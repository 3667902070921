import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import * as actions from 'store/actions/CRUD'
import GenericFormHOC from './FormElements/GenericFormHOC'
import { REDUCER_QUESTIONNAIRES } from '../store/reducers'
import SingleQuestionForm from './SingleQuestionForm'

const DEFAULT_FIELDS = {
  choices: [],
  default: '',
  field_type: '',
  help_text: '',
  label: '',
  placeholder_text: '',
  timing_in_days: null
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GenericFormHOC(
    SingleQuestionForm,
    DEFAULT_FIELDS,
    REDUCER_QUESTIONNAIRES,
    null
  )
)
