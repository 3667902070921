import React, { Component } from 'react'
import DateTime from 'react-datetime'
import './DateTimeInput.scss'
import styled from 'styled-components/macro'
import moment from 'moment'
import Label from '../Label/Label'

const Container = styled.div`
  flex: 1;
  & input {
    border: #979797;
    border-radius: 4px;
    background-color: #535252;
    color: #ffffff;
    padding-left: 0.25rem;
    height: 36px;
    outline: none;
    width: 100%;
  }
`

const Time = styled.div``
const TimeSeparator = styled.span`
  line-height: 36px;
`
const TimeField = styled.input`
  width: 2.5rem;
`

export default class extends Component {
  state = {
    datetime: null,
    date: null,
    hours: null,
    minutes: null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        date: moment(this.props.value)
      })
    }
  }

  makeDateTime() {
    const { date, hours, minutes } = this.state

    let newDate = moment(date)
    if (moment.isMoment(newDate)) {
      newDate.set({ hours, minutes })
    }

    const mockEvent = {
      target: {
        value: newDate.toISOString(),
        name: this.props.name
      }
    }

    this.props.onChange(mockEvent)
  }

  handleDate = value => {
    this.setState({ date: value }, this.makeDateTime)
  }

  handleHours = e => {
    this.setState({ hours: e.target.value }, this.makeDateTime)
  }

  handleMinutes = e => {
    this.setState({ minutes: e.target.value }, this.makeDateTime)
  }

  render() {
    const { title, defaultValue, isValidDate } = this.props
    const { date, hours, minutes } = this.state

    return (
      <Container style={{ margin: '5px 10px' }}>
        <Label>{title}</Label>
        <br />
        <DateTime
          defaultValue={defaultValue ? defaultValue : ''}
          timeFormat={false}
          value={date}
          onChange={this.handleDate}
          isValidDate={isValidDate}
        />
      </Container>
    )
  }
}
