import { addLocale, useLocale } from 'ttag'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/en-gb'

import * as cookie from './cookie'

const LOCALE_COOKIE = '__locale'
const DEFAULT_LOCALE = 'en-GB'

const locales = ['en-GB', 'fi']
/**
 * Return current locale
 *
 * If locale is not saved to cookie check user browsers language.
 *
 * NOTE !!! Code is currently optimized to support only english and finnish.
 *
 * @returns {*}
 */
export function getLocale() {
  const locale = cookie.get(LOCALE_COOKIE)
  if (typeof locale !== 'undefined') {
    return locale
  }

  return DEFAULT_LOCALE
}

export function saveLocale(locale) {
  cookie.set(LOCALE_COOKIE, locale)
}

if (locales.findIndex(locale => locale === getLocale()) === -1) {
  cookie.remove(LOCALE_COOKIE)
}

const currentLocale = getLocale()

if (currentLocale !== moment.locale()) {
  moment.locale(currentLocale.toLowerCase())
}

if (currentLocale !== DEFAULT_LOCALE) {
  // eslint-disable-next-line
  const translationObj = require(`../i18n/${currentLocale}.po.json`)
  addLocale(currentLocale, translationObj)
  useLocale(currentLocale)

  /* Dynamically import correct locale for moment. Add more complex selection
   * logic here when more options are available.
   *
   * This changes language of the timeline component. */
  // eslint-disable-next-line

  // require('moment/locale/fi')
}
