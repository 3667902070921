// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import * as actions from 'store/actions/CRUD'
import { REDUCER_USERS, REDUCER_GROUPS } from 'store/reducers'
import GenericFormHOC from 'components/FormElements/GenericFormHOC'
import Submit from '../components/FormElements/Inputs/Submit/Submit'
import { bulkCreateUsers } from 'store/actions/auth'

import ExampleCSV from 'assets/example-csv.csv'
import { toast } from 'react-toastify'

const Form = styled.form`
  width: 100%;
`

const FormContainer = styled.table`
  width: 100%;
`

const DEFAULT_FIELDS = {}

class UserImport extends Component {
  componentDidMount() {}

  state = {
    file: null
  }

  handleSubmit = e => {
    e.preventDefault()
    const { file } = this.state
    const { isStale } = this.props
    if (file !== null) {
      const { bulkCreateUsers: bulkCreateUsersAction } = this.props
      const formData = new FormData(e.target)

      bulkCreateUsersAction(formData, this.getCompanyId()).then(() => {
        this.setState({ selectedFile: null })
        isStale(REDUCER_USERS)
        isStale(REDUCER_GROUPS)
      })
      e.target.reset()
    }
  }

  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  handleFile = e => {
    this.setState({
      file: e.target.files[0]
    })
  }

  render() {
    const {
      auth: { error, alreadyExistingAddresses }
    } = this.props

    let existingAddresses = null

    let errorText = null
    if (error.messages && error.messages.non_field_errors) {
      errorText = (
        <div style={{ color: 'red' }}>{error.messages.non_field_errors[0]}</div>
      )
    }

    let success = null
    if (alreadyExistingAddresses) {
      success = (
        <div
          style={{
            color: '#009f86'
          }}
        >
          {t`Users created.`}
        </div>
      )
    }
    if (alreadyExistingAddresses && alreadyExistingAddresses.length > 0) {
      const emails = alreadyExistingAddresses.map(name => {
        return <div>{name}</div>
      })
      existingAddresses = (
        <div
          style={{
            color: 'red'
          }}
        >
          <div>{t`Following emails were skipped, because they have already account.`}</div>
          {emails}
        </div>
      )
    }

    return (
      <div>
        <h2>{t`Import users`}</h2>
        <p>
          <a style={{ color: '#009f86' }} href={ExampleCSV}>{t`Here's`}</a>{' '}
          {t`an example CSV file that you can use to import users.`}
        </p>

        <Form onSubmit={this.handleSubmit}>
          <FormContainer>
            <tr>
              <td>
                <input type="file" onChange={this.handleFile} name="file" />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Submit
                  type="submit"
                  style={{ float: 'right' }}
                >{t`Create users`}</Submit>
              </td>
            </tr>
          </FormContainer>
          {success}
          {existingAddresses}
          {errorText}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ bulkCreateUsers, ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GenericFormHOC(
    UserImport,
    DEFAULT_FIELDS,
    REDUCER_USERS,
    REDUCER_GROUPS,
    '/import'
  )
)
