// @flow
import React, { Component } from 'react'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import {
  ListBodyCell,
  ListCompanyTitle,
  ListContainer,
  ListHeadingCell,
  ListRow
} from 'components/Lists/StyledLists'
import { connect } from 'react-redux'
import { REDUCER_GROUPS } from 'store/reducers'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import { SortableListComponent } from 'components/Lists'
import { GreenButtonLink } from 'components/Button/GreenButtonLink'

const TABLE_HEADINGS = [
  {
    Header: t`Group`,
    accessor: 'group'
  },
  {
    Header: t`Description`,
    accessor: 'description'
  },
  {
    Header: t`Users`,
    accessor: 'users'
  },
  {
    accessor: 'buttons'
  }
]

class Groups extends Component {
  state = {}

  render() {
    const {
      session: { company },
      [REDUCER_GROUPS]: { data }
    } = this.props

    const list = data.map(({ id, name, description, users }) => {
      return {
        key: id,
        group: name,
        description,
        users: users.length,
        buttons: (
          <GreenButtonLink to={`/groups/${id}`}>{t`OPEN`}</GreenButtonLink>
        )
      }
    })

    return (
      <SortableListComponent
        title={t`Groups`}
        headers={TABLE_HEADINGS}
        data={list}
        company={company}
        linkToForm="/groups/new"
      />
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups)
