// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'
import { bindActionCreators } from 'redux'
import countryList from 'react-select-country-list'

import { login, registerOrganization } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'
import Input from 'components/FormElements/Inputs/FatInput/FatInput'
import Select from 'components/FormElements/Inputs/Select'
import Button from 'components/Button/Button'
import { GreenButtonLink } from 'components/Button/GreenButtonLink'
import { GreenButtonLinkExternal } from 'components/Button/GreenButtonLinkExternal'

import device from 'device'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const Container = styled.div`
  display: flex;
  // height: 100%;
  font-size: 0.9rem;
  flex-direction: column-reverse;
  @media ${device.laptop} {
    flex-direction: row;
  }
`

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const RightContainer = styled.div`
  flex: 1;
  display: flex;

  margin: 1rem auto;

  @media ${device.laptop} {
    margin-right: 1rem;
    padding-left: 4rem;
  }
`

const FormContainer = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #535252;
  padding: 25px 50px;

  margin: 0 auto;
  @media ${device.laptop} {
    width: 450px;

    margin-right: 1rem;
  }

  justify-content: flex-end;
`

const CTAContainer = styled.div`
  @media ${device.laptop} {
    width: 450px;
  }
`

const Form = styled.form`
  text-align: left;
`

const FeatureList = styled.div``
const Feature = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`
const FeatureIcon = styled.div`
  display: inline-block;
  align-self: flex-start;
  background-color: #009f86;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
  text-align: center;
  border-radius: 100%;
  font-size: 0.75rem;
`
const FeatureContent = styled.div`
  flex: 2;
`

const Submit = styled.button`
  width: 100%;
  line-height: 36px;
  display: block;
  text-decoration: none;
  color: #fff;
  text-align: center;
  background: #009f86;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;

  :disabled {
    background: gray;
  }
`

const Agreements = styled.div`
  padding-bottom: 1rem;

  input {
    width: 21px;
    height: 21px;
  }
  label {
    font-size: 1.1rem;
  }
`

class Signup extends Component {
  state = {
    forms: {},
    consent: false,
    entry_url: cookies.get('UTM')
  }

  fields = [
    { type: 'email', name: 'email', label: t`Email` },
    { type: 'password', name: 'password', label: t`Password` },
    { type: 'text', name: 'first_name', label: t`First name` },
    { type: 'text', name: 'last_name', label: t`Last name` },
    { type: 'text', name: 'company_name', label: t`Organization name` },
    { type: 'hidden', name: 'entry_url' },
    {
      type: 'select',
      name: 'industry',
      label: t`Industry`,
      items: [
        'Professional Services',
        'Technology and Engineering',
        'Manufacturing and Construction',
        'Startups and small business',
        'Management consulting',
        'Public Sector',
        'Charity and Non-Profit',
        'Other'
      ]
    },
    {
      type: 'select',
      name: 'headcount',
      label: t`Headcount`,
      items: [
        '1-10',
        '11-30',
        '31-50',
        '51-100',
        '101-300',
        '301-500',
        '501-1000',
        '1001-5000',
        'More'
      ]
    },
    {
      type: 'select',
      name: 'country',
      label: t`Country`,
      items: countryList()
        .getData()
        .map(country => country.label)
    }
  ]

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  submit = e => {
    e.preventDefault()

    const {
      forms,
      forms: { email },
      entry_url
    } = this.state
    const {
      registerOrganization: registerOrganizationAction,
      login: loginAction,
      history
    } = this.props

    forms.entry_url = entry_url

    registerOrganizationAction(forms).then(response => {
      if (response && response.action) {
        if (response.action.type === 'REGISTER_ORG_FULFILLED') {
          const $FPROM = window.$FPROM
          $FPROM.trackSignup({
            email
          })
          history.push(`/confirmation`)
        }
      }
      return response
    })
  }

  handleChange = e => {
    const { forms } = this.state
    this.setState({ forms: { ...forms, [e.target.name]: e.target.value } })
  }

  toggleConsent = e => {
    const { consent } = this.state
    this.setState({ consent: !consent })
  }

  render() {
    const {
      auth: { isPending, error, loggedIn }
    } = this.props

    const { consent } = this.state

    if (loggedIn)
      return (
        <Redirect
          to={{
            pathname: '/home'
          }}
        />
      )

    const inputs = this.fields.map(({ type, name, label, items }) => {
      const { messages } = error
      const fieldError = messages ? messages[name] : null

      if (type === 'select') {
        return (
          <Select
            onChange={this.handleChange}
            name={name}
            label={label}
            options={items}
            error={fieldError}
          />
        )
      }

      return (
        <Input
          key={`${type}-${name}`}
          type={type}
          name={name}
          onChange={this.handleChange}
          error={fieldError}
          label={label}
        />
      )
    })

    return (
      <Container>
        <LeftContainer>
          <FormContainer>
            <h1>{t`Signup`}</h1>
            <Form onSubmit={this.submit} autocomplete="off">
              {inputs}
              <br />
              <Agreements>
                <input
                  type="checkbox"
                  name="agreements"
                  onChange={this.toggleConsent}
                />
                <label for="agreements">
                  {' '}
                  {t`I have read and agree to the`}{' '}
                  <a
                    href="https://priocta.com/company/terms-of-use"
                    target="_blank"
                  >
                    {t`Terms of Use`}
                  </a>{' '}
                  {t`and`}{' '}
                  <a
                    href="https://priocta.com/company/privacy-policy"
                    target="_blank"
                  >
                    {t`Privacy Policy.`}
                  </a>
                </label>
              </Agreements>
              <Submit type="submit" disabled={isPending || !consent}>
                {t`Sign up`}
              </Submit>
            </Form>
            <br />
            <Button style={{ width: '100%' }} to="/login">
              {t`Already have an account? Login here`}
            </Button>
          </FormContainer>
        </LeftContainer>
        <RightContainer>
          <CTAContainer>
            <h1
              style={{ marginBottom: 0 }}
            >{t`Get started with your account`}</h1>
            <h4 style={{ color: '#CEA766', marginTop: '0.5rem' }}>
              {t`14 DAY FREE TRIAL`}
            </h4>
            <p>
              {t`Priocta is free to try for 14 days.`}
              <br />
              {t`No credit card required.`}
              <br />
              {t`Don´t worry, it takes less than a minute and is 100% confidential.`}
            </p>
            <p style={{ textAlign: 'center', padding: '1rem 0' }}>
              <GreenButtonLinkExternal href="https://www.priocta.com">
                www.priocta.com
              </GreenButtonLinkExternal>
            </p>
            <strong style={{ fontSize: '0.9rem' }}>
              {t`THE OUTSTANDING PLATFORM TO MAKE A CHANGE HAPPEN`}
            </strong>
            <FeatureList>
              <Feature>
                <FeatureIcon />
                <FeatureContent>
                  {t`Manage with data instead of gut feeling`}
                </FeatureContent>
              </Feature>
              <Feature>
                <FeatureIcon />
                <FeatureContent>
                  {t`Receive valuable insight, for example, about the progress of
                  your change project or employees’ job satisfaction`}
                </FeatureContent>
              </Feature>
              <Feature>
                <FeatureIcon />
                <FeatureContent>
                  {t`Share the information of the change project progression with
                  explicit dashboard`}
                </FeatureContent>
              </Feature>
              <Feature>
                <FeatureIcon />
                <FeatureContent>
                  {t`Have quantitative and qualitative data of your organization’s
                  change or employees state of mind`}
                </FeatureContent>
              </Feature>
              <Feature>
                <FeatureIcon />
                <FeatureContent>
                  {t`Get answers anonymously or not, you decide`}
                </FeatureContent>
              </Feature>
            </FeatureList>
            <p style={{ textAlign: 'center', padding: '1rem 0' }}>
              <GreenButtonLinkExternal href="https://priocta.com/features">
                {t`See more features`}
              </GreenButtonLinkExternal>
            </p>
          </CTAContainer>
        </RightContainer>
      </Container>
    )
  }
}

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerOrganization,
      resetErrorMessage,
      login
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup)
