import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { t } from 'ttag'
import { changeSelectedCompany } from 'store/actions/session'
import * as actions from 'store/actions/CRUD'
import {
  REDUCER_COMPANIES,
  REDUCER_QUESTIONNAIRES,
  REDUCER_GROUPS,
  REDUCER_USERS,
  REDUCER_PLANS,
  REDUCER_SUBSCRIPTION
} from 'store/reducers'
import Label from '../FormElements/Inputs/Label/Label'

const Container = styled.div`
  margin-left: auto;
  display: flex;
`

const Select = styled.select`
  border: ${(props) => (props.error ? '1px solid red' : '#979797')};
  border-radius: 4px;
  background-color: #535252;
  color: #ffffff;
  padding-left: 0.25rem;
  height: 36px;
  width: 100%;
`

const SettingsLink = styled.div`
  align-self: center;

  & > a {
    color: #eaeaee;
    position: relative;
    top: 8px;
    padding-left: 1rem;
  }
`

class CompanySelection extends Component {
  componentDidUpdate(nextProps, nextState, snapshot) {
    const {
      auth: {
        user: { visible_companies, user_type }
      },
      session: { company }
    } = this.props

    if (!company && visible_companies && visible_companies.length === 1) {
      const companyId = visible_companies[0].id
      this.updateCompany(companyId)
    }
  }

  onChange = (event) => {
    const { value } = event.target
    const companyId = parseInt(value) > 0 ? parseInt(value) : null
    this.updateCompany(companyId)
  }

  updateCompany = (companyId) => {
    const {
      auth: {
        user: { visible_companies }
      }
    } = this.props
    // Pass whole company object with name to reducer
    const company =
      visible_companies.find((item) => item.id === companyId) || null
    this.props.changeSelectedCompany(company)
    this.props.isStale(REDUCER_COMPANIES)
    this.props.isStale(REDUCER_GROUPS)
    this.props.isStale(REDUCER_USERS)
    this.props.isStale(REDUCER_QUESTIONNAIRES)
    this.props.isStale(REDUCER_PLANS)
    this.props.isStale(REDUCER_SUBSCRIPTION)
    this.props.history.push('/surveys')
  }

  render() {
    const {
      auth: {
        user: { visible_companies, user_type }
      },
      session: { company }
    } = this.props

    if (!visible_companies) return <Container>{t`Loading...`}</Container>

    const selectedCompany = company ? company.id : null

    if (user_type === 'reseller_group') {
      return (
        <Container>
          <div>
            <Label>{t`Change company`}</Label>
            <Select onChange={this.onChange}>
              <option value={0}>{t`Select company from a list`}</option>
              {visible_companies.map((item) => (
                <option
                  key={item.id}
                  value={item.id}
                  selected={item.id === selectedCompany}
                >
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <SettingsLink>
            <Link to={'/companies'}>
              <i className="fas fa-cog" />
            </Link>
          </SettingsLink>
        </Container>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = ({ auth, session }) => ({
  auth,
  session
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeSelectedCompany, ...actions }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanySelection)
)
