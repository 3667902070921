// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'
import Questionnaires from './Questionnaires'
import Companies from './Companies'
import * as actions from 'store/actions/CRUD'
import { bindActionCreators } from 'redux'
import { changeSelectedCompany } from '../store/actions/session'
import CompaniesLinkables from './CompaniesLinkables'

class Home extends Component {
  render() {
    const {
      session,
      auth: { isReseller }
    } = this.props

    const company = session && session.company ? session.company : null
    const selectedCompany = company && company.id ? company.id : null

    return (
      <div>
        <h1>{t`Welcome – time to make a difference.`}</h1>
        <p>
          {t`We are living in among constant change. What was certain and constant yesterday, may be obsolete and inoperative tomorrow. You know that, we all know that. Although the change itself pushes us to extend ourselves to keep up, it offers wonderful opportunities as well. Change should not be afraid or agonized; it should take as an opportunity to shine. Presumably, that’s why you are here and that’s why we, the Priocta team, want help you.`}
        </p>
        <p>
          {t`We have a common goal – turn the inevitable change to our asset. Its time to make a difference.`}
        </p>
        {isReseller && !selectedCompany ? (
          <CompaniesLinkables {...this.props} />
        ) : (
          <Questionnaires />
        )}
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeSelectedCompany, ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
