// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import { REDUCER_COMPANIES } from 'store/reducers'
import GenericFormHOC from 'components/FormElements/GenericFormHOC'
import * as actions from 'store/actions/CRUD'
import { userRead } from 'store/actions/auth'
import DeleteConfirmation from '../components/DeleteConfirmation'
import Submit from '../components/FormElements/Inputs/Submit/Submit'

const Form = styled.form`
  width: 100%;
`

const FormTitle = styled.div`
  text-align: left;
  color: #eaeaee;
  font-weight: bold;
  border-bottom: 1px solid #212121;
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.3rem;
  padding-bottom: 0.5rem;
`

const FormContainer = styled.table`
  width: 100%;
`

const DEFAULT_FIELDS = {
  name: '',
  email: ''
}

class CompaniesForm extends Component {
  componentDidMount() {
    this.props.populateDataByParamId()
  }

  handleSubmit = e => {
    e.preventDefault()
    const { fields, handleSaveOrCreate, object, userRead } = this.props
    let query = object ? `/api/companies/${object}/` : '/api/companies/'
    handleSaveOrCreate(fields, null, query).then(userRead())
  }

  onDelete = () => {
    const { isStale, userRead } = this.props
    isStale(REDUCER_COMPANIES)
    userRead()
    this.moveBack()
  }

  moveBack = () => {
    const { history } = this.props

    history.push(`/companies`)
  }

  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  render() {
    const { fields, errors, object, handleChange } = this.props

    return (
      <div>
        <FormTitle colSpan="2">
          {object ? t`Edit company` : t`Add new company`}
        </FormTitle>
        <Form onSubmit={this.handleSubmit}>
          <FormContainer>
            <tr>
              <td colSpan={2}>
                <InputWithLabel
                  label={t`Company name`}
                  name="name"
                  onChange={handleChange}
                  value={fields.name}
                  error={errors.name}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <InputWithLabel
                  label={t`Company email`}
                  name="email"
                  onChange={handleChange}
                  value={fields.email}
                  error={errors.email}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                {object && (
                  <DeleteConfirmation
                    reducer={REDUCER_COMPANIES}
                    query={`/api/companies/${object}/`}
                    onSuccess={this.onDelete}
                    onFailure={() => {}}
                    ActionButton={
                      <Submit
                        type="button"
                        style={{ float: 'left' }}
                        destructive
                      >{t`Delete`}</Submit>
                    }
                  />
                )}
                <Submit
                  type="submit"
                  style={{ float: 'right' }}
                >{t`Save`}</Submit>
                <Submit
                  type="button"
                  onClick={() => this.moveBack()}
                  style={{ float: 'right', marginRight: '1rem' }}
                  secondary
                >{t`Cancel`}</Submit>
              </td>
            </tr>
          </FormContainer>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions, userRead }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GenericFormHOC(CompaniesForm, DEFAULT_FIELDS, REDUCER_COMPANIES, '/companies')
)
