// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import { login } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'

import Button from 'components/Button/Button'
import Input from 'components/FormElements/Inputs/FatInput/FatInput'
import { toast } from 'react-toastify'

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #535252;
  margin: 0 auto;
  padding: 25px 50px;
  width: 450px;
`

const WhiteLink = styled(Link)`
  color: white;
  text-decoration: none;
`

const FormContainer = styled.div``

const Form = styled.form`
  text-align: left;
`

const Submit = styled.button`
  width: 100%;
  line-height: 36px;
  display: block;
  text-decoration: none;
  color: #fff;
  text-align: center;
  background: #009f86;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;

  :disabled {
    background: gray;
  }
`

class Login extends Component {
  state = {
    username: '',
    password: '',
    redirectToReferrer: false
  }

  fields = [
    { type: 'username', name: 'username', label: t`Email` },
    { type: 'password', name: 'password', label: t`Password` }
  ]

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0]) {
      toast.error(error.messages.non_field_errors[0]) // Display error messages
      resetErrorAction()
    }
  }

  submitLogin = e => {
    e.preventDefault()
    const { username, password } = this.state
    const { login: loginAction } = this.props

    loginAction({ username, password }).then(() => {
      this.setState({ redirectToReferrer: true })
    })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const {
      error,
      auth: { isPending },
      location: { state }
    } = this.props

    const { from } = state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer) return <Redirect to={from} />

    const inputs = this.fields.map(({ type, name, label }) => {
      const fieldError = error ? error[name] : null

      return (
        <Input
          key={`${type}-${name}`}
          type={type}
          name={name}
          onChange={this.handleChange}
          error={fieldError}
          label={label}
        />
      )
    })

    return (
      <Container>
        <FormContainer>
          {/*  <p>You must log in to view the page at {from.pathname}</p> */}
          <h1>{t`Login`}</h1>
          <Form onSubmit={this.submitLogin}>
            {inputs}
            <br />
            <Submit type="submit" disabled={isPending}>
              {t`Login`}
            </Submit>
          </Form>
          <p>
            <WhiteLink to="/forgotpassword">{t`Forgot password?`}</WhiteLink>
          </p>
          <Button style={{ marginTop: '15px' }} to="/signup">
            {t`Sign up`}
          </Button>
        </FormContainer>
      </Container>
    )
  }
}

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      resetErrorMessage
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
