import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { saveLocale, getLocale } from '../../i18nInit'

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 50px;
  margin-top: 1rem;
`

const Button = styled.div`
  width: 40px;
  cursor: pointer;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
`

const locales = [
  {
    locale: 'fi',
    title: 'FI'
  },
  {
    locale: 'en-GB',
    title: 'EN'
  }
]

const currentLocale = getLocale()

export default class LanguageSelector extends Component {
  setLocale = locale => {
    saveLocale(locale)
    window.location.reload()
  }

  render() {
    const options = locales.map(item => {
      const isSelected = currentLocale === item.locale
      return (
        <Button
          key={item.locale}
          role="button"
          selected={isSelected}
          onClick={e => this.setLocale(item.locale, e)}
        >
          {item.title}
        </Button>
      )
    })

    return <Container>{options}</Container>
  }
}
