import {
  LOGIN,
  LOGOUT,
  REGISTER_ORG,
  USER_READ,
  PASSWORD_RESET,
  PASSWORD_CONFIRM,
  PASSWORD_CONFIRM_RESET,
  BULK_CREATE_USERS,
  PASSWORD_CHANGE
} from 'store/actions/auth'

import produce from 'immer'

const API_TOKEN = sessionStorage.getItem('API_TOKEN')

const initialState = {
  token: API_TOKEN,
  isPending: false,
  error: [],
  tokenExpired: false,
  loggedIn: !!API_TOKEN,
  user: [],
  isReseller: false,
  registrationRedirect: false,
  subscriptionChecked: false,
  passwordResetRedirect: false,
  passwordResetMessage: null,
  passwordResetSend: false,
  alreadyExistingAddresses: null,
  passwordChangeDone: false
}

const auth = (state = initialState, action) => {
  const { type, error, payload } = action

  if (error && payload.messages.code === 'token_expired') {
    return produce(state, draft => {
      draft.tokenExpired = true
      draft.isPending = false
      draft.error = payload.response
      draft.loggedIn = false
    })
  }

  switch (type) {
    case 'LIST_SUBSCRIPTION_FULFILLED':
      return produce(state, draft => {
        draft.subscriptionChecked = true
      })

    case `${REGISTER_ORG}_PENDING`:
    case `${LOGIN}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
      })

    case `${REGISTER_ORG}_REJECTED`:
    case `${LOGIN}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
        draft.loggedIn = false
      })

    case `${REGISTER_ORG}_FULFILLED`:
      return produce(state, draft => {
        draft.registrationRedirect = true
      })

    case `${LOGIN}_FULFILLED`:
      sessionStorage.setItem('API_TOKEN', payload.key)
      return produce(state, draft => {
        draft.token = payload.key
        draft.tokenExpired = false
        draft.registrationRedirect = false
        draft.isPending = false
        draft.loggedIn = true
      })

    case `${USER_READ}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
        draft.error = []
        draft.user = []
      })

    case `${USER_READ}_REJECTED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
        draft.user = []
      })

    case `${USER_READ}_FULFILLED`:
      // On login the api returns only key, and no user info
      // if user has no type (APP user) log the user out:
      if (!payload.user_type) {
        sessionStorage.removeItem('API_TOKEN')
        sessionStorage.removeItem('company')
        return produce(state, draft => {
          draft.isPending = false
          draft.error = payload
          draft.user = []
          draft.loggedIn = false
          draft.token = null
        })
      }
      return produce(state, draft => {
        draft.isReseller = payload.user_type === 'reseller_group'
        draft.user = payload
        draft.isPending = false
      })
    case `${LOGOUT}_PENDING`:
      return produce(state, draft => {
        draft.isPending = true
      })

    case `${LOGOUT}_FULFILLED`:
    case `${LOGOUT}_REJECTED`:
      sessionStorage.removeItem('API_TOKEN')
      sessionStorage.removeItem('company')
      return produce(state, draft => {
        draft.isPending = false
        draft.error = payload
        draft.user = []
        draft.loggedIn = false
        draft.token = null
      })

    case `${PASSWORD_RESET}_PENDING`:
      return produce(state, draft => {
        draft.passwordResetMessage = null
        draft.passwordResetError = null
        draft.passwordResetSend = false
        draft.error = []
        draft.isPending = true
      })

    case `${PASSWORD_RESET}_REJECTED`:
      return produce(state, draft => {
        draft.passwordResetError = payload
        draft.error = payload
        draft.isPending = false
        draft.passwordResetSend = false
      })

    case `${PASSWORD_RESET}_FULFILLED`:
      return produce(state, draft => {
        draft.passwordResetMessage = payload.detail
        draft.isPending = false
        draft.passwordResetSend = true
      })

    case `${PASSWORD_CHANGE}_PENDING`:
      return produce(state, draft => {
        draft.error = []
        draft.isPending = true
        draft.passwordChangeDone = false
      })

    case `${PASSWORD_CHANGE}_REJECTED`:
      return produce(state, draft => {
        draft.error = payload
        draft.isPending = false
        draft.passwordChangeDone = false
      })

    case `${PASSWORD_CHANGE}_FULFILLED`:
      return produce(state, draft => {
        draft.isPending = false
        draft.passwordChangeDone = true
      })

    case `${PASSWORD_CONFIRM}_PENDING`:
      return produce(state, draft => {
        draft.passwordResetRedirect = false
        draft.passwordResetMessage = null
        draft.error = []
        draft.isPending = true
      })

    case `${PASSWORD_CONFIRM}_REJECTED`:
      return produce(state, draft => {
        draft.error = payload
        draft.isPending = false
      })

    case `${PASSWORD_CONFIRM}_FULFILLED`:
      return produce(state, draft => {
        draft.passwordResetRedirect = true
        draft.passwordResetMessage = payload.detail
        draft.isPending = false
      })

    case PASSWORD_CONFIRM_RESET:
      return produce(state, draft => {
        draft.passwordResetRedirect = false
        draft.passwordResetMessage = null
        draft.error = []
      })
    case `${BULK_CREATE_USERS}_PENDING`:
      return produce(state, draft => {
        draft.error = []
        draft.alreadyExistingAddresses = null
      })
    case `${BULK_CREATE_USERS}_REJECTED`:
      return produce(state, draft => {
        draft.error = payload
      })
    case `${BULK_CREATE_USERS}_FULFILLED`:
      return produce(state, draft => {
        draft.alreadyExistingAddresses = payload.already_existing_addresses
      })
    default:
      return state
  }
}

export default auth
