import styled, { keyframes } from 'styled-components/macro'
import { Component } from 'react'
import { t } from 'ttag'
import React from 'react'

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate} 2s linear infinite;
  display: inline-block;
`

const Title = styled.span`
  padding-left: 0.5rem;
`

export default class LoadingSpinner extends Component {
  render() {
    return (
      <Container>
        <Spinner>
          <i className="fas fa-spinner" />
        </Spinner>
        <Title>{t`Loading...`}</Title>
      </Container>
    )
  }
}
