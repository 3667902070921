// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import { changePassword } from 'store/actions/auth'
import Input from 'components/FormElements/Inputs/FatInput/FatInput'

const Container = styled.div`
  text-align: center;
  border-radius: 3px;
  background-color: #535252;
  margin: 0 auto;
  padding: 25px 50px;
  width: 450px;
`

const FormContainer = styled.div``

const Form = styled.form`
  text-align: left;
`

const Submit = styled.button`
  width: 100%;
  line-height: 36px;
  display: block;
  text-decoration: none;
  color: #fff;
  text-align: center;
  background: #009f86;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;

  :disabled {
    background: gray;
  }
`

class ChangePassword extends Component {
  state = {
    forms: {},
    touched: false
  }

  resetFields = [
    { type: 'password', name: 'new_password1', label: t`Password` },
    { type: 'password', name: 'new_password2', label: t`Password (same again)` }
  ]

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      auth: { passwordChangeDone }
    } = this.props

    if (!prevProps.auth.passwordChangeDone && passwordChangeDone) {
      toast.info(t`Password changed successfully`)
    }
  }

  changePassword = e => {
    e.preventDefault()

    const {
      forms: { new_password1, new_password2 }
    } = this.state
    const { changePassword: changePasswordAction } = this.props

    changePasswordAction({ new_password1, new_password2 })
  }

  handleChange = e => {
    const { forms } = this.state
    this.setState({
      touched: true,
      forms: { ...forms, [e.target.name]: e.target.value }
    })
  }

  render() {
    const {
      auth: { isPending, error, loggedIn, passwordResetSend },
      match: { params }
    } = this.props

    const {
      forms: { new_password1: newPass1, new_password2: newPass2 },
      touched
    } = this.state

    const inputs = this.resetFields.map(({ type, name, label }) => {
      const { messages } = error
      const fieldError = messages ? messages[name] : null

      return (
        <Input
          key={`${type}-${name}`}
          type={type}
          name={name}
          onChange={this.handleChange}
          error={fieldError}
          label={label}
        />
      )
    })

    return (
      <Container>
        <FormContainer>
          <h1>{t`Change password`}</h1>
          <Form onSubmit={this.changePassword}>
            {inputs}
            <br />
            <Submit type="submit">{t`Change password`}</Submit>
          </Form>
        </FormContainer>
      </Container>
    )
  }
}

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword)
