// @flow
import React, { Component } from 'react'
import { t } from 'ttag'

import { connect } from 'react-redux'
import { REDUCER_COMPANIES } from 'store/reducers'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import { SortableListComponent } from 'components/Lists'
import { GreenButtonLink } from 'components/Button/GreenButtonLink'

const TABLE_HEADINGS = [
  {
    Header: t`Name`,
    accessor: 'name'
  },
  {
    accessor: 'buttons'
  }
]

class Companies extends Component {
  state = {}

  render() {
    const {
      session: { company },
      [REDUCER_COMPANIES]: { data }
    } = this.props

    const list = data.map(item => {
      return {
        key: item.id,
        name: item.name,
        buttons: (
          <GreenButtonLink
            to={`/companyform/${item.id}`}
          >{t`EDIT`}</GreenButtonLink>
        )
      }
    })

    return (
      <SortableListComponent
        title={t`Companies`}
        headers={TABLE_HEADINGS}
        data={list}
        company={company}
        linkToForm="/companyform"
      />
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies)
