import produce from 'immer'
import { TOUR_END, TOUR_START, BACK_TO_SURVEYS } from 'store/actions/tour'

const initialState = {
  tourOn: false,
  backToSurveys: false
}

const tour = (state = initialState, action) => {
  const { type, error, payload } = action

  switch (type) {
    case TOUR_START:
      return produce(state, draft => {
        draft.tourOn = true
      })
    case TOUR_END:
      return produce(state, draft => {
        draft.tourOn = false
        draft.backToSurveys = false
      })
    case BACK_TO_SURVEYS:
      return produce(state, draft => {
        draft.backToSurveys = true
      })
    default:
      return state
  }
}

export default tour
