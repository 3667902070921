import styled from 'styled-components/macro'
import { SortableHandle } from 'react-sortable-hoc'
import React from 'react'

const Container = styled.div`
  cursor: move;
`

const DragHandle = SortableHandle(() => (
  <Container>
    <i className="fas fa-grip-lines" />
  </Container>
))

export default DragHandle
