// @flow
import React, { Component } from 'react'
import { t } from 'ttag'

import { connect } from 'react-redux'
import { REDUCER_SUBSCRIPTION, REDUCER_USERS } from 'store/reducers'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import { SortableListComponent } from 'components/Lists'
import { GreenButtonLink } from 'components/Button/GreenButtonLink'

const TABLE_HEADINGS = [
  {
    Header: t`Person`,
    accessor: 'fullName'
  },
  {
    Header: t`Group`,
    accessor: 'groups'
  },
  {
    Header: t`Email`,
    accessor: 'email'
  },
  {
    Header: t`Admin`,
    accessor: 'admin'
  },
  {
    accessor: 'buttons'
  }
]

class Groups extends Component {
  state = {}

  render() {
    const {
      session: { company },
      [REDUCER_USERS]: { data },
      [REDUCER_SUBSCRIPTION]: { data: subscription }
    } = this.props

    const list = data.map(
      ({
        company_groups: companyGroups,
        user_type: userType,
        full_name: fullName,
        email,
        id
      }) => {
        let groups = companyGroups.map(({ name }) => `${name}\n`)
        if (groups.length < 1) groups = '-'
        const isAdmin = userType === 'company_admin_group' ? t`Yes` : t`No`

        return {
          key: id,
          fullName,
          groups,
          email,
          admin: isAdmin,
          buttons: (
            <GreenButtonLink to={`/personnel/${id}`}>{t`OPEN`}</GreenButtonLink>
          )
        }
      }
    )
    const personnelCount = data.length
    const extraInfoRow = (
      <td colSpan="7" style={{ fontWeight: 600 }}>
        <p
          style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
        >{t`Total personnel: ${personnelCount}`}</p>
      </td>
    )
    let metered = false

    if (subscription.length > 0) {
      if (subscription[0].plan.usage_type === 'metered') {
        metered = true
      }
    }
    return (
      <div>
        <SortableListComponent
          title={t`Personnel`}
          headers={TABLE_HEADINGS}
          extraInfoRow={extraInfoRow}
          data={list}
          company={company}
          linkToForm="/personnel/new"
          actionDescription={
            metered &&
            t`Your plan is billed on a per user basis. Adding new users might affect your pricing.`
          }
        />
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}
        >
          <GreenButtonLink to="/user-import">{t`Import users`}</GreenButtonLink>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Groups)
