import React from 'react'
import styled from 'styled-components/macro'
import { darken } from 'polished'
import { withRouter } from 'react-router'

const Button = styled.button`
  color: #ffffff;
  font-size: ${props => (props.size === 'large' ? '17' : '15')}px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
  background-color: ${props => props.background || '#A3A3A3'};
  padding: 12px 40px;
  border: 0;
  margin: 0 12px 0 0;

  transition: all 0.2s;
  cursor: pointer;

  padding: ${props =>
    (props.size === 'small' && '7px 25px') ||
    (props.size === 'medium' && '12px 30px') ||
    (props.size === 'large' && '12px 40px')};

  &:hover {
    background-color: ${props => darken(0.05, props.background || '#A3A3A3')};
  }
  &:active {
    background-color: ${props => darken(0.08, props.background || '#A3A3A3')};
  }

  &:last-child {
    margin-right: 0;
  }

  :disabled {
    background: gray;
  }
`
export default withRouter(
  ({
    size = 'medium',
    children,
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    ...props
  }: {
    size: string,
    children: React.children,
    props: Array<Object>
  }) => (
    <Button
      size={size}
      {...props}
      onClick={event => {
        if (onClick) onClick(event)
        history.push(to)
      }}
    >
      {children}
    </Button>
  )
)
