import React from 'react'
import styled from 'styled-components/macro'
import { t } from 'ttag'
import { useTable, useSortBy } from 'react-table'

import { GreenButtonLink } from '../Button/GreenButtonLink'
import { lighten } from 'polished'

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const ActionDescription = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const ExtraInfoContainer = styled.tr`
  background-color: ${lighten('0.05', '#353438')};
`

const Styles = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        :first-child {
          th {
            padding: 1rem 0;
            font-size: 1.3rem;
          }
        }
      }
    }

    th {
      text-align: left;
    }
    th,
    td {
      padding: 0.75rem;
    }

    tbody {
      th,
      td {
        border-top: 1px solid #212121;
        border-bottom: 1px solid #212121;
      }
      td {
        &:first-letter {
          text-transform: capitalize;
        }
        &:last-child {
          white-space: nowrap;
          padding-right: 0;
          text-align: right;
        }
      }
    }
  }
`

const Icon = styled.i.attrs({
  src: (props) => props.src
})`
  width: 48px;
  padding: 1rem 0.5rem 1rem 1rem;
`

const Sort = <Icon className="far fa-sort" />
const SortUp = <Icon className="far fa-sort-up" />
const SortDown = <Icon className="far fa-sort-down" />

const SortableListComponent = ({
  data,
  headers,
  title,
  company,
  linkToForm,
  linkToFormTitle,
  limit,
  limitText,
  actionDescription = null,
  extraInfoRow = null
}) => {
  const columns = React.useMemo(() => headers)

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useSortBy
  )

  const amount = data.length

  const limitReached = amount >= limit

  const ListBottom = limitReached ? (
    <div>{limitText}</div>
  ) : (
    <div>
      {linkToForm && (
        <GreenButtonLink to={linkToForm}>
          {linkToFormTitle || t`Add new`}
        </GreenButtonLink>
      )}
    </div>
  )

  return (
    <div>
      <h1>{title}</h1>
      <Styles>
        <table {...getTableProps()}>
          <thead>
            <tr>
              <th colSpan="7"> {company && company.name}</th>
            </tr>
            {extraInfoRow && (
              <ExtraInfoContainer>{extraInfoRow}</ExtraInfoContainer>
            )}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {typeof column.Header !== 'function' && (
                      <span>
                        {/* eslint-disable-next-line */}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? SortDown
                            : SortUp
                          : Sort}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {amount < 1 ? (
              <tr>
                <td
                  colSpan="7"
                  style={{ textAlign: 'left' }}
                >{t`This is list is empty.`}</td>
              </tr>
            ) : (
              rows.map(
                (row) =>
                  prepareRow(row) || (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
              )
            )}
          </tbody>
        </table>
      </Styles>

      {actionDescription && (
        <ActionDescription>{actionDescription}</ActionDescription>
      )}
      <ActionButtons>{ListBottom}</ActionButtons>
    </div>
  )
}

export default SortableListComponent
