import { combineReducers } from 'redux'

import { RESET_ERROR_MESSAGE } from 'store/actions/error'
import auth from './auth'
import session from './session'
import CRUDReducer from './CRUD'
import tour from './tour'

const errorMessage = (state = null, action) => {
  const { type, error, payload } = action

  if (type === RESET_ERROR_MESSAGE) {
    return null
  } else if (error && payload.generalError) {
    const { status, statusText, messages } = payload
    return { status, statusText, messages }
  }

  return state
}

// QUESTION TYPES
// TODO MOVE SOMEWHERE ELSE
export const QUESTION_TYPE_TEXTAREA = 2
export const QUESTION_TYPE_RADIO = 3
export const QUESTION_TYPE_NUMBER = 4
export const QUESTION_TYPE_CHECKBOX = 5
export const QUESTION_TYPE_SLIDER = 8

export const QUESTION_TYPES = [
  { name: 'Textarea', id: QUESTION_TYPE_TEXTAREA },
  { name: 'Radio', id: QUESTION_TYPE_RADIO },
  { name: 'Number', id: QUESTION_TYPE_NUMBER },
  { name: 'Checkbox', id: QUESTION_TYPE_CHECKBOX },
  { name: 'Slider', id: QUESTION_TYPE_SLIDER }
]

// TIMING VALUES
const TIMING_VALUE_ONE_WEEK = 7
const TIMING_VALUE_TWO_WEEK = 14
const TIMING_VALUE_THREE_WEEK = 21
const TIMING_VALUE_FOUR_WEEK = 28

export const TIMING_VALUES = [
  { name: 'Once a week', id: TIMING_VALUE_ONE_WEEK },
  { name: 'Once every two weeks', id: TIMING_VALUE_TWO_WEEK },
  { name: 'Once every three weeks', id: TIMING_VALUE_THREE_WEEK },
  { name: 'Once every four weeks', id: TIMING_VALUE_FOUR_WEEK }
]

export const REDUCER_QUESTIONNAIRES = 'QUESTIONNAIRES'
export const REDUCER_USERS = 'USERS'
export const REDUCER_GROUPS = 'GROUPS'
export const REDUCER_QUESTIONS = 'QUESTIONS'
export const REDUCER_CHARTS = 'REPORT'
export const REDUCER_COMPANIES = 'COMPANIES'
export const REDUCER_REMINDER = 'REMINDER'
export const REDUCER_NOTIFY = 'NOTIFY'
export const REDUCER_PLANS = 'PLANS'
export const REDUCER_SUBSCRIPTION = 'SUBSCRIPTION'

const appReducer = combineReducers({
  auth,
  error: errorMessage,
  session,
  tour,
  [REDUCER_COMPANIES]: CRUDReducer(REDUCER_COMPANIES),
  [REDUCER_USERS]: CRUDReducer(REDUCER_USERS),
  [REDUCER_GROUPS]: CRUDReducer(REDUCER_GROUPS),
  [REDUCER_QUESTIONNAIRES]: CRUDReducer(REDUCER_QUESTIONNAIRES),
  [REDUCER_QUESTIONS]: CRUDReducer(REDUCER_QUESTIONS),
  [REDUCER_CHARTS]: CRUDReducer(REDUCER_CHARTS),
  [REDUCER_REMINDER]: CRUDReducer(REDUCER_REMINDER),
  [REDUCER_NOTIFY]: CRUDReducer(REDUCER_NOTIFY),
  [REDUCER_PLANS]: CRUDReducer(REDUCER_PLANS),
  [REDUCER_SUBSCRIPTION]: CRUDReducer(REDUCER_SUBSCRIPTION)
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_PENDING') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
