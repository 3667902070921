import { Component } from 'react'
import { t } from 'ttag'
import DragHandle from './DragHandle'
import DeleteConfirmation from './DeleteConfirmation'
import { REDUCER_QUESTIONS } from 'store/reducers'
import NewQuestion from './NewQuestion'
import { QUESTION_TYPES, TIMING_VALUES } from 'store/reducers'
import React from 'react'
import styled from 'styled-components/macro'
import RowMenu from './RowMenu'

const Cell = styled.div`
  padding: 0.75rem 0rem;
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #212121;
`

const SmallText = styled.span`
  font-size: 0.7rem;
`

export default class SingleQuestion extends Component {
  state = {
    open: false
  }

  render() {
    const { open } = this.state
    const { data, parent, company, isStale, readOnly } = this.props

    const fieldType = QUESTION_TYPES.find(type => type.id === data.field_type)
    const timingInDays = TIMING_VALUES.find(
      type => type.id === data.timing_in_days
    )

    return [
      !open && (
        <Row>
          {!readOnly && (
            <Cell style={{ width: '5%' }}>
              <DragHandle />
            </Cell>
          )}
          <Cell style={{ width: '45%' }}>
            <strong>{data.label}</strong>
            <br />
            <SmallText>{data.help_text}</SmallText>
          </Cell>
          <Cell style={{ width: '20%' }}>
            <SmallText>{fieldType.name}</SmallText>
          </Cell>
          <Cell style={{ width: '25%' }}>
            <SmallText>{timingInDays.name}</SmallText>
          </Cell>
          {!readOnly && (
            <Cell style={{ width: '5%' }}>
              <RowMenu>
                <button
                  onClick={() => this.setState({ open: !this.state.open })}
                >
                  {t`Edit`}
                </button>
                <DeleteConfirmation
                  reducer={REDUCER_QUESTIONS}
                  query={`/api/companies/${company}/questionnaires/${parent}/questions/${
                    data.id
                  }/`}
                  onSuccess={() => isStale(REDUCER_QUESTIONS)}
                />
              </RowMenu>
            </Cell>
          )}
        </Row>
      ),
      open && (
        <Row>
          <Cell>
            <NewQuestion
              {...this.props}
              parent={parent}
              id={data.id}
              data={data}
              noShadow={true}
              onCancel={() => this.setState({ open: false })}
            />
          </Cell>
        </Row>
      )
    ]
  }
}
