export const TOUR_START = 'TOUR_START'
export const TOUR_END = 'TOUR_END'
export const BACK_TO_SURVEYS = 'BACK_TO_SURVEYS'

export const startTour = () => dispatch => {
  dispatch({
    type: TOUR_START
  })
}

export const endTour = () => dispatch => {
  dispatch({
    type: TOUR_END
  })
}

export const visitedDashboardOnce = () => dispatch => {
  dispatch({
    type: BACK_TO_SURVEYS
  })
}
