import produce from 'immer'
import { t } from 'ttag'
import typeToReducer from 'type-to-reducer'

const company = sessionStorage.getItem('company')

const initialState = {
  company: company ? JSON.parse(company) : null,
  sideMenuActive: false,
  notification: null
}

/**
 * User's session related attributes
 *
 * Selected company
 * UI language (?)
 *
 * @param state
 * @param action
 * @returns {*}
 */

export default typeToReducer(
  {
    CHANGE_SELECTED_COMPANY: (state, { payload }) =>
      produce(state, draft => {
        draft.sideMenuActive = payload !== null
        draft.company = payload
        sessionStorage.setItem('company', JSON.stringify(payload))
      }),
    CLEAR_NOTIFICATION: state => {
      produce(state, draft => {
        draft.notification = null
      })
    },
    // GROUP NOTIFICATIONS
    CREATE_GROUPS: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Group added successfully!`
          }
        })
    },
    EDIT_GROUPS: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Group information successfully updated!`
          }
        })
    },
    REMOVE_GROUPS: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Group removed successfully!`
          }
        })
    },

    // USER NOTIFICATIONS
    CREATE_USERS: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Person added successfully!`
          }
        })
    },
    EDIT_USERS: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Person information successfully updated!`
          }
        })
    },
    REMOVE_USERS: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`User removed successfully!`
          }
        })
    },

    // QUESTIONNAIRE NOTIFICATIONS
    CREATE_QUESTIONNAIRES: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Survey added successfully!`
          }
        }),
      REJECTED: (
        state,
        {
          payload: {
            messages: { non_field_errors }
          }
        }
      ) => {
        return produce(state, draft => {
          draft.notification = {
            type: 'error',
            message: non_field_errors[0]
          }
        })
      }
    },
    EDIT_QUESTIONNAIRES: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Survey information successfully updated!`
          }
        })
    },
    REMOVE_QUESTIONNAIRES: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Survey removed successfully!`
          }
        })
    },
    PUT_REMINDER: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Reminder successfully sent!`
          }
        })
    },
    PUT_NOTIFY: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Notifier successfully sent!`
          }
        })
    }
    /*     REGISTER_ORG: {
      FULFILLED: state =>
        produce(state, draft => {
          draft.notification = {
            type: 'success',
            message: t`Sign up successful!`
          }
        })
    } */
  },
  initialState
)
