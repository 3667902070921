import React, { Component } from 'react'
import { t } from 'ttag'
import styled from 'styled-components/macro'
import { QUESTION_TYPES, TIMING_VALUES } from 'store/reducers'
import { REDUCER_QUESTIONNAIRES, REDUCER_QUESTIONS } from '../store/reducers'
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import SingleQuestion from './SingleQuestion'

const Title = styled.div`
  font-size: 1.3rem;
  padding: 1rem 0rem;
`

const Container = styled.div`
  width: 100%;
`

const Heading = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  border-bottom: 1px solid #212121;
`

const Head = styled.div``

const SortableItem = SortableElement(SingleQuestion)
const SortableList = SortableContainer(props => (
  <div>
    {props.data.map((item, index) => {
      return (
        <SortableItem
          {...props}
          index={index}
          key={`item-${index}`}
          data={item}
        />
      )
    })}
  </div>
))

export default class Questions extends Component {
  state = {
    items: []
  }

  componentDidMount() {
    const { data } = this.props
    this.setState({ items: data })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props
    if (prevProps.data !== data) {
      this.setState({ items: data })
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state
    const { company, parent, edit } = this.props
    const object = items[oldIndex]

    if (!isNaN(newIndex)) {
      // Shorthand's for query params
      const questionnaires = REDUCER_QUESTIONNAIRES.toLowerCase()
      const questions = REDUCER_QUESTIONS.toLowerCase()
      const query = `/api/companies/${company}/${questionnaires}/${parent}/${questions}/${
        object.id
      }/`

      // Order data
      const data = { order: newIndex }

      edit(REDUCER_QUESTIONS, data, company, object, query)

      this.setState({
        items: arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  render() {
    const { parent, company, readOnly } = this.props
    const { items } = this.state

    let questions = <i>{t`This survey does not have any questions added.`}</i>

    if (items.length > 0) {
      questions = (
        <SortableList
          {...this.props}
          company={company}
          parent={parent}
          data={items}
          useDragHandle
          axis="y"
          lockAxis="y"
          onSortEnd={this.onSortEnd}
          readOnly={readOnly}
        />
      )
    }

    return (
      <Container>
        {items.length > 0 && (
          <Heading>
            {!readOnly && <Head style={{ width: '5%' }} />}
            <Head style={{ width: '45%' }}>{t`Name`}</Head>
            <Head style={{ width: '20%' }}>{t`Type`}</Head>
            <Head style={{ width: '20%' }}>{t`Timing`}</Head>
            <Head style={{ width: '10%' }} />
          </Heading>
        )}
        {questions}
      </Container>
    )
  }
}
