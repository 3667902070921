import { encodeQueryParams } from 'util/url'
import ApiError from 'ApiError'
import { getLocale } from '../i18nInit'
import 'unfetch/polyfill'

const ApiCall = (path, method, body, overrideHeaders = null) => {
  let headers = {}
  if (overrideHeaders == null) {
    headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  } else {
    headers = overrideHeaders
  }

  headers['Accept-Language'] = getLocale()

  // For authentication
  const token = sessionStorage.getItem('API_TOKEN')
  headers.Authorization = token ? `Token ${token}` : null

  return fetch(path, {
    headers,
    method,
    mode: 'cors',
    body: body || undefined
  }).then(response =>
    response[response.status === 204 ? 'text' : 'json']()
      .then(json => {
        if (!response.ok) {
          const Error = new ApiError(response, json)

          return Promise.reject(Error)
        }

        return json
      })
      .catch(error => Promise.reject(error))
  )
}

export function Get(path, params = {}) {
  return ApiCall(path + encodeQueryParams(params), 'GET', null, null)
}

export function Post(path, body, overrideHeaders = null) {
  return ApiCall(path, 'POST', body, overrideHeaders)
}

export function Put(path, body) {
  return ApiCall(path, 'PUT', body, null)
}

export function Patch(path, body) {
  return ApiCall(path, 'PATCH', body, null)
}

export function Delete(path) {
  return ApiCall(path, 'DELETE', null, null)
}
