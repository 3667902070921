import React, { Component, useState } from 'react'
import { t } from 'ttag'
import styled from 'styled-components/macro'

import Label from '../Label/Label'
import InputWithLabel from '../InputWithLabel/InputWithLabel'

const Container = styled.div`
  width: 100%;
  display: flex;
`

const Section = styled.div`
  flex: 1;

  &:first-child {
    margin-right: 1rem;
  }
`

const Selector = styled.div`
  background: linear-gradient(180deg, #39383c 0%, #2f2e32 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

const Item = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #212121;
  padding: 0 1rem;
  cursor: ${(props) => (props.readOnly ? 'normal' : 'pointer')};
`

const User = styled.div``

const Nothing = styled.div`
  padding: 1rem;
  font-style: italic;
`

const Icon = styled.i`
  width: 32px;
  height: auto;
  margin-left: ${(props) => (props.alignRight ? 'auto' : 'none')};
`

const Role = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
`

const Name = styled.span``

const SingleUser = (props) => {
  const onDoubleClickAction = () => {
    const {
      onDoubleClick,
      data: { id }
    } = props
    onDoubleClick(id)
  }

  const { data, actionIcon, readOnly, onDoubleClick } = props

  const groups =
    data.company_groups &&
    data.company_groups.map((item, index, array) => {
      const postfix = ', '
      const lastOfArray = array.length - 1 === index
      return lastOfArray ? `${item.name}` : `${item.name}${postfix}`
    })

  const name = data.full_name || data.username

  const action = onDoubleClick ? onDoubleClickAction : null

  const { isLate } = data

  return (
    <Item onClick={action} readOnly={readOnly}>
      <Icon className={['far', isLate ? 'fa-snooze' : 'fa-user']} />
      <User>
        <Name>{name}</Name>
        <Role>{groups}</Role>
      </User>
      {actionIcon}
    </Item>
  )
}

const SingleGroup = (props) => {
  const onDoubleClickAction = () => {
    const {
      onDoubleClick,
      data: { users }
    } = props
    onDoubleClick(users)
  }

  const {
    data,
    data: { name },
    actionIcon,
    readOnly,
    onDoubleClick
  } = props

  const groups =
    data.company_groups &&
    data.company_groups.map((item, index, array) => {
      const postfix = ', '
      const lastOfArray = array.length - 1 === index
      return lastOfArray ? `${item.name}` : `${item.name}${postfix}`
    })

  const action = onDoubleClick ? onDoubleClickAction : null

  return (
    <Item onClick={action} readOnly={readOnly}>
      <Icon className="fa fa-users" />
      <User>
        <Name>{name}</Name>
        <Role>{groups}</Role>
      </User>
      {actionIcon}
    </Item>
  )
}

const ResultContainer = styled.div`
  overflow: overlay;
  min-height: ${(props) => (props.enableFilter ? '15rem' : '19.25rem')};
  max-height: ${(props) => (props.enableFilter ? '40rem' : '44.25rem')};
`
const FilterContainer = styled.div`
  padding: 1rem;
`

const SingleSelector = (props) => {
  const [filterValue, setfilter] = useState('')

  let { label, data, enableFilter } = props

  data = data.filter((item) => {
    const testString = String(item.props.data.full_name).toLowerCase()
    const testString2 = String(item.props.data.name).toLowerCase()
    const needle = String(filterValue).toLowerCase()
    return testString.includes(needle) || testString2.includes(needle)
  })

  if (data.length < 1) {
    data = <Nothing>{t`Nothing to show.`}</Nothing>
  }

  return (
    <Section>
      <Label>{label}</Label>
      <Selector>
        {enableFilter && (
          <FilterContainer>
            <InputWithLabel
              name="filter"
              autocomplete="off"
              value={filterValue}
              onChange={(e) => setfilter(e.target.value)}
              placeholder={t`Start typing to find user or group`}
            />
          </FilterContainer>
        )}
        <ResultContainer className="select-user" enableFilter={enableFilter}>
          {data}
        </ResultContainer>
      </Selector>
    </Section>
  )
}

export { SingleSelector, SingleGroup, SingleUser }

export default class UserSelector extends Component {
  handleAddToGroup = (value) => {
    const { selected, all, onChange, name } = this.props
    const listObject = all.find((item) => parseInt(item.id) === parseInt(value))
    const groupList = selected.slice()
    const existing = selected.find(
      (item) => parseInt(item.id) === parseInt(value)
    )
    if (!existing) groupList.push(listObject)
    onChange(name, groupList)
  }

  handleGroupToGroup = (users) => {
    const { selected, all, onChange, name } = this.props
    const groupList = selected.slice()
    for (let id in users) {
      const item = users[id]
      const existing = selected.find((old) => old.id === item.id)
      if (!existing) groupList.push(item)
    }
    onChange(name, groupList)
  }

  handleRemoveFromGroup = (value) => {
    const { selected, onChange, name } = this.props
    const userList = selected.filter(
      (item) => parseInt(item.id) !== parseInt(value)
    )
    onChange(name, userList)
  }

  render() {
    const {
      all,
      selected,
      groups,
      allUsersTitle,
      groupsUsersTitle,
      isTourOpen,
      toTourStep
    } = this.props

    // Available company groups
    let allGroups = []

    if (groups) {
      allGroups = groups.map((group) => (
        <SingleGroup
          data={group}
          actionIcon={<Icon alignRight className="fas fa-chevron-right" />}
          onDoubleClick={this.handleGroupToGroup}
        />
      ))
    }

    // Filter out selected users
    const selectedIds = selected.map((item) => item.id)
    const allUsers = allGroups.concat(
      all
        .filter((user) => !selectedIds.includes(user.id))
        .map((user) => (
          <SingleUser
            data={user}
            actionIcon={<Icon alignRight className="fas fa-chevron-right" />}
            onDoubleClick={(value) => {
              this.handleAddToGroup(value)
              if (isTourOpen) {
                toTourStep(1)
              }
            }}
          />
        ))
    )

    // Users in group
    const usersInGroup = selected.map((user) => (
      <SingleUser
        data={user}
        actionIcon={<Icon alignRight className="fas fa-times" />}
        onDoubleClick={this.handleRemoveFromGroup}
      />
    ))

    return (
      <Container>
        <SingleSelector enableFilter label={allUsersTitle} data={allUsers} />
        <SingleSelector label={groupsUsersTitle} data={usersInGroup} />
      </Container>
    )
  }
}
