// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { REDUCER_SUBSCRIPTION } from '../store/reducers'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import { t } from 'ttag'
import ListComponent from '../components/Lists/ListComponent'
import moment from 'moment'
import DeleteConfirmation from '../components/DeleteConfirmation'
import Submit from '../components/FormElements/Inputs/Submit/Submit'
import Reactour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { endTour } from 'store/actions/tour'
import infoLogo from 'assets/info.png'
import styled from 'styled-components/macro'
import ReactTooltip from 'react-tooltip'

const InfoContainer = styled.div`
  display: flex;
`

const Info = styled.img`
  margin-left: 5px;
`

const TABLE_HEADINGS = [
  {
    title: t`Plan`,
    colSpan: 1
  },
  {
    title: t`Status`,
    colSpan: 1
  },
  {
    title: (
      <InfoContainer>
        {t`Usage this period`}
        <Info src={infoLogo} data-tip data-for="infoText" alt="info" />
        <ReactTooltip id="infoText" type="dark" effect="solid">
          <span>{t`Billing is based on the maximum number of personnel at the present billing cycle.`}</span>
        </ReactTooltip>
      </InfoContainer>
    ),
    colSpan: 1
  },
  {
    title: t`Subscription ending date`,
    colSpan: 1
  },
  {
    title: t`Subscription canceled at`,
    colSpan: 1
  },
  {
    title: null,
    colSpan: 2
  }
]

class Subscription extends Component {
  componentDidMount = () => {
    const {
      session: { company },
      list
    } = this.props
    list(
      REDUCER_SUBSCRIPTION,
      null,
      `/api/stripe/companies/${company.id}/subscriptions/`
    )
  }

  disableBody = (target) => disableBodyScroll(target)

  enableBody = (target) => enableBodyScroll(target)

  onDelete = () => {
    const { isStale } = this.props
    isStale(REDUCER_SUBSCRIPTION)
  }

  render() {
    const {
      session: { company },
      [REDUCER_SUBSCRIPTION]: { data },
      tour: { tourOn },
      endTour
    } = this.props

    let list = data.map((item) => {
      const endDate = moment(item.current_period_end).format('L')
      const canceledAt = item.canceled_at
        ? moment(item.canceled_at).format('L')
        : null

      const endSubscriptionButton = (
        <DeleteConfirmation
          reducer={REDUCER_SUBSCRIPTION}
          query={`/api/stripe/companies/${company.id}/subscriptions/${item.subscription_id}/`}
          onSuccess={this.onDelete}
          onFailure={() => {}}
          ActionButton={
            <Submit
              type="button"
              style={{ marginLeft: 'auto' }}
              destructive
            >{t`End subscription`}</Submit>
          }
        />
      )

      let usage = item.usage_record
      const currencySymbol = item.plan.currency === 'usd' ? '$' : '€'

      if (item.usage_record) {
        usage += ` ${t`users *`} ${item.plan.amount}${currencySymbol} = ${
          item.plan.amount * item.usage_record
        }${currencySymbol}`
      }

      let status = item.status

      if (status === 'active') {
        status = t`Active`
      }

      return {
        key: item.id,
        items: [
          item.plan.nickname,
          status,
          usage,
          endDate,
          canceledAt,
          endSubscriptionButton
        ]
      }
    })

    const steps = [
      {
        selector: '.link-to-form',
        style: {
          color: '#353438'
        },
        content: () => (
          <div>
            <p>
              {t`From the Subscriptions tab you can upgrade or change your plan.`}
            </p>
          </div>
        )
      }
    ]

    return (
      <>
        <ListComponent
          headers={TABLE_HEADINGS}
          data={list}
          company={company}
          linkToForm="/plans"
          linkToFormTitle={t`Change your plan`}
        />
        <Reactour
          steps={steps}
          isOpen={tourOn}
          onRequestClose={endTour}
          showNumber={false}
          rounded={5}
          lastStepNextButton={t`Done`}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          showNavigation={false}
          showNavigationNumber={false}
        />
      </>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions, endTour }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
