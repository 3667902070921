import React from 'react'

export default function GenericFormHOC(
  WrapperComponent,
  DEFAULT_FIELDS = {},
  objectReducer = null,
  returnUrl = null,
  objectId = null,
  data = null
) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        object: false, // Existing object for form
        fields: DEFAULT_FIELDS,
        errors: {}
      }
      this.handleChange = this.handleChange.bind(this)
    }

    /**
     * This method keeps form up-to-date after hard refreshes
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
      const {
        [objectReducer]: { data }
      } = this.props
      if (prevProps[objectReducer].data !== data) {
        this.populateDataByParamId()
      }
    }

    populateDataByParamId = () => {
      try {
        const {
          match: { params }
        } = this.props
        if ('id' in params) {
          const id = parseInt(params.id)
          this.handleSetObjectId(id)
          this.getExistingObjectData(id)
          return true
        }
      } catch (e) {
        console.error(e)
      }
      return false
    }

    getExistingObjectData(id) {
      const {
        [objectReducer]: { data }
      } = this.props
      const { fields } = this.state

      if (data) {
        const user = data.find(object => object.id === id)
        if (user) {
          this.setState({
            fields: {
              ...fields,
              ...user
            }
          })
        }
      }
    }

    handleChange = e => {
      const { fields } = this.state
      this.setState({
        fields: { ...fields, [e.target.name]: e.target.value }
      })
    }

    handleToggle = e => {
      const { fields } = this.state
      this.setState({
        fields: { ...fields, [e.target.name]: !fields[e.target.name] }
      })
    }

    handlePopulateFields = fields => {
      this.setState({ fields: { ...fields } })
    }

    handleSetObjectId = id => {
      this.setState({ object: id })
    }

    handleSetFieldValue = (field, value) => {
      const { fields } = this.state
      this.setState({
        fields: { ...fields, [field]: value }
      })
    }

    handleSetErrorValue = (field, value) => {
      const { errors } = this.state

      this.setState({
        errors: { ...errors, [field]: value }
      })
    }

    /**
     * The problems by setting errors one by one is that the previous errors are not cleared, this goes around it but not very elegantly unfortunately
     */

    bulkSetErrors = errors => {
      const bulk = {}
      Object.entries(errors).forEach(([field, arr]) => {
        const message = arr[0]
        bulk[field] = message
      })

      this.setState({
        errors: { ...bulk }
      })
    }

    handleClearForm = () => {
      this.setState({
        fields: DEFAULT_FIELDS
      })
    }

    handleSaveOrCreate = (data, company, query = '') => {
      const { object } = this.state

      const { create, edit, history } = this.props

      const method = object ? edit : create
      return method(objectReducer, data, company, object, query).then(
        response => {
          const { messages } = response
          switch (response.status) {
            case 400:
              /* Handle 400 Bad Response answer. Try to map error message's fields to form fields. */
              for (const message in Object.keys(messages)) {
                const fieldName = Object.keys(messages)[message]
                const fieldValue = messages[fieldName]
                this.handleSetErrorValue(fieldName, fieldValue)
              }
              break
            default:
              if (returnUrl) history.push(returnUrl)
              break
          }
          return response
        }
      )
    }

    render() {
      const { fields, errors, object } = this.state

      return (
        <WrapperComponent
          {...this.props}
          fields={fields}
          errors={errors}
          object={object}
          populateDataByParamId={this.populateDataByParamId}
          handleChange={this.handleChange}
          handleToggle={this.handleToggle}
          handlePopulateFields={this.handlePopulateFields}
          getExistingObjectData={this.getExistingObjectData}
          handleSetObjectId={this.handleSetObjectId}
          handleSetFieldValue={this.handleSetFieldValue}
          handleSetErrorValue={this.handleSetErrorValue}
          handleSaveOrCreate={this.handleSaveOrCreate}
          handleClearForm={this.handleClearForm}
          bulkSetErrors={this.bulkSetErrors}
        />
      )
    }
  }
}
