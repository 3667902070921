import React from 'react'
import styled, { css } from 'styled-components/macro'

const Link = styled.a`
  width: 100%;
  display: flex;
  cursor: pointer;
  transition: background-color 200ms linear;
  color: white;
  text-decoration: none;

  &:hover {
    background-color: gray;
    transition: background-color 200ms linear;
  }

  ${props =>
    props.disabled &&
    css`
      color: gray;
      pointer-events: none;
      cursor: pointer;
    `}
`

const Icon = styled.i.attrs({
  src: props => props.src
})`
  width: 48px;
  padding: 1rem 0.5rem 1rem 1rem;
`

const Title = styled.div`
  padding: 1rem 0.5rem;
  flex-grow: 1;
`

const ExternalLinkElement = ({
  href,
  icon,
  title,
  onClick,
  disabled,
  ...rest
}) => (
  <Link {...rest} href={href} onClick={!disabled ? onClick : () => {}}>
    <Icon className={`fas ${icon}`} />
    <Title>{title}</Title>
  </Link>
)

export default ExternalLinkElement
