import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const GreenButtonLink = styled(Link)`
  padding: 0 1rem;
  line-height: 36px;
  display: inline-block;
  text-decoration: none;
  font-size: 0.8rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: ${props => (props.secondary ? '#CEA766' : '#009f86')};
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
  box-shadow: 0px 5px 5px 0px rgba(39, 39, 39, 0.75);

  :disabled {
    background: gray;
  }

  &:hover {
    color: #f2f2f2;
    background: ${props => (props.secondary ? '#e1ba68' : '#00b498')};
    transition: background 200ms;
  }
`
