import { t } from 'ttag'
import React, { Component } from 'react'
import * as actions from 'store/actions/CRUD'
import InputWithLabel from './FormElements/Inputs/InputWithLabel/InputWithLabel'
import styled from 'styled-components/macro'
import {
  QUESTION_TYPES,
  TIMING_VALUES,
  QUESTION_TYPE_CHECKBOX,
  QUESTION_TYPE_NUMBER,
  QUESTION_TYPE_RADIO,
  QUESTION_TYPE_SLIDER,
  QUESTION_TYPE_TEXTAREA,
  REDUCER_QUESTIONNAIRES,
  REDUCER_QUESTIONS
} from '../store/reducers'

import MinMaxValueList from './FormElements/Inputs/MinMaxValuesList/MinMaxValuesList'
import Label from './FormElements/Inputs/Label/Label'
import ChoicesList from './FormElements/Inputs/ChoicesList/ChoicesList'
import Submit from './FormElements/Inputs/Submit/Submit'

const DEFAULT_FIELDS = {
  choices: [],
  default: '',
  field_type: '',
  help_text: '',
  label: '',
  placeholder_text: '',
  timing_in_days: null
}

const FIELD_PLACEHOLDER = t`Select value from the list`

const FIELD_TYPE_TO_COMPONENT = {
  [QUESTION_TYPE_TEXTAREA]: null,
  [QUESTION_TYPE_NUMBER]: null,
  [QUESTION_TYPE_RADIO]: ChoicesList,
  [QUESTION_TYPE_CHECKBOX]: ChoicesList,
  [QUESTION_TYPE_SLIDER]: MinMaxValueList
}

const Container = styled.div`
  width: 100%;
`

const Form = styled.form`
  width: 100%;
`

const FormContainer = styled.table`
  width: 100%;
`

const TextArea = styled.textarea`
  border: ${props => (props.error ? '1px solid red' : 'none')};
  border-radius: 4px;
  background-color: #535252;
  color: #ffffff;
  padding-left: 0.25rem;
  width: 100%;
`

const SelectWithLabel = styled.select`
  border: ${props => (props.error ? '1px solid red' : 'none')};
  border-radius: 4px;
  background-color: #535252;
  color: #ffffff;
  padding-left: 0.25rem;
  height: 36px;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 1rem;
  }

  & > button:last-child {
    margin-right: 0;
  }
`

export default class SingleQuestionForm extends Component {
  componentDidMount() {
    const { id, data } = this.props
    this.props.handlePopulateFields(data)
    this.props.handleSetObjectId(id)
  }

  getCompanyId() {
    const {
      session: { company }
    } = this.props
    return company.id
  }

  handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    const { fields, object, parent } = this.props

    // Remove field ID if does exists
    delete fields.id

    // Make sure that choices is not null but an empty list
    if (!fields.choices) fields.choices = []

    // If we have slider and it's empty, use some sane defaults
    if (
      Array.isArray(fields.choices) &&
      fields.choices.length === 0 &&
      parseInt(fields.field_type, 10) === QUESTION_TYPE_SLIDER
    )
      fields.choices = [0, 10, 1]

    // Shorthand's for query params
    const companyId = this.getCompanyId()
    const parentFormId = parent
    const questionId = object
    const questionnaires = REDUCER_QUESTIONNAIRES.toLowerCase()
    const questions = REDUCER_QUESTIONS.toLowerCase()

    const createNewQuestion = `/api/companies/${companyId}/${questionnaires}/${parentFormId}/${questions}/`
    const editExistingQuestion = `${createNewQuestion}${questionId}/`

    this.handleCreate(
      fields,
      null,
      this.isEditMode() ? editExistingQuestion : createNewQuestion
    )
  }

  handleCreate = (data, company, query = '') => {
    const {
      create,
      edit,
      object,
      handleSetErrorValue,
      handleClearForm,
      isStale,
      bulkSetErrors
    } = this.props

    const method = this.isEditMode() ? edit : create

    method(REDUCER_QUESTIONS, data, company, object, query).then(response => {
      const { messages } = response

      switch (response.status) {
        case 400:
          /* Handle 400 Bad Response answer. Try to map error message's fields to form fields. */
          /* for (const message in Object.keys(messages)) {
            const fieldName = Object.keys(messages)[message]
            const fieldValue = messages[fieldName]
            handleSetErrorValue(fieldName, fieldValue)
          } */

          // This is a quick fix around the problem of settings errors one by one
          // Follow this function to the form hoc
          bulkSetErrors(messages)

          break
        default:
          handleClearForm()
          isStale(REDUCER_QUESTIONS)
          break
      }
    })
  }

  isEditMode() {
    const { object } = this.props
    return !!object
  }

  render() {
    const {
      fields,
      errors,
      handleChange,
      handleSetFieldValue,
      onCancel,
      noShadow
    } = this.props

    const fieldTypes = QUESTION_TYPES.map(item => (
      <option value={item.id}>{item.name}</option>
    ))
    const timingValues = TIMING_VALUES.map(item => (
      <option value={item.id}>{item.name}</option>
    ))

    const ChoiceComponent = FIELD_TYPE_TO_COMPONENT[fields.field_type]

    return (
      <Container noShadow={noShadow}>
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <FormContainer>
            <tr>
              <td>
                <InputWithLabel
                  group="question"
                  label={t`Question`}
                  name="label"
                  onChange={handleChange}
                  value={fields.label}
                  error={errors.label}
                />
              </td>
              <td>
                <Label>{t`Field type`}</Label>
                <br />
                <SelectWithLabel
                  name="field_type"
                  onChange={handleChange}
                  value={fields.field_type}
                  error={errors.field_type}
                >
                  <option>{FIELD_PLACEHOLDER}</option>
                  {fieldTypes}
                </SelectWithLabel>
              </td>
              <td>
                <Label>{t`Timing`}</Label>
                <br />
                <SelectWithLabel
                  name="timing_in_days"
                  onChange={handleChange}
                  value={fields.timing_in_days || ''}
                  error={errors.timing_in_days}
                >
                  <option>{FIELD_PLACEHOLDER}</option>
                  {timingValues}
                </SelectWithLabel>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <Label>{t`Type here additional information for respondents`}</Label>
                <br />
                <TextArea
                  rows={3}
                  name="help_text"
                  onChange={handleChange}
                  value={fields.help_text}
                  error={errors.help_text}
                />
              </td>
              <td style={{ verticalAlign: 'top' }}>
                {ChoiceComponent && (
                  <ChoiceComponent
                    values={fields.choices || []}
                    onChange={choices =>
                      handleSetFieldValue('choices', choices)
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <ButtonContainer>
                  <Submit onClick={onCancel} secondary type="button">
                    {t`Cancel`}
                  </Submit>
                  <Submit type="submit">{t`Save`}</Submit>
                </ButtonContainer>
              </td>
            </tr>
          </FormContainer>
        </Form>
      </Container>
    )
  }
}
