import { Get, Post, Patch, Delete, Put } from 'api/index'

export const LIST = 'LIST'
export const IS_STALE = 'IS_STALE'
export const CREATE = 'CREATE'
export const EDIT = 'EDIT'
export const REMOVE = 'REMOVE'
export const PUT = 'PUT'

// TODO Move this to .env or similar
const BASE_URL = '/api/companies/'

export const list = (property, companyId, query = '') => dispatch => {
  const url = query
    ? `${query}`
    : `${BASE_URL}${companyId}/${property.toLowerCase()}/`
  return dispatch({
    type: `${LIST}_${property.toUpperCase()}`,
    payload: Get(url)
  })
}

export const isStale = property => ({
  type: `${IS_STALE}_${property.toUpperCase()}`
})

export const create = (
  property,
  body,
  companyId,
  objectId,
  query = ''
) => dispatch => {
  const url = query
    ? query
    : `${BASE_URL}${companyId}/${property.toLowerCase()}/`
  return dispatch({
    type: `${CREATE}_${property.toUpperCase()}`,
    payload: Post(url, JSON.stringify(body))
  })
}

export const edit = (
  property,
  body,
  companyId,
  objectId,
  query = ''
) => dispatch => {
  const url = query
    ? query
    : `${BASE_URL}${companyId}/${property.toLowerCase()}/${objectId}/`
  return dispatch({
    type: `${EDIT}_${property.toUpperCase()}`,
    payload: Patch(url, JSON.stringify(body))
  })
}

export const remove = (property, query = '') => dispatch => {
  const url = query
  return dispatch({
    type: `${REMOVE}_${property.toUpperCase()}`,
    payload: Delete(url)
  })
}

export const put = (property, body, query = '') => dispatch => {
  const url = query
  return dispatch({
    type: `${PUT}_${property.toUpperCase()}`,
    payload: Put(url, JSON.stringify(body))
  })
}
