import React from 'react'
import styled from 'styled-components/macro'
import { t } from 'ttag'
import {
  ListBodyCell,
  ListCompanyTitle,
  ListContainer,
  ListHeadingCell,
  ListRow
} from 'components/Lists/StyledLists'
import { GreenButtonLink } from '../Button/GreenButtonLink'

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const ActionDescription = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const ListComponent = ({
  data,
  headers,
  title,
  company,
  linkToForm,
  linkToFormTitle,
  limit,
  limitText,
  actionDescription = null
}) => {
  let list = data.map((item) => {
    return (
      <ListRow key={item.id}>
        {item.items.map((cell) => (
          <ListBodyCell>{cell}</ListBodyCell>
        ))}
      </ListRow>
    )
  })

  const amount = list.length

  if (amount < 1)
    list = (
      <ListRow>
        <ListBodyCell
          colSpan={6}
          style={{ textAlign: 'left' }}
        >{t`This is list is empty.`}</ListBodyCell>
      </ListRow>
    )

  const limitReached = amount >= limit

  const ListBottom = limitReached ? (
    <div>{limitText}</div>
  ) : (
    <div>
      {linkToForm && (
        <GreenButtonLink className="link-to-form" to={linkToForm}>
          {linkToFormTitle || t`Add new`}
        </GreenButtonLink>
      )}
    </div>
  )

  return (
    <div>
      <h1>{title}</h1>
      <ListContainer>
        <ListRow>
          <ListCompanyTitle colSpan="5">
            {company && company.name}
          </ListCompanyTitle>
        </ListRow>
        <ListRow>
          {headers.map((item) => (
            <ListHeadingCell colSpan={item.colSpan}>
              {item.title}
            </ListHeadingCell>
          ))}
        </ListRow>
        {list}
      </ListContainer>
      <ActionDescription>{actionDescription}</ActionDescription>
      <ActionButtons>{ListBottom}</ActionButtons>
    </div>
  )
}

export default ListComponent
