import React from 'react'
import styled from 'styled-components/macro'

const Input = styled.input`
  border: ${props => (props.error ? '1px solid red' : 'none')};
  border-radius: 4px;
  background-color: #535252;
  color: #ffffff;
  padding-left: 0.25rem;
  height: 36px;
  width: 100%;
`

const Label = styled.label`
  height: 14px;
  color: #eaeaee;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`

const ErrorField = styled.span`
  padding: 5px;
`
const textInput = (
  props: Array<{
    group?: string,
    label: string,
    name: string,
    onChange: void,
    value: string,
    error: string,
    placeholder?: string,
    type?: string
  }>
) => {
  const {
    group,
    label,
    name,
    onChange,
    value,
    error,
    type,
    placeholder
  } = props

  const id = group ? `${group}-${name}` : name

  const inputType = type || 'text'
  return (
    <>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <div style={{ flex: 1 }}>
        <Input
          id={id}
          name={name}
          type={inputType}
          onChange={onChange}
          value={value}
          error={error}
          placeholder={placeholder}
        />

        {error && <ErrorField>{error}</ErrorField>}
      </div>
    </>
  )
}

textInput.defaultProps = {
  type: 'text',
  placeholder: null,
  group: null
}

export default textInput
