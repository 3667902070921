// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import * as actions from 'store/actions/CRUD'
import {
  REDUCER_QUESTIONNAIRES,
  REDUCER_QUESTIONS,
  REDUCER_NOTIFY,
  REDUCER_REMINDER
} from 'store/reducers'
import GenericFormHOC from 'components/FormElements/GenericFormHOC'
import Questions from 'components/Questions'
import Submit from 'components/FormElements/Inputs/Submit/Submit'
import UserList from 'components/FormElements/Inputs/UserList/UserList'
import moment from 'moment'
import GenericConfirmation from 'components/GenericConfirmation'
import Reactour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { endTour, visitedDashboardOnce } from 'store/actions/tour'

const FormContainer = styled.div`
  width: 100%;
`

const FormSection = styled.div`
  height: auto;
  max-height: ${props => (props.open ? '1000px' : '0')};
  display: inline-block;
  overflow: ${props => (props.open ? 'visible' : 'hidden')};
  transition: max-height 300ms;
  width: 100%;
`

const SplittedFormSection = styled(FormSection)`
  display: flex;
`

const Title = styled.div`
  font-size: ${props => (props.secondary ? '1rem' : '1.3rem')};
  padding: 1rem 0rem 1rem 0rem;
  font-weight: bold;
  text-transform: ${props => (props.secondary ? 'uppercase' : 'none')};
  color: ${props => (props.secondary ? '#009f86' : 'inherit')};
  cursor: ${props => (props.secondary ? 'pointer' : 'normal')};
`

const SeparatorLine = styled.hr`
  border: none;
  border-bottom: 1px solid #212121;
`

const Description = styled.span`
  font-size: 0.7rem;
`

const DEFAULT_FIELDS = {
  title: '',
  description: '',
  respondent_ids: []
}

class Dashboard extends Component {
  componentDidMount() {
    const { populateDataByParamId } = this.props
    const populate = populateDataByParamId()
    if (populate) this.setState({ editMode: true })
    this.updateQuestionList()
  }

  componentDidUpdate() {
    const questionReducerIsStale =
      this.getCompanyId() && this.props[REDUCER_QUESTIONS].isStale
    if (questionReducerIsStale) {
      this.updateQuestionList()
    }
  }

  /**
   * When component is unmounted clear all questions data from store
   */
  componentWillUnmount() {
    const { isStale } = this.props
    isStale(REDUCER_QUESTIONS)
  }

  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  updateQuestionList = () => {
    const {
      match: { params },
      session: { company },
      list,
      object
    } = this.props
    if (this.getCompanyId()) {
      const id = object ? object : parseInt(params.id)
      list(
        REDUCER_QUESTIONS,
        null,
        `/api/companies/${this.getCompanyId()}/${REDUCER_QUESTIONNAIRES.toLowerCase()}/${id}/${REDUCER_QUESTIONS.toLowerCase()}/`
      )
    }
  }

  onEnd = () => {
    const { handleSaveOrCreate, history } = this.props

    const data = {
      active_until: moment().toISOString(true)
    }

    handleSaveOrCreate(data, this.getCompanyId()).then(questionnaire => {
      history.push(`/surveys/${questionnaire.value.id}/`)
    })
  }

  onStart = () => {
    const { handleSaveOrCreate, history } = this.props

    const data = {
      active_from: moment().toISOString(true)
    }

    handleSaveOrCreate(data, this.getCompanyId()).then(questionnaire => {
      history.push(`/surveys/${questionnaire.value.id}/`)
    })
  }

  onContinue = () => {
    const { handleSaveOrCreate, history } = this.props

    const data = {
      active_until: null
    }

    handleSaveOrCreate(data, this.getCompanyId()).then(questionnaire => {
      history.push(`/surveys/${questionnaire.value.id}/`)
    })
  }

  toggleActive = () => {
    const {
      handleSaveOrCreate,
      history,
      fields: { is_active: isActive }
    } = this.props

    const data = {
      is_active: !isActive
    }

    handleSaveOrCreate(data, this.getCompanyId()).then(questionnaire => {
      history.push(`/surveys/${questionnaire.value.id}/`)
    })
  }

  sendReminder = () => {
    const { object, put } = this.props
    put(
      REDUCER_REMINDER,
      {},
      `/api/companies/${this.getCompanyId()}/questionnaires/${object}/remind/`
    )
  }

  sendNotify = () => {
    const { object, put } = this.props
    put(
      REDUCER_NOTIFY,
      {},
      `/api/companies/${this.getCompanyId()}/questionnaires/${object}/notify/`
    )
  }

  disableBody = target => disableBodyScroll(target)
  enableBody = target => enableBodyScroll(target)

  render() {
    let {
      fields: { active_from: activeFrom, active_until: activeUntil }
    } = this.props

    const {
      fields: {
        respondent_ids: respondentIds,
        title,
        status,
        status_code: statusCode,
        count_of_late_respondents: countOfLateRespondents,
        late_respondents: lateRespondents,
        is_active: isActive,
        count_of_respondents: countOfRespondents
      },
      object,
      history,
      [REDUCER_QUESTIONS]: { data: questions },
      tour: { tourOn },
      endTour,
      visitedDashboardOnce
    } = this.props

    const steps = [
      {
        selector: '.fourth-step',
        style: {
          color: '#353438'
        },
        content: () => (
          <div>
            <p>
              {t`In this view you can see information about the selected survey.`}
            </p>
            <p>
              {t`Because we want to connect you to this survey, we need to click
              EDIT SURVEY`}
            </p>
          </div>
        )
      }
    ]

    /*
    activeFrom = moment(activeFrom).isValid() ? moment(activeFrom) : null
    activeUntil = moment(activeUntil).isValid() ? moment(activeUntil) : null */

    return (
      <div>
        <FormContainer>
          <FormSection open>
            <Title>{title}</Title>
            <Description>
              {status}{' '}
              {moment(activeFrom).isValid()
                ? moment(activeFrom).format('L')
                : null}{' '}
              -{' '}
              {moment(activeUntil).isValid()
                ? moment(activeUntil).format('L')
                : null}
            </Description>
            <SeparatorLine />
          </FormSection>
          <SplittedFormSection open>
            <FormSection open>
              <Title>{t`People`}</Title>
              <p>
                {countOfRespondents} {t`people has answered`}
              </p>
              <p>
                {countOfLateRespondents} {t`people are late`}
              </p>
              <GenericConfirmation
                onSubmit={this.sendNotify}
                onFailure={() => {}}
                label={t`Notifies all the users who have not yet filled the currently going on survey or any of the previous ones. Are you sure you want to do this?`}
                ActionButton={
                  <Submit
                    type="button"
                    style={{ float: 'left', marginRight: '1rem' }}
                  >{t`Notify users`}</Submit>
                }
              />
              <GenericConfirmation
                onSubmit={this.sendReminder}
                onFailure={() => {}}
                label={t`This action reminds users who have not filled the previous surveys on time. Are you sure you want to send this reminder?`}
                ActionButton={
                  <Submit
                    type="button"
                    style={{ float: 'left' }}
                  >{t`Send reminder`}</Submit>
                }
              />
            </FormSection>
            <FormSection open>
              <UserList
                groupsUsersTitle={<Title>{t`Users in this survey`}</Title>}
                selected={respondentIds}
                late={lateRespondents}
              />
            </FormSection>
          </SplittedFormSection>
          <FormSection open>
            <Title>{t`Questions`}</Title>
            <Questions
              {...this.props}
              company={this.getCompanyId()}
              parent={object}
              data={questions}
              readOnly
            />
          </FormSection>
          <FormSection
            open
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Submit
              secondary
              type="button"
              className={'fourth-step'}
              onClick={() => {
                history.push(`/surveys/${object}/edit`)
                if (tourOn) {
                  visitedDashboardOnce()
                }
              }}
              style={{}}
            >
              {t`Edit survey`}
            </Submit>
            <GenericConfirmation
              onSubmit={this.toggleActive}
              onFailure={() => {}}
              label={
                isActive
                  ? t`Are you sure you want to deactivate this survey?`
                  : t`Are you sure you want to activate this survey?`
              }
              ActionButton={
                <Submit type="button" style={{ marginLeft: '1rem' }}>
                  {isActive ? t`Deactivate` : t`Activate`}
                </Submit>
              }
            />

            <DashboardScheduleButtonComponent
              status={statusCode}
              onStart={this.onStart}
              onEnd={this.onEnd}
              onContinue={this.onContinue}
              style={{ marginLeft: '1rem' }}
            />
          </FormSection>
        </FormContainer>
        <Reactour
          steps={steps}
          isOpen={tourOn}
          onRequestClose={endTour}
          showNumber={false}
          rounded={5}
          update={questions}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          showNavigation={false}
          showNavigationNumber={false}
        />
      </div>
    )
  }
}

const DashboardScheduleButtonComponent = ({
  status,
  onStart,
  onEnd,
  onContinue,
  style
}) => {
  let buttonTitle = null
  let modalTitle = null
  let action = null

  if (status === 'inactive') {
    return (
      <Submit style={{ ...style }} type="button" disabled>
        {t`Survey is not active`}
      </Submit>
    )
  }
  switch (status) {
    case 'ongoing':
      buttonTitle = t`End survey`
      modalTitle = t`Are you sure you want to end this survey?`
      action = onEnd
      break

    case 'expired':
      buttonTitle = t`Continue survey`
      modalTitle = t`Are you sure you want to continue this survey? Data may be incomplete!`
      action = onContinue
      break

    default:
    case 'upcoming':
      buttonTitle = t`Start survey`
      modalTitle = t`Are you sure you want to start this survey?`
      action = onStart
      break
  }

  return (
    <GenericConfirmation
      onSubmit={action}
      onFailure={() => {}}
      label={modalTitle}
      ActionButton={
        <Submit style={{ ...style }} type="button" onClick={action}>
          {buttonTitle}
        </Submit>
      }
    />
  )
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions, endTour, visitedDashboardOnce }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericFormHOC(Dashboard, DEFAULT_FIELDS, REDUCER_QUESTIONNAIRES, null))
