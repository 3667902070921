// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { t } from 'ttag'

import { bindActionCreators } from 'redux'
import { login, registerOrganization } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'

const Container = styled.div`
  display: flex;
  margin-right: 15rem;
`

const Wrapper = styled.div`
  flex: 0 1 50%;
  margin: 0 auto;
  background-color: #39383c;
  border-radius: 25px;
  padding: 50px;
  text-align: center;
`

class Confirmation extends Component {
  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0].message) {
      toast.error(error.messages.non_field_errors[0].message)
      resetErrorAction()
    }
  }

  render() {
    const {
      auth: { isPending, error, loggedIn }
    } = this.props

    if (loggedIn)
      return (
        <Redirect
          to={{
            pathname: '/home'
          }}
        />
      )

    return (
      <Container>
        <Wrapper>
          <h1>{t`Verify your Priocta account`}</h1>
          <h4 style={{ color: '#CEA766', marginTop: '0.5rem' }}>
            {t`We are ready to start your free trial in Priocta!`}
          </h4>
          <h4>
            {t`All we need to do is make sure this is your email address. Please
            check your email to confirm your account.`}
          </h4>
          <h4>
            {t`If the verification email does not appear in a
            couple of minutes, please, check also your junk email folder.`}
          </h4>
        </Wrapper>
      </Container>
    )
  }
}

const mapStateToProps = ({ auth, error }) => ({
  auth,
  error
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      registerOrganization,
      resetErrorMessage,
      login
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation)
