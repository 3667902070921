// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import Charts from 'components/Charts'
import { REDUCER_QUESTIONNAIRES } from 'store/reducers'
import Button from 'components/Button/Button'
import { t } from 'ttag'
import { toast } from 'react-toastify'
import Reactour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { endTour } from 'store/actions/tour'

// require('moment/locale/fi')

const Title = styled.div`
  font-size: ${(props) => (props.secondary ? '1rem' : '1.3rem')};
  padding: 1rem 0rem 1rem 0rem;
  font-weight: bold;
  text-transform: ${(props) => (props.secondary ? 'uppercase' : 'none')};
  color: ${(props) => (props.secondary ? '#009f86' : 'inherit')};
  cursor: ${(props) => (props.secondary ? 'pointer' : 'normal')};
`

class DashboardStats extends Component {
  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  disableBody = (target) => disableBodyScroll(target)
  enableBody = (target) => enableBodyScroll(target)

  render() {
    const {
      object,
      match: { params },
      [REDUCER_QUESTIONNAIRES]: { data },
      history,
      tour: { tourOn },
      endTour,
      edit
    } = this.props

    const questionnaireId = object ? object : parseInt(params.id)

    const questionnaire = data.find((object) => object.id === questionnaireId)
    let title = ''
    let activeFrom = null
    if (questionnaire) {
      title = `${questionnaire.title} -`
      activeFrom = questionnaire.active_from
    }

    let respondents = []
    let groups = []
    respondents[t`All groups`] = []
    if (questionnaire && questionnaire.respondent_ids) {
      questionnaire.respondent_ids.map((respondent) => {
        if ('company_groups' in respondent) {
          respondent.company_groups.map((group) => {
            groups[group.id] = group.name
            if (group.id in respondents) {
              const users = respondents[group.id]
              users.push({
                id: respondent.id,
                name: respondent.full_name
              })
              respondents[group.id] = users
              respondents[group.name] = users
            } else {
              const users = []
              users.push({
                id: respondent.id,
                name: respondent.full_name
              })

              respondents[group.id] = users
            }
          })
        }

        const users = respondents[t`All groups`]
        users.push({
          id: respondent.id,
          name: respondent.full_name
        })
        respondents[t`All groups`] = users
      })
    }

    let isAnonymous = true
    if (questionnaire) {
      isAnonymous = questionnaire.anonymous
    }

    const steps = [
      {
        selector: '.dashboard',
        style: {
          color: '#353438'
        },
        content: () => (
          <div>
            <p>
              {t`From Help you can find more tips about the features of Priocta.
              For example, how to add a new user, or create a new group or
              survey.`}
            </p>
          </div>
        )
      },
      {
        selector: '.dashboard'
      }
    ]
    return (
      <div className="dashboard">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Title>
            {title}
            {t`Statistics`}
          </Title>
        </div>
        <Charts
          companyId={this.getCompanyId()}
          questionnaire={questionnaire}
          questionnaireId={questionnaireId}
          isShare={false}
          respondents={respondents}
          groups={groups}
          isAnonymous={isAnonymous}
          activeFrom={activeFrom}
          edit={edit}
        />
        <Reactour
          steps={steps}
          isOpen={tourOn}
          onRequestClose={endTour}
          showNumber={false}
          rounded={5}
          nextStep={() => history.push(`/subscriptions`)}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          showNavigation={false}
          showNavigationNumber={false}
        />
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions, endTour }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStats)
