import styled from 'styled-components/macro'

const Label = styled.label`
  height: 14px;
  color: #eaeaee;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`

export default Label
