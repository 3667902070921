// @flow

import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  useHistory
} from 'react-router-dom'
import { Provider } from 'react-redux'
import { initStore } from 'store/store'
import PrivateRoute from 'auth/PrivateRoute'
import { StripeProvider } from 'react-stripe-elements'

import {
  Dashboard,
  Home,
  Login,
  PersonelList,
  UserForm,
  Companies,
  CompaniesForm,
  Groups,
  GroupsForm,
  Questionnaires,
  QuestionnaireForm,
  Subscription,
  Plans,
  Signup,
  ForgotPassword,
  UserImport,
  ChangePassword,
  Confirmation
} from 'pages'

import BaseContainer from 'components/layouts/BaseContainer'
import 'normalize.css'
import 'App.scss'
import DashboardStats from './pages/DashboardStats'
import SharedDashboardStats from './pages/SharedDashboardStats'
import WithAnalytics from './withAnalytics'
import styled from 'styled-components/macro'

const imgStyle = styled.img`
  margin: 2rem;
`
const styledLink = {
  margin: '2rem',
  img: {
    height: '30px'
  }
}

const { REACT_APP_STRIPE_API_KEY } = process.env

class App extends Component {
  render() {
    return (
      <Provider store={initStore()}>
        <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
          <Router>
            <WithAnalytics trackingId="UA-140615523-1">
              <BaseContainer>
                <Switch>
                  <Route component={Login} path="/login" />
                  <Route component={Signup} path="/signup" />
                  <Route component={Confirmation} path="/confirmation" />
                  <Route
                    component={SharedDashboardStats}
                    path="/share/:uuid/"
                  />

                  <Route
                    component={ForgotPassword}
                    path={['/forgotpassword', '/reset/:uid/:token/']}
                  />
                  <PrivateRoute component={UserForm} path="/personnel/new" />
                  <PrivateRoute component={UserForm} path="/personnel/:id" />
                  <PrivateRoute component={PersonelList} path="/personnel" />
                  <PrivateRoute component={UserImport} path="/user-import" />

                  <PrivateRoute component={Companies} path="/companies" />
                  <PrivateRoute
                    component={CompaniesForm}
                    path="/companyform/:id"
                  />
                  <PrivateRoute component={CompaniesForm} path="/companyform" />
                  <PrivateRoute component={GroupsForm} path="/groups/new" />
                  <PrivateRoute component={GroupsForm} path="/groups/:id" />
                  <PrivateRoute component={Groups} path="/groups" />
                  <PrivateRoute
                    component={QuestionnaireForm}
                    path="/surveys/new"
                  />
                  <PrivateRoute
                    component={QuestionnaireForm}
                    path="/surveys/:id/edit"
                  />
                  <PrivateRoute
                    component={DashboardStats}
                    path="/surveys/:id/dashboard"
                  />
                  <PrivateRoute component={Dashboard} path="/surveys/:id" />
                  <PrivateRoute component={Questionnaires} path="/surveys" />
                  <PrivateRoute
                    component={Subscription}
                    path="/subscriptions"
                  />
                  <PrivateRoute
                    component={ChangePassword}
                    path="/change-password"
                  />
                  <PrivateRoute component={Plans} path="/plans" />
                  <PrivateRoute component={Home} path="/" />
                </Switch>
              </BaseContainer>
            </WithAnalytics>
          </Router>
        </StripeProvider>
      </Provider>
    )
  }
}

export default App
