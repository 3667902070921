export const CHANGE_SELECTED_COMPANY = 'CHANGE_SELECTED_COMPANY'
export const DATA_IS_STALE = 'DATA_IS_STALE'

// Resets the currently visible error message.
export const changeSelectedCompany = company => ({
  type: CHANGE_SELECTED_COMPANY,
  payload: company
})

export const sessionDataIsStale = company => ({
  type: DATA_IS_STALE
})
