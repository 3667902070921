import React, { Component } from 'react'
import styled from 'styled-components/macro'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { userRead, logout } from 'store/actions/auth'
import { t } from 'ttag'
import * as actions from 'store/actions/CRUD'

import LinkElement from './LinkElement'
import ExternalLinkElement from './ExternalLinkElement'
import { REDUCER_SUBSCRIPTION } from '../../store/reducers'
import { withRouter } from 'react-router-dom'
import { startTour } from 'store/actions/tour'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
`
let Links = [
  {
    name: 'personnel',
    icon: 'fa-user',
    to: '/personnel',
    title: t`Personnel`,
    type: 'internal'
  },
  {
    name: 'groups',
    icon: 'fa-users',
    to: '/groups',
    title: t`Groups`,
    type: 'internal'
  },
  {
    name: 'surveys',
    icon: 'fa-question-circle',
    to: '/surveys',
    title: t`Surveys`,
    type: 'internal'
  },
  {
    name: 'subscriptions',
    icon: 'fa-credit-card',
    to: '/subscriptions',
    title: t`Subscriptions`,
    type: 'internal',
    className: 'subscriptions-link'
  },
  {
    type: 'spacer'
  },
  {
    type: 'external',
    href: 'https://priocta.com/resources/help',
    target: '_blank',
    icon: 'fa-question-circle',
    title: t`Help`,
    className: 'help-link'
  },
  {
    name: 'password',
    type: 'internal',
    icon: 'fal fa-lock-open-alt',

    to: '/change-password',
    title: t`Change password`
  }
]

class Navbar extends Component {
  componentDidMount() {
    const {
      auth: { loggedIn },
      history,
      startTour: startTourAction
    } = this.props

    if (loggedIn) {
      this.getUserInfo()

      Links.splice(6, 0, {
        name: 'wizard',
        type: 'internal',
        icon: 'fal fa-info-square',

        to: '/start',
        title: t`Start a tour`,
        onClick: () => {
          startTourAction()
        }
      })
    }
  }

  componentDidUpdate(prevprops) {
    const {
      auth: { loggedIn }
    } = this.props

    const {
      auth: { loggedIn: prevLoggedIn }
    } = prevprops

    if (prevLoggedIn !== loggedIn && prevLoggedIn === false) {
      this.getUserInfo()
    }
  }

  handleLogout = () => {
    const { logout: logoutAction } = this.props

    logoutAction()
  }

  getUserInfo = () => {
    const { userRead: userReadAction } = this.props

    userReadAction()
  }

  render() {
    const {
      session: { sideMenuActive },
      [REDUCER_SUBSCRIPTION]: { data },
      history,
      startTour: startTourAction
    } = this.props

    const hasActiveSubscriptions = data.length > 0
    const isDisabled = !sideMenuActive || !hasActiveSubscriptions

    return (
      <Container>
        {Links.map(
          ({ type, icon, to, title, href, target, className, onClick }) => {
            if (type === 'external') {
              return (
                <ExternalLinkElement
                  key={`key-${to}`}
                  icon={icon}
                  disabled={isDisabled}
                  to={to}
                  title={title}
                  href={href}
                  target={target}
                  className={className}
                />
              )
            } else if (type === 'spacer') {
              return <hr key={`key-spacer`} style={{ width: '90%' }} />
            } else if (type === 'internal') {
              return (
                <LinkElement
                  key={`key-${to}`}
                  icon={icon}
                  disabled={isDisabled}
                  to={to}
                  title={title}
                  className={className}
                  onClick={onClick}
                />
              )
            }
          }
        )}
        {/*   <div style={{ marginTop: 'auto' }}> */}
        <LinkElement
          icon="fa-sign-out-alt"
          title={t`Log out`}
          onClick={this.handleLogout}
          to="/logout"
        />
        {/*   </div> */}
      </Container>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions, startTour, userRead, logout }, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Navbar)
)
