import React, { Component } from 'react'
import { t } from 'ttag'

import Label from 'components/FormElements/Inputs//Label/Label'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import Submit from 'components/FormElements/Inputs/Submit/Submit'
import styled from 'styled-components/macro'
import {
  arrayMove,
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc'

const Container = styled.div`
  cursor: pointer;
  height: 32px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #212121;
  align-items: center;
  font-size: 0.75rem;
`

const Icon = styled.i`
  width: 32px;
  height: auto;
  padding: 5px;
  margin-left: ${props => (props.alignRight ? 'auto' : 'none')};
`

const SingleChoice = props => {
  const { data, onRemove } = props

  return (
    <Container
      onDoubleClick={() => {
        onRemove(data)
      }}
    >
      {data}
      <Icon
        onClick={() => {
          onRemove(data)
        }}
        className="fa fa-times"
        alignRight
      />
    </Container>
  )
}

const SortableItem = SortableElement(SingleChoice)
const SortableList = SortableContainer(props => (
  <div>
    {props.data.map((item, index) => {
      return (
        <SortableItem
          {...props}
          index={index}
          key={`item-${index}`}
          data={item}
        />
      )
    })}
  </div>
))

export default class ChoicesList extends Component {
  state = {
    value: '',
    items: []
  }

  componentDidMount() {
    const { values } = this.props
    this.setState({ items: values })
  }

  componentDidUpdate({ values: prevValues }) {
    const { values } = this.props
    if (prevValues !== values) {
      this.setState({ items: values })
    }
  }

  handleRemoveChoice = value => {
    const { items } = this.state
    const { onChange } = this.props
    onChange(items.filter(item => item !== value))
  }

  handleAddChoice = e => {
    e.preventDefault()
    e.stopPropagation()
    const { value, items } = this.state
    const { onChange } = this.props

    items.push(value)
    onChange(items)
    this.setState({ value: '' })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state
    const { onChange } = this.props

    const newOrder = arrayMove(items, oldIndex, newIndex)

    if (!isNaN(newIndex)) {
      this.setState({
        items: newOrder
      })
    }
    onChange(newOrder)
  }

  render() {
    const { items, value } = this.state

    let choices = <div style={{ fontSize: '0.75rem' }}>{t`No choices.`}</div>

    if (items.length > 0) {
      choices = (
        <SortableList
          data={items}
          onRemove={this.handleRemoveChoice}
          onSortEnd={this.onSortEnd}
          pressDelay={300}
        />
      )
    }
    return (
      <div>
        <Label>{t`Choices`}</Label>
        {choices}
        <div style={{ display: 'flex', marginTop: '1rem' }}>
          <InputWithLabel
            group="question"
            onChange={e => this.setState({ value: e.target.value })}
            value={value}
          />
          <Submit
            style={{ flex: 1, maxWidth: '8rem', marginLeft: '1rem' }}
            type="button"
            onClick={this.handleAddChoice}
          >{t`Add`}</Submit>
        </div>
      </div>
    )
  }
}
