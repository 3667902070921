// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import * as actions from 'store/actions/CRUD'
import {
  REDUCER_USERS,
  REDUCER_GROUPS,
  REDUCER_QUESTIONNAIRES
} from 'store/reducers'
import GenericFormHOC from 'components/FormElements/GenericFormHOC'
import SelectWithLabelMultiple from '../components/FormElements/Inputs/SelectWithLabel/SelectWithLabel'
import Label from '../components/FormElements/Inputs/Label/Label'
import Toggle from '../components/Toggle/Toggle'
import DeleteConfirmation from '../components/DeleteConfirmation'
import RowMenu from '../components/RowMenu'
import Submit from '../components/FormElements/Inputs/Submit/Submit'
import { toast } from 'react-toastify'
import { resetErrorMessage } from 'store/actions/error'

const Form = styled.form`
  width: 100%;
`

const FormContainer = styled.table`
  width: 100%;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 1rem;
  }

  & > button:last-child {
    margin-right: 0;
  }
`

const DEFAULT_FIELDS = {
  first_name: '',
  last_name: '',
  email: '',
  company_groups: [],
  user_type: false
}

class UserForm extends Component {
  componentDidMount() {
    this.props.populateDataByParamId()
  }

  componentDidUpdate() {
    const { error, resetErrorMessage: resetErrorAction } = this.props

    if (error && error.messages.non_field_errors[0]) {
      toast.error(error.messages.non_field_errors[0]) // Display error messages
      resetErrorAction()
    }
  }

  handleAddToGroup = e => {
    const {
      fields: { company_groups },
      [REDUCER_GROUPS]: { data },
      handleSetFieldValue
    } = this.props

    const listObject = data.find(
      item => parseInt(item.id) === parseInt(e.target.value)
    )

    const groupList = company_groups.slice()
    const existing = company_groups.find(
      item => parseInt(item.id) === parseInt(e.target.value)
    )
    if (!existing) groupList.push(listObject)
    handleSetFieldValue('company_groups', groupList)
  }

  handleRemoveFromGroup = e => {
    const {
      fields: { company_groups },
      handleSetFieldValue
    } = this.props
    const groupList = company_groups.filter(
      item => parseInt(item.id) !== parseInt(e.target.value)
    )
    handleSetFieldValue('company_groups', groupList)
  }

  handleSubmit = e => {
    e.preventDefault()
    const {
      fields,
      handleSaveOrCreate,
      isStale,
      session: { company }
    } = this.props

    // Convert company groups list to ID's only
    fields.company_groups = fields.company_groups.map(item => item.id)

    // Convert boolean to null / company_admin_group
    // TODO Move company admin group name to some global variable
    fields.user_type =
      fields.user_type === false || fields.user_type === null
        ? null
        : 'company_admin_group'
    handleSaveOrCreate(fields, company ? company.id : null).then(() => {
      isStale(REDUCER_GROUPS)
      isStale(REDUCER_USERS)
      isStale(REDUCER_QUESTIONNAIRES)
    })
  }

  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  onDelete = () => {
    const { isStale, history } = this.props
    isStale(REDUCER_USERS)
    isStale(REDUCER_GROUPS)
    history.push(`/personnel`)
  }

  onCancel = () => {
    const { isStale, history } = this.props
    isStale(REDUCER_USERS)
    isStale(REDUCER_GROUPS)
    history.push(`/personnel`)
  }

  render() {
    const {
      fields,
      errors,
      handleChange,
      handleToggle,
      isStale,
      [REDUCER_GROUPS]: { data },
      object
    } = this.props

    // Available company groups
    const availableGroups = data.map(group => (
      <option value={group.id}>{group.name}</option>
    ))

    // Groups in which user belongs
    const userGroups = fields.company_groups.map(group => (
      <option value={group.id}>{group.name}</option>
    ))

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <FormContainer>
            <tr>
              <td>
                <InputWithLabel
                  group="addcustomer"
                  label={t`First name`}
                  name="first_name"
                  onChange={handleChange}
                  value={fields.first_name}
                  error={errors.first_name}
                />
              </td>
              <td>
                <Label>{t`Groups`}</Label>
                <SelectWithLabelMultiple
                  name="available_groups"
                  onChange={this.handleAddToGroup}
                >
                  <option>{t`Select group from the list`}</option>
                  {availableGroups}
                </SelectWithLabelMultiple>
              </td>
            </tr>
            <tr>
              <td>
                <InputWithLabel
                  group="addcustomer"
                  label={t`Last name`}
                  name="last_name"
                  onChange={handleChange}
                  value={fields.last_name}
                  error={errors.last_name}
                />
              </td>
              <td rowSpan="2" style={{ verticalAlign: 'top' }}>
                <Label>{t`User groups`}</Label>
                <SelectWithLabelMultiple
                  name="user_groups"
                  multiple
                  onDoubleClick={this.handleRemoveFromGroup}
                >
                  {userGroups}
                </SelectWithLabelMultiple>
              </td>
            </tr>
            <tr>
              <td>
                <InputWithLabel
                  group="addcustomer"
                  label={t`Email`}
                  name="email"
                  onChange={handleChange}
                  value={fields.email}
                  error={errors.email}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Label>{t`Is admin user?`}</Label>
                <Toggle
                  type="checkbox"
                  name="user_type"
                  onToggle={handleToggle}
                  checked={fields.user_type}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                {object && (
                  <DeleteConfirmation
                    reducer={REDUCER_USERS}
                    query={`/api/companies/${this.getCompanyId()}/users/${object}/`}
                    onSuccess={this.onDelete}
                    onFailure={() => {}}
                    ActionButton={
                      <Submit
                        type="button"
                        style={{ float: 'left' }}
                        destructive
                      >{t`Delete`}</Submit>
                    }
                  />
                )}
                <ButtonContainer>
                  <Submit onClick={this.onCancel} secondary type="button">
                    {t`Cancel`}
                  </Submit>
                  <Submit type="submit">{t`Save`}</Submit>
                </ButtonContainer>
              </td>
            </tr>
          </FormContainer>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions, resetErrorMessage }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericFormHOC(UserForm, DEFAULT_FIELDS, REDUCER_USERS, '/personnel'))
