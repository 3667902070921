// @flow
import React, { Component } from 'react'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import {
  ListBodyCell,
  ListCompanyTitle,
  ListContainer,
  ListHeadingCell,
  ListRow
} from 'components/Lists/StyledLists'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import ListComponent from 'components/Lists/ListComponent'
import { GreenButtonLink } from 'components/Button/GreenButtonLink'
import {
  REDUCER_COMPANIES,
  REDUCER_QUESTIONNAIRES,
  REDUCER_GROUPS,
  REDUCER_USERS,
  REDUCER_PLANS,
  REDUCER_SUBSCRIPTION
} from 'store/reducers'
import { changeSelectedCompany } from '../store/actions/session'

const TABLE_HEADINGS = [
  {
    title: t`Name`,
    colSpan: 2
  }
]

const CompanyLink = styled.div`
  cursor: pointer;
`

export default class CompaniesLinkables extends Component {
  state = {}

  onChange = id => {
    const companyId = parseInt(id) > 0 ? parseInt(id) : null
    this.updateCompany(companyId)
  }

  updateCompany = companyId => {
    const {
      auth: {
        user: { visible_companies }
      }
    } = this.props
    // Pass whole company object with name to reducer
    const company =
      visible_companies.find(item => item.id === companyId) || null
    this.props.changeSelectedCompany(company)
    this.props.isStale(REDUCER_COMPANIES)
    this.props.isStale(REDUCER_GROUPS)
    this.props.isStale(REDUCER_USERS)
    this.props.isStale(REDUCER_QUESTIONNAIRES)
    this.props.isStale(REDUCER_PLANS)
    this.props.isStale(REDUCER_SUBSCRIPTION)
  }

  render() {
    const {
      session: { company },
      auth: {
        user: { visible_companies, user_type }
      }
    } = this.props

    let list =
      visible_companies &&
      visible_companies.map(item => {
        return {
          key: item.id,
          items: [
            item.name,
            <div>
              <GreenButtonLink
                onClick={this.onChange.bind(this, item.id)}
                style={{ marginRight: '1rem' }}
              >{t`SELECT COMPANY`}</GreenButtonLink>
              <GreenButtonLink
                to={`/companyform/${item.id}`}
              >{t`EDIT`}</GreenButtonLink>
            </div>
          ]
        }
      })

    return (
      <ListComponent
        title={t`Companies`}
        headers={TABLE_HEADINGS}
        data={list}
        company={company}
        linkToForm="/companyform"
      />
    )
  }
}
