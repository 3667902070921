import React, { Component } from 'react'
import {
  REDUCER_PLANS,
  REDUCER_QUESTIONNAIRES,
  REDUCER_SUBSCRIPTION,
  REDUCER_USERS
} from 'store/reducers'
import Submit from 'components//FormElements/Inputs/Submit/Submit'
import { t } from 'ttag'
import { injectStripe } from 'react-stripe-elements'
import CheckoutForm from 'components//CheckoutForm'
import styled, { keyframes } from 'styled-components/macro'
import { toast } from 'react-toastify'
import { trackEvent } from '../withAnalytics'

const Product = styled.div`
  background: linear-gradient(180deg, #39383c 0%, #2f2e32 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  overflow: overlay;
  flex: 1;
  box-sizing: border-box;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;

  box-shadow: ${(props) =>
    props.isSubscribed ? '0px 0px 0px 10px #CEA766' : 'none'};
  max-width: ${(props) => (props.isSelected ? '35%' : '27%')};
  max-width: ${(props) => props.isSelected === null && '30%'};
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  & > .StripeCheckout > span {
  }
`

const ProductName = styled.div`
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`

const ProductContent = styled.div`
  padding: 1rem;
`

const FeatureIcon = styled.div`
  display: inline-block;
  align-self: flex-start;
  background-color: #009f86;
  width: 0.75rem;
  height: 0.75rem;
  font-weight: bold;
  margin-right: 1rem;
  text-align: center;
  border-radius: 100%;
  font-size: 0.75rem;
`

const ProductContentRow = styled.div`
  margin-top: 4px;
`

const ProductCheckoutButton = styled.div`
  margin-top: 1rem;

  & > button {
    overflow: visible !important;
    display: inline-block !important;
    background: none !important;
    border: none !important;
    text-decoration: none !important;
    border-radius: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    visibility: visible !important;
    user-select: none !important;
    padding: 0 1rem !important;
    line-height: 36px !important;
    display: inline-block !important;
    text-decoration: none !important;
    font-size: 0.8rem !important;
    color: #fff !important;
    text-transform: uppercase !important;
    text-align: center !important;
    background: ${(props) => (props.disabled ? 'gray' : '#009f86')} !important;
    border: 0 !important;
    border-radius: 5px !important;
    background: ${(props) =>
      props.disabled ? 'normal' : 'pointer'} !important;
    outline: 0 !important;
    box-shadow: 0px 5px 5px 0px rgba(39, 39, 39, 0.75) !important;
    height: 36px;
    width: 100%;
    display: ${(props) =>
      props.isSelected ? 'none' : 'inline-block'} !important;
  }

  & > button span {
    background-image: none !important;
    background: none !important;
    font-family: inherit !important;
    font-size: initial !important;
    position: initial !important;
    padding: initial !important;
    display: initial !important;
    height: initial !important;
    line-height: initial !important;
    color: white !important;
    font-weight: initial !important;
    box-shadow: initial !important;
    text-shadow: initial !important;
    border-radius: initial !important;
  }

  & > button:hover {
    background: ${(props) => (props.disabled ? 'gray' : '#00b498')} !important;
    transition: ${(props) =>
      props.disabled ? 'none' : 'background 200ms'} !important;
  }
`

const ProductIsNotSuitable = styled.div`
  padding-top: 1rem;
  font-size: 0.75rem;
`

const SelectedSubscription = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 0.5rem;
  color: #00b498;
`

const ProductPrice = styled.div`
  font-size: 1.6rem;
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 8rem;

  & span:first-child {
    color: #009f86;
  }

  & div {
    font-size: 1rem;
    color: #cea766;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate} 2s linear infinite;
  display: inline-block;
`

const UnitPricing = styled.div`
  & span:first-child {
    color: #ffffff;
    padding-right: 10px;
  }
  & span:nth-child(2) {
    color: #009f86;
  }
  & span:nth-child(3) {
    color: #ffffff;
  }
`

const SubscriptionStatuses = Object.freeze({
  ACTIVE: 'active',
  INCOMPLETE: 'incomplete',
  TRIALING: 'trialing',
  PAST_DUE: 'past_due'
})

const PaymentIntentStatuses = Object.freeze({
  SUCCEEDED: 'succeeded',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
  REQUIRES_ACTION: 'requires_action'
})

const SetupIntentStatuses = Object.freeze({
  REQUIRES_ACTION: 'requires_action'
})

class SinglePlan extends Component {
  state = {
    isStripePending: false
  }

  onPlanSelect = (isTrial) => {
    const { onPlanSelect, plan } = this.props
    onPlanSelect(plan)
    if (isTrial) {
      this.setState({ isStripePending: true })
      this.handleSubscription()
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault()
    const { stripe } = this.props
    this.setState({ isStripePending: true })
    stripe.createToken().then((result) => {
      if (result.error) {
        toast.error(result.error.message)
        this.setState({ isStripePending: false })
      } else {
        this.handleSubscription(result.token)
      }
    })
  }

  handleSubscription = (token) => {
    const {
      session: { company },
      plan,
      put,
      create
    } = this.props
    const currentSubscription = this.props[REDUCER_SUBSCRIPTION].data
    const currentSubscriptionId = currentSubscription[0]
      ? currentSubscription[0].subscription_id
      : null

    let payload = {
      plan_id: plan.id
    }
    if (token) {
      payload = {
        stripe_token: token.id,
        plan_id: plan.id
      }
    }

    if (currentSubscriptionId) {
      put(
        REDUCER_SUBSCRIPTION,
        payload,
        `/api/stripe/companies/${company.id}/subscriptions/${currentSubscriptionId}/`
      ).then((response) => {
        this.handleSubscriptionResult(response)
      })
    } else {
      create(
        REDUCER_SUBSCRIPTION,
        payload,
        null,
        null,
        `/api/stripe/companies/${company.id}/subscriptions/`
      ).then((response) => {
        this.handleSubscriptionResult(response)
      })
    }
  }

  handleSubscriptionResult = (response) => {
    if (response && response.value && response.value.subscription) {
      const { subscription } = response.value
      if (subscription.status === SubscriptionStatuses.INCOMPLETE) {
        const paymentIntent = subscription.latest_invoice.payment_intent
        if (paymentIntent.status === PaymentIntentStatuses.REQUIRES_ACTION) {
          this.handleCardPayment(paymentIntent)
        } else {
          this.indicateError(t`An error occurred when trying to subscribe.`)
        }
      } else if (subscription.status === SubscriptionStatuses.ACTIVE) {
        this.indicateSuccess(t`Successfully subscribed!`)
        this.successEvent(subscription)
      } else if (subscription.status === SubscriptionStatuses.TRIALING) {
        const pendingSetupIntent = subscription.pending_setup_intent
        if (pendingSetupIntent) {
          if (
            pendingSetupIntent.status === SetupIntentStatuses.REQUIRES_ACTION
          ) {
            this.handleCardSetup(pendingSetupIntent)
          }
        } else {
          this.indicateSuccess(t`Successfully subscribed!`)
          this.successEvent(subscription)
        }
      } else if (subscription.status === SubscriptionStatuses.PAST_DUE) {
        const paymentIntent = subscription.latest_invoice.payment_intent
        if (paymentIntent.status === PaymentIntentStatuses.REQUIRES_ACTION) {
          this.handleCardPayment(paymentIntent)
        } else if (
          paymentIntent.status === PaymentIntentStatuses.REQUIRES_PAYMENT_METHOD
        ) {
          this.handleCardPayment(paymentIntent)
        } else {
          this.indicateError(t`An error occurred when trying to subscribe.`)
        }
      }
    } else {
      this.indicateError(t`An error occurred when trying to subscribe.`)
    }
  }

  handleCardPayment = (paymentIntent) => {
    const { stripe } = this.props
    const self = this
    stripe
      .handleCardPayment(paymentIntent.client_secret)
      .then(function (result) {
        if (result.error) {
          self.indicateError(result.error.message)
        } else {
          self.indicateSuccess(t`Successfully subscribed!`)
        }
      })
  }

  handleCardSetup = (setupIntent) => {
    const { stripe } = this.props
    const self = this
    stripe.handleCardSetup(setupIntent.client_secret).then(function (result) {
      if (result.error) {
        self.indicateError(result.error.message)
      } else {
        self.indicateSuccess(t`Successfully subscribed!`)
      }
    })
  }

  successEvent = (subscription) => {
    const { entry_url } = this.props.auth.user
    trackEvent(
      'Subscription',
      subscription.plan.nickname,
      entry_url,
      Number(subscription.plan.amount)
    )
  }

  indicateSuccess = (successMessage) => {
    const { isStale, history, onSubscriptionUpdate } = this.props
    isStale(REDUCER_SUBSCRIPTION)
    isStale(REDUCER_PLANS)
    isStale(REDUCER_PLANS)
    this.setState({ isStripePending: false })
    onSubscriptionUpdate(true)
    toast.success(successMessage)
  }

  indicateError = (errorMessage) => {
    const { isStale, history } = this.props
    isStale(REDUCER_SUBSCRIPTION)
    isStale(REDUCER_PLANS)
    this.setState({ isStripePending: false })
    toast.error(errorMessage)
  }

  render() {
    const {
      name,
      max_active_questionnaires,
      max_admin_users,
      max_users,
      selectedPlan,
      additional_features,
      plan,
      currentSubscription,
      clientSecret,
      showOldPlans
    } = this.props
    const { isStripePending } = this.state

    const activeSubscription = currentSubscription[0]
    let isSubscribedPlan = false
    if (activeSubscription) {
      isSubscribedPlan = plan.plan_id === activeSubscription.plan.plan_id
    }

    // Validate questionnaire count in allowed limits
    const companyQuestionnaireCount =
      this.props[REDUCER_QUESTIONNAIRES].data.length
    const allowedMaxQuestionnaireCount = max_active_questionnaires
    const questionairesWithinLimits =
      allowedMaxQuestionnaireCount === null
        ? true
        : allowedMaxQuestionnaireCount >= companyQuestionnaireCount

    // Validate max user count
    const companyUserCount = this.props[REDUCER_USERS].data.length
    const allowedMaxUserCount = max_users
    const usersWithinLimits =
      allowedMaxUserCount === null
        ? true
        : allowedMaxUserCount >= companyUserCount

    // Validate max ADMIN user count
    const companyAdminUserCount = this.props[REDUCER_USERS].data.filter(
      (user) => user.user_type === 'company_admin_group'
    ).length
    const allowedMaxAdminUserCount = max_admin_users
    const adminUsersWithinLimits =
      allowedMaxAdminUserCount === null
        ? true
        : allowedMaxAdminUserCount >= companyAdminUserCount

    const someValueIsTooHighToDowngrade =
      !questionairesWithinLimits ||
      !usersWithinLimits ||
      !adminUsersWithinLimits

    const isDisabled = someValueIsTooHighToDowngrade

    const infinityIcon = <span>&#8734;</span>

    const hasTrialPeriodDays = !activeSubscription && !!plan.trial_period_days

    const isPending =
      this.props[REDUCER_SUBSCRIPTION].isPending ||
      this.props[REDUCER_PLANS].isPending

    let subscribeButton = (
      <Submit
        disabled={isDisabled}
        onClick={() => {
          this.onPlanSelect(true)
        }}
      >
        {isPending ? (
          <Spinner>
            <i className="fas fa-spinner" />
          </Spinner>
        ) : (
          t`Start your trial`
        )}
      </Submit>
    )
    if (!hasTrialPeriodDays) {
      subscribeButton = (
        <Submit
          disabled={isDisabled}
          onClick={() => {
            this.onPlanSelect(false)
          }}
        >
          {t`Pay with card`}
        </Submit>
      )
    }
    const isSelectedPlan =
      selectedPlan === null ? selectedPlan : selectedPlan.id === plan.id

    const currencySymbol = plan.currency === 'usd' ? '$' : '€'
    const maxActiveSurveysText =
      max_active_questionnaires > 1 || !max_active_questionnaires
        ? t`Active surveys`
        : t`Active survey`
    const maxAdminUsersText =
      max_admin_users > 1 || !max_admin_users ? t`Admin users` : t`Admin user`
    const maxUsersText =
      max_users > 1 || !max_users ? t`Total users` : t`Total user`

    let amount = plan.amount
    if (amount === null && plan.tiers?.length > 0) {
      amount = plan.tiers[0].flat_amount * 0.01
    }

    let interval = plan.interval

    if (plan.interval === 'month') {
      interval = t`month`
    } else if (plan.interval === 'year') {
      interval = t`year`
    }

    // Metered pricing
    const metered = plan.usage_type === 'metered'

    let userPricingList = null

    let pricing = (
      <>
        <span>
          {parseInt(amount)} {currencySymbol}
        </span>
        <span> / {plan.interval}</span>
      </>
    )

    if (metered && plan.tiers?.length > 0) {
      if (showOldPlans) return null
      // If unit pricing is the same for all intervals, generate one element for all
      if (
        plan.tiers.filter(
          (obj) => obj.unit_amount === plan.tiers[0].unit_amount
        ).length === plan.tiers.length
      ) {
        if (plan.tiers[0].flat_amount) {
          userPricingList = (
            <UnitPricing>
              <span>+</span>
              <span>
                {plan.tiers[0].unit_amount * 0.01} {currencySymbol}
              </span>
              <span>
                {' '}
                per {t`user`} / {t`month`}
              </span>
            </UnitPricing>
          )
        } else {
          pricing = (
            <>
              <span>
                {plan.tiers[0].unit_amount * 0.01} {currencySymbol}
              </span>
              <span>
                {' '}
                per {t`user`} / {t`month`}
              </span>
            </>
          )
        }
      } else {
        userPricingList = plan.tiers?.map((tier, idx) => {
          if (!plan.tiers[0].flat_amount && idx === 0) {
            pricing = (
              <>
                <span>
                  {plan.tiers[0].unit_amount * 0.01} {currencySymbol}
                </span>
                <span>
                  {' '}
                  per {t`user`} / {t`month`}
                </span>
              </>
            )
          } else {
            return (
              <UnitPricing>
                <span>+</span>
                <span>
                  {tier.unit_amount * 0.01} {currencySymbol}
                </span>
                <span>
                  {' '}
                  per {t`user`} / {t`month`}{' '}
                  {`upto ${
                    tier.up_to !== null ? tier.up_to.toString() : '∞'
                  } users`}
                </span>
              </UnitPricing>
            )
          }
        })
      }
    } else if (metered) {
      if (showOldPlans) return null
      pricing = (
        <>
          <span>
            {amount} {currencySymbol}
          </span>
          <span>
            {' '}
            per {t`user`} / {t`month`}
          </span>
        </>
      )
    } else if (showOldPlans) {
      pricing = (
        <>
          <span>
            {parseInt(amount)} {currencySymbol}
          </span>
          <span> / {plan.interval}</span>
        </>
      )
    } else {
      // Hide non-metered plans, also known as old pricing model plans
      return null
    }

    return (
      <Product
        isSubscribed={isSubscribedPlan}
        isHidden={isPending}
        isSelected={isSelectedPlan}
      >
        <ProductName>{name}</ProductName>
        <ProductPrice>
          {pricing}
          {userPricingList}
          <div>
            {hasTrialPeriodDays
              ? `${plan.trial_period_days} ${t`days of free trial`}`
              : null}
          </div>
        </ProductPrice>
        <ProductContent>
          <strong>{t`Features`}</strong>
          <ProductContentRow>
            <FeatureIcon />
            {max_active_questionnaires || infinityIcon} {maxActiveSurveysText}
            {questionairesWithinLimits ? null : '*'}
          </ProductContentRow>
          <ProductContentRow>
            <FeatureIcon />
            {max_admin_users || infinityIcon} {maxAdminUsersText}
            {adminUsersWithinLimits ? null : '*'}
          </ProductContentRow>
          <ProductContentRow>
            <FeatureIcon />
            {max_users || infinityIcon} {maxUsersText}
            {usersWithinLimits ? null : '*'}
          </ProductContentRow>
          {additional_features.map((feature) => (
            <ProductContentRow>
              <FeatureIcon />
              {feature}
            </ProductContentRow>
          ))}
          <ProductCheckoutButton
            isSelected={isSelectedPlan && !hasTrialPeriodDays}
            disabled={isDisabled}
          >
            {!isSubscribedPlan ? (
              subscribeButton
            ) : (
              <SelectedSubscription>{t`Selected subscription`}</SelectedSubscription>
            )}
          </ProductCheckoutButton>
          {isDisabled && (
            <ProductIsNotSuitable>{t`* This plan does not cover your current requirements`}</ProductIsNotSuitable>
          )}
          {!isSubscribedPlan && (
            <CheckoutForm
              {...this.props}
              isStripePending={isStripePending}
              onSubmit={this.handleSubmit}
              isSelected={isSelectedPlan && !hasTrialPeriodDays}
              clientSecret={clientSecret}
              isPending={isPending}
            />
          )}
        </ProductContent>
      </Product>
    )
  }
}

export default injectStripe(SinglePlan)
