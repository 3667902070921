import React from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { REDUCER_SUBSCRIPTION } from 'store/reducers'
import { t } from 'ttag'

const SubscriptionStatusContainer = styled.div`
  margin-left: auto;
  color: red;
`

const SubscriptionStatus = () => {
  const { data } = useSelector((store) => store[REDUCER_SUBSCRIPTION])
  const { user } = useSelector((store) => store.auth)
  const { company } = useSelector((store) => store.session)
  if (!company) return null
  if (user.user_type !== 'reseller_group') return null
  if (!data) return null
  if (data.length > 0) return null
  return (
    <SubscriptionStatusContainer>{t`Selected company has no active subscription`}</SubscriptionStatusContainer>
  )
}

export default SubscriptionStatus
