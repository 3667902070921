import React from 'react'
import styled from 'styled-components/macro'

const Select = styled.select`
  border: ${props => (props.error ? '1px solid red' : '1px solid gray;')};
  border-radius: 4px;
  color: #ffffff;
  background-color: #535252;
  padding-left: 0.25rem;
  height: 36px;
  width: 100%;
  outline: none;
`

const Label = styled.label`
  height: 14px;
  color: #eaeaee;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`

const ErrorField = styled.span`
  padding: 0;
  font-size: 0.75rem;
  color: red;
`
const SelectInput = ({ label, name, onChange, error, options }) => {
  return (
    <>
      {label ? <Label htmlFor={name}>{label}</Label> : null}
      <div style={{ flex: 1 }}>
        <Select id={name} name={name} onChange={onChange} error={error}>
          <option />
          {options.map(option => (
            <option value={option}>{option}</option>
          ))}
        </Select>
        {error && <ErrorField>{error}</ErrorField>}
      </div>
    </>
  )
}

export default SelectInput
