import { Get, Post } from 'api/index'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const REGISTER_ORG = 'REGISTER_ORG'
export const USER_READ = 'USER_READ'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_CONFIRM = 'PASSWORD_CONFIRM'
export const BULK_CREATE_USERS = 'BULK_CREATE_USERS'
export const PASSWORD_CHANGE = 'PASSWORD_CHANGE'

// Used after redirecting to reset redirect
export const PASSWORD_CONFIRM_RESET = 'PASSWORD_CONFIRM_RESET'

export const login = body => dispatch => {
  const url = `/api/auth/login/`
  return dispatch({
    type: LOGIN,
    payload: Post(url, JSON.stringify(body))
  })
}

export const logout = () => dispatch => {
  const url = `/api/auth/logout/`
  return dispatch({
    type: LOGOUT,
    payload: Post(url)
  })
}

export const registerOrganization = body => dispatch => {
  const url = `/api/auth/registration/`
  return dispatch({
    type: REGISTER_ORG,
    payload: Post(url, JSON.stringify(body))
  })
}

export const userRead = () => dispatch => {
  const url = `/api/auth/user/`
  return dispatch({
    type: USER_READ,
    payload: Get(url)
  })
}

export const resetPassword = (body: { email: string }) => dispatch => {
  const url = `/api/auth/password/reset/`
  return dispatch({
    type: PASSWORD_RESET,
    payload: Post(url, JSON.stringify(body))
  })
}

export const changePassword = (body: {
  new_password1: string,
  new_password2: string
}) => dispatch => {
  const url = `/api/auth/password/change/`
  return dispatch({
    type: PASSWORD_CHANGE,
    payload: Post(url, JSON.stringify(body))
  })
}

export const resetPasswordConfirm = (body: {
  new_password1: string,
  new_password2: string,
  uid: string,
  token: string
}) => dispatch => {
  const url = `/api/auth/password/reset/confirm/`
  return dispatch({
    type: PASSWORD_CONFIRM,
    payload: Post(url, JSON.stringify(body))
  })
}

export const resetPasswordConfirmReset = () => dispatch =>
  dispatch({
    type: PASSWORD_CONFIRM_RESET
  })

export const bulkCreateUsers = (body, companyId) => dispatch => {
  const url = `/api/auth/user/bulk_create/${companyId}/`
  const headers = {
    Accept: '*/*'
  }
  return dispatch({
    type: BULK_CREATE_USERS,
    payload: Post(url, body, headers)
  })
}
