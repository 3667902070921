import styled from 'styled-components/macro'
import { Component } from 'react'
import React from 'react'
import onClickOutside from 'react-onclickoutside'

const Container = styled.div``
const Icon = styled.i`
  cursor: pointer;
`
const MenuContainer = styled.div`
  position: relative;
  background-color: gray;
`

const MenuContent = styled.div`
  position: absolute;
  overflow: hidden;
  width: 8rem;
  right: 4rem;
  background-color: #39383c;
  border-radius: 2px;
  box-shadow: 0px 5px 5px 0px rgba(39, 39, 39, 0.75);
  max-height: ${props => (props.open ? '100px' : '0')};
  transition: max-height 200ms;

  & > button {
    padding: 0.5rem;
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    text-align: left;
    display: block;
    width: 100%;
  }

  & > button:hover {
    background-color: gray;
    transition: background-color 200ms linear;
  }
`

class RowMenu extends Component {
  state = {
    open: false
  }

  handleClickOutside = () => {
    this.setState({ open: false })
  }

  render() {
    const { open } = this.state
    return (
      <Container>
        <Icon
          className="fas fa-ellipsis-v"
          onClick={() => this.setState({ open: !open })}
        />
        <MenuContainer>
          <MenuContent open={open}>{this.props.children}</MenuContent>
        </MenuContainer>
      </Container>
    )
  }
}

export default onClickOutside(RowMenu)
