import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import LanguageSelector from 'components/LanguageSelector/LanguageSelector'

import BrandLogo from 'assets/logo-priocta.png'
import CompanySelection from './CompanySelection'
import SubscriptionStatus from './SubscriptionStatus'

const Container = styled.div`
  background-color: #4c4a4f;
  height: 6rem;
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  color: white;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 999;
`

const Logo = styled.img`
  max-height: 50px;
`

const Header = ({ auth: { loggedIn } }) => {
  return (
    <Container>
      <Link to="/">
        <Logo src={BrandLogo} alt="Priole" />
      </Link>
      <LanguageSelector />
      {loggedIn && <SubscriptionStatus />}
      {loggedIn && <CompanySelection />}
    </Container>
  )
}

const mapStateToProps = ({ auth }) => ({
  auth
})

export default connect(mapStateToProps)(Header)
