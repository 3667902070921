// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import InputWithLabel from 'components/FormElements/Inputs/InputWithLabel/InputWithLabel'
import {
  REDUCER_GROUPS,
  REDUCER_USERS,
  REDUCER_QUESTIONNAIRES
} from 'store/reducers'
import GenericFormHOC from 'components/FormElements/GenericFormHOC'
import * as actions from 'store/actions/CRUD'
import SelectWithLabelMultiple from 'components/FormElements/Inputs/SelectWithLabel/SelectWithLabel'
import UserSelector from '../components/FormElements/Inputs/UserSelector/UserSelector'
import DeleteConfirmation from '../components/DeleteConfirmation'
import Submit from '../components/FormElements/Inputs/Submit/Submit'

const Form = styled.form`
  width: 100%;
`

const FormTitle = styled.div`
  text-align: left;
  color: #eaeaee;
  font-weight: bold;
  border-bottom: 1px solid #212121;
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.3rem;
  padding-bottom: 0.5rem;
`

const FormContainer = styled.table`
  width: 100%;
`

const Title = styled.div`
  font-size: ${props => (props.secondary ? '1rem' : '1.3rem')};
  padding: 1rem 0rem;
  text-transform: ${props => (props.secondary ? 'uppercase' : 'none')};
  color: ${props => (props.secondary ? '#009f86' : 'inherit')};
  font-weight: ${props => (props.secondary ? 'bold' : 'normal')};
  cursor: ${props => (props.secondary ? 'pointer' : 'normal')};
`

const DEFAULT_FIELDS = {
  description: '',
  name: '',
  users: []
}

class GroupsForm extends Component {
  componentDidMount() {
    this.props.populateDataByParamId()
  }

  handleSubmit = e => {
    e.preventDefault()
    const {
      fields,
      handleSaveOrCreate,
      isStale,
      session: { company }
    } = this.props

    // Convert company groups list to ID's only
    fields.users = fields.users.map(item => item.id)

    handleSaveOrCreate(fields, company ? company.id : null).then(() => {
      isStale(REDUCER_GROUPS)
      isStale(REDUCER_USERS)
      isStale(REDUCER_QUESTIONNAIRES)
    })
  }

  onDelete = () => {
    const { isStale, history } = this.props
    isStale(REDUCER_GROUPS)
    isStale(REDUCER_USERS)
    history.push(`/groups`)
  }

  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  moveBack = () => {
    const { history } = this.props

    history.push(`/groups`)
  }

  render() {
    const {
      fields,
      errors,
      object,
      handleChange,
      handleSetFieldValue,
      [REDUCER_USERS]: { data }
    } = this.props

    const allGroups = this.props[REDUCER_GROUPS].data

    return (
      <div>
        <FormTitle colSpan="2">
          {object ? t`Edit group` : t`Create a new group`}
        </FormTitle>
        <Form onSubmit={this.handleSubmit} autocomplete="off">
          <input type="hidden" autocomplete="false" />
          <FormContainer>
            <tr>
              <td colSpan={2}>
                <InputWithLabel
                  label={t`Group name`}
                  name="name"
                  onChange={handleChange}
                  value={fields.name}
                  error={errors.name}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <InputWithLabel
                  label={t`Description`}
                  name="description"
                  onChange={handleChange}
                  value={fields.description}
                  error={errors.description}
                />
              </td>
            </tr>
            <UserSelector
              all={data}
              groups={allGroups}
              name="users"
              selected={fields.users}
              onChange={handleSetFieldValue}
              allUsersTitle={<Title>{t`All users & groups`}</Title>}
              groupsUsersTitle={<Title>{t`Users in this group`}</Title>}
            />
            <tr>
              <td colSpan="4" style={{ paddingTop: '1rem' }}>
                {object && (
                  <DeleteConfirmation
                    reducer={REDUCER_GROUPS}
                    query={`/api/companies/${this.getCompanyId()}/groups/${object}/`}
                    onSuccess={this.onDelete}
                    onFailure={() => {}}
                    ActionButton={
                      <Submit
                        type="button"
                        style={{ float: 'left' }}
                        destructive
                      >{t`Delete`}</Submit>
                    }
                  />
                )}
                <Submit
                  type="submit"
                  style={{ float: 'right' }}
                >{t`Save`}</Submit>
                <Submit
                  type="button"
                  onClick={() => this.moveBack()}
                  style={{ float: 'right', marginRight: '1rem' }}
                  secondary
                >{t`Cancel`}</Submit>
              </td>
            </tr>
          </FormContainer>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericFormHOC(GroupsForm, DEFAULT_FIELDS, REDUCER_GROUPS, '/groups'))
