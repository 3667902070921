import styled from 'styled-components/macro'
import { Component } from 'react'
import Submit from './FormElements/Inputs/Submit/Submit'
import { t } from 'ttag'
import React from 'react'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import connect from 'react-redux/es/connect/connect'
import { REDUCER_QUESTIONS } from '../store/reducers'

const ModalContainer = styled.div`
  display: ${props => (props.open ? 'initial' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalContent = styled.div`
  background-color: #39383c;
  margin: 15% auto;
  border: 1px solid #888;
  width: 50%;
  max-width: 25rem;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
  padding: 1rem 1rem 2rem 1rem;
`

const Label = styled.p`
  width: 100%;
`

const DefaultButton = styled.button``

class DeleteConfirmation extends Component {
  state = {
    open: false
  }

  handleCancel = e => {
    e.preventDefault()
    this.setState({ open: false })
  }

  handleDelete = e => {
    e.preventDefault()
    e.preventDefault()
    const { remove, onSuccess, onFailure, query, reducer } = this.props
    remove(reducer, query).then(response => {
      switch (response.status) {
        case 404:
          return onFailure()
        default:
          this.setState({ open: false })
          return onSuccess()
      }
    })
  }

  render() {
    const { object, onSuccess, onFailure, ActionButton, label } = this.props
    const { open } = this.state

    let button = (
      <button
        onClick={e => {
          e.preventDefault()
          this.setState({ open: true })
        }}
      >{t`Delete`}</button>
    )

    if (ActionButton) {
      button = React.cloneElement(
        ActionButton,
        { onClick: () => this.setState({ open: true }) },
        ActionButton.props.children
      )
    }

    return [
      button,
      <ModalContainer open={open}>
        <ModalContent>
          <Label>
            {label ? label : t`Are you sure you want to delete this object?`}
          </Label>
          <Submit onClick={this.handleDelete}>{t`Delete`}</Submit>
          <Submit onClick={this.handleCancel}>{t`Cancel`}</Submit>
        </ModalContent>
      </ModalContainer>
    ]
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteConfirmation)
