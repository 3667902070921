// @flow
import React, { Component } from 'react'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import { SortableListComponent } from 'components/Lists/'
import { GreenButtonLink } from 'components/Button/GreenButtonLink'
import moment from 'moment'

import {
  REDUCER_SUBSCRIPTION,
  REDUCER_PLANS,
  REDUCER_QUESTIONNAIRES
} from 'store/reducers'
import Reactour from 'reactour'
import playstore from 'assets/play-store.png'
import applestore from 'assets/apple-store.png'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { endTour } from 'store/actions/tour'

const SmallText = styled.span`
  font-size: 0.7rem;
`

const TABLE_HEADINGS = [
  {
    Header: t`Survey`,
    accessor: 'survey'
  },
  {
    Header: t`Active`,
    accessor: 'active'
  },
  {
    Header: t`State`,
    accessor: 'state'
  },
  {
    Header: t`Respondents`,
    accessor: 'respondents'
  },
  {
    Header: t`Late`,
    accessor: 'late'
  },
  {
    Header: t`Started`,
    accessor: 'started'
  },
  {
    accessor: 'buttons'
  }
]

const StyledImage = styled.img`
  margin: 10px;
  max-width: 100px;
`

class Questionnaires extends Component {
  disableBody = target => disableBodyScroll(target)
  enableBody = target => enableBodyScroll(target)

  render() {
    const {
      session: { company },
      [REDUCER_QUESTIONNAIRES]: { data },
      [REDUCER_SUBSCRIPTION]: { data: subscriptions },
      [REDUCER_PLANS]: { data: plans },
      tour: { tourOn, backToSurveys },
      endTour
    } = this.props

    const list = data.map(item => {
      const activeFrom = moment(item.active_from).isValid()
        ? moment(item.active_from).format('L')
        : null

      return {
        key: item.id,
        survey: (
          <div>
            <strong>{item.title}</strong>
            <br />
            <SmallText>{item.description}</SmallText>
          </div>
        ),
        active: item.is_active ? t`Yes` : t`No`,
        state: item.status,
        respondents: item.respondent_ids.length,
        late: item.count_of_late_respondents,
        started: activeFrom,
        buttons: (
          <div>
            <GreenButtonLink
              className={'third-step'}
              to={`/surveys/${item.id}`}
            >{t`OPEN`}</GreenButtonLink>
            <GreenButtonLink
              className="dashboard-link"
              style={{ marginLeft: 15 }}
              to={`/surveys/${item.id}/dashboard`}
            >{t`Dashboard`}</GreenButtonLink>
          </div>
        )
      }
    })

    let maxQuestionnaires = null
    if (subscriptions.length > 0) {
      const currentSubscriptionProductId = subscriptions[0].plan.product

      const currentPlan = plans.find(
        plan => plan.product_id === currentSubscriptionProductId
      )

      if (typeof currentPlan !== 'undefined') {
        maxQuestionnaires = currentPlan.max_active_questionnaires
      }
    }

    const steps = !backToSurveys
      ? [
          {
            selector: '.first-step',
            style: {
              color: '#353438'
            },
            content: () => (
              <div>
                <h3>{t`Welcome to Priocta!`}</h3>
                <p>
                  {t`Let’s have a quick tour to get you started with the use of
                  Priocta.`}
                </p>
              </div>
            )
          },
          {
            selector: '.second-step',
            style: {
              color: '#353438'
            },
            position: 'center',
            content: () => (
              <div>
                <p>
                  {t`To use Priocta you need to download the Priocta mobile app to
                  your phone.`}
                </p>
                <p>
                  {t`The Priocta app is used for answering surveys. Use the desktop
                  mode to manage surveys and users.`}
                </p>
                <p
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                  }}
                >
                  <a
                    href={
                      'https://play.google.com/store/apps/details?id=fi.priole.priocta'
                    }
                    target={'_blank'}
                  >
                    <StyledImage
                      src={playstore}
                      alt={t`Get Priocta app from Google Play`}
                    />
                  </a>
                  <a
                    href={
                      'https://apps.apple.com/us/app/priocta/id1464662574?l=fi&ls=1'
                    }
                    target={'_blank'}
                  >
                    <StyledImage
                      src={applestore}
                      alt={t`Get Priocta app from Apple store`}
                    />
                  </a>
                </p>
              </div>
            )
          },
          {
            selector: '.third-step',
            style: {
              color: '#353438'
            },
            content: () => {
              return (
                <div>
                  <p>
                    {t`To be able to receive questions with your mobile app, you
                    need to be added to a survey.`}
                  </p>
                  <p>
                    {t`Let’s practice this by connecting you to a template survey.`}
                  </p>
                  <p>{t`Please click OPEN`}</p>
                </div>
              )
            }
          }
        ]
      : [
          {
            selector: '.second-step',
            style: {
              color: '#353438'
            },
            position: 'center',
            content: () => (
              <div>
                <p>
                  {t`Next, use your phone and log in to the app with the same email
                  address and password that you are using now.`}
                </p>
                <p>
                  {t`Questions of this template survey should be visible on your
                  app. Answer these questions to generate data to the dashboard
                  view.`}
                </p>
                <p
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                  }}
                >
                  <a
                    href={
                      'https://play.google.com/store/apps/details?id=fi.priole.priocta'
                    }
                    target={'_blank'}
                  >
                    <StyledImage
                      src={playstore}
                      alt={t`Get Priocta app from Google Play`}
                    />
                  </a>
                  <a
                    href={
                      'https://apps.apple.com/us/app/priocta/id1464662574?l=fi&ls=1'
                    }
                    target={'_blank'}
                  >
                    <StyledImage
                      src={applestore}
                      alt={t`Get Priocta app from Apple store`}
                    />
                  </a>
                </p>
              </div>
            )
          },
          {
            selector: '.dashboard-link',
            style: {
              color: '#353438'
            },
            content: () => (
              <div>
                <p>
                  {t`You can see the collected survey data from the DASHBOARD
                  button. From the dashboard view you can see all the answers
                  given to that specific survey.`}
                </p>
                <p>{t`Please click DASHBOARD`}</p>
              </div>
            )
          }
        ]

    return (
      <>
        <SortableListComponent
          title={t`Surveys`}
          headers={TABLE_HEADINGS}
          data={list}
          company={company}
          linkToForm="/surveys/new"
          limit={maxQuestionnaires}
          limitText={t`You cannot add more surveys. You have reached the maximum limit of active surveys for your subscription.`}
        />
        <Reactour
          steps={steps}
          showNavigationNumber={false}
          isOpen={tourOn}
          onRequestClose={endTour}
          showNumber={false}
          rounded={5}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          showNavigation={false}
        />
      </>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions, endTour }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questionnaires)
