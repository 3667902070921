import React from 'react'
import styled, { css } from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

const Container = styled(NavLink)`
  width: 100%;
  display: flex;
  cursor: pointer;
  transition: background-color 200ms linear;
  color: white;
  text-decoration: none;

  &:hover {
    background-color: gray;
    transition: background-color 200ms linear;
  }

  ${props =>
    props.disabled &&
    css`
      color: gray;
      pointer-events: none;
      cursor: pointer;
    `}
`

const Icon = styled.i.attrs({
  src: props => props.src
})`
  width: 48px;
  padding: 1rem 0.5rem 1rem 1rem;
`

const Title = styled.div`
  padding: 1rem 0.5rem;
  flex-grow: 1;
`

const LinkElement = ({
  to,
  icon,
  title,
  onClick,
  disabled,
  className,
  ...rest
}) => (
  <Container
    {...rest}
    to={to}
    onClick={!disabled ? onClick : () => {}}
    activeStyle={{
      backgroundColor: 'gray'
    }}
    className={className}
  >
    <Icon className={`fas ${icon}`} />
    <Title>{title}</Title>
  </Container>
)

export default LinkElement
