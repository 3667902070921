// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ttag'
import * as actions from 'store/actions/CRUD'
import {
  REDUCER_USERS,
  REDUCER_GROUPS,
  REDUCER_QUESTIONNAIRES,
  REDUCER_QUESTIONS
} from 'store/reducers'
import GenericFormHOC from 'components/FormElements/GenericFormHOC'
import Questions from 'components/Questions'
import NewQuestion from 'components/NewQuestion'
import UserSelector from 'components/FormElements/Inputs/UserSelector/UserSelector'
import InputTitle from '../components/FormElements/Inputs/InputWithLabel/InputTitle'
import InputTextArea from '../components/FormElements/Inputs/InputWithLabel/InputTextArea'
import Submit from '../components/FormElements/Inputs/Submit/Submit'
import DeleteConfirmation from '../components/DeleteConfirmation'
import DateTimeInput from '../components/FormElements/Inputs/DateTimeInput/DateTimeInput'
import Label from '../components/FormElements/Inputs/Label/Label'
import Toggle from '../components/Toggle/Toggle'
import Reactour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { endTour } from 'store/actions/tour'

const Form = styled.form`
  width: 100%;
`

const FormContainer = styled.div`
  width: 100%;
`

const FormSection = styled.div`
  height: auto;
  max-height: ${props => (props.open ? '1000px' : '0')};
  display: inline-block;
  overflow: ${props => (props.open ? 'visible' : 'hidden')};
  transition: max-height 300ms;
  width: 100%;
`

const FormTitleSection = styled(FormSection)`
  display: flex;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: ${props => (props.secondary ? '1rem' : '1.3rem')};
  padding: 1rem 0rem;
  text-transform: ${props => (props.secondary ? 'uppercase' : 'none')};
  color: ${props => (props.secondary ? '#009f86' : 'inherit')};
  font-weight: ${props => (props.secondary ? 'bold' : 'normal')};
  cursor: ${props => (props.secondary ? 'pointer' : 'normal')};
`

const DEFAULT_FIELDS = {
  title: '',
  description: '',
  respondent_ids: [],
  active_from: null,
  active_until: null,
  anonymous: false
}

class QuestionnaireForm extends Component {
  state = {
    step: 0,
    editMode: false,
    addQuestion: false,
    editSchedule: false,
    run: false
  }

  componentDidMount() {
    const { populateDataByParamId } = this.props
    const populate = populateDataByParamId()
    if (populate) this.setState({ editMode: true })
    this.updateQuestionList()
  }

  componentDidUpdate() {
    const questionReducerIsStale =
      this.getCompanyId() && this.props[REDUCER_QUESTIONS].isStale
    if (questionReducerIsStale) {
      this.updateQuestionList()
    }
  }

  /**
   * When component is unmounted clear all questions data from store
   */
  componentWillUnmount() {
    const { isStale } = this.props
    isStale(REDUCER_QUESTIONS)
  }

  disableBody = target => disableBodyScroll(target)
  enableBody = target => enableBodyScroll(target)

  updateQuestionList = () => {
    const {
      match: { params },
      session: { company },
      list,
      object
    } = this.props
    if (this.getCompanyId()) {
      const id = object || parseInt(params.id)
      list(
        REDUCER_QUESTIONS,
        null,
        `/api/companies/${this.getCompanyId()}/${REDUCER_QUESTIONNAIRES.toLowerCase()}/${id}/${REDUCER_QUESTIONS.toLowerCase()}/`
      )
    }
  }

  nextStep = () => {
    const { step } = this.state
    this.setState({
      step: step + 1
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { step, editMode } = this.state
    const {
      fields,
      handleSaveOrCreate,
      handleSetObjectId,
      history,
      tour: { tourOn }
    } = this.props

    // Convert company groups list to ID's only
    fields.respondent_ids = fields.respondent_ids.map(item => item.id)

    handleSaveOrCreate(fields, this.getCompanyId()).then(questionnaire => {
      if (questionnaire && questionnaire.value) {
        if (tourOn) {
          history.push(`/surveys`)
        } else if (editMode || step >= 2) {
          history.push(`/surveys/${questionnaire.value.id}/`)
        } else {
          this.nextStep()
        }
        handleSetObjectId(questionnaire.value.id)
      }
    })
  }

  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  onDelete = () => {
    const { isStale, history } = this.props
    isStale(REDUCER_QUESTIONNAIRES)
    history.push(`/surveys`)
  }

  render() {
    const { step, editMode, addQuestion, editSchedule } = this.state
    const {
      fields,
      errors,
      handleChange,
      handleToggle,
      object,
      handleSetFieldValue,
      history,
      tour: { tourOn },
      endTour
    } = this.props

    const allUsers = this.props[REDUCER_USERS].data
    const questions = this.props[REDUCER_QUESTIONS].data
    const allGroups = this.props[REDUCER_GROUPS].data

    const steps = [
      {
        selector: '.select-user > div',
        style: {
          color: '#353438'
        },
        content: () => (
          <div>
            <p>
              {t`Shift yourself from the left box to the right box by clicking your
              name.`}
            </p>
          </div>
        )
      },
      {
        selector: '.question-form-save',
        style: {
          color: '#353438'
        },
        content: () => (
          <div>
            <p>{t`Then click SAVE.`}</p>
            <p>
              {t`Now your mobile app will receive the template survey questions
              periodically.`}
            </p>
          </div>
        )
      }
    ]

    return (
      <div>
        <Reactour
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          steps={steps}
          isOpen={tourOn}
          onRequestClose={endTour}
          showNumber={false}
          rounded={5}
          update={allUsers && questions}
          showNavigation={false}
          showNavigationNumber={false}
        />
        <Form onSubmit={this.handleSubmit}>
          <FormContainer>
            <FormSection open>
              <Title>{t`Title`}:</Title>
              <InputTitle
                group="survey"
                name="title"
                placeholder={t`Type in a title for this survey...`}
                onChange={handleChange}
                value={fields.title}
                error={errors.title}
              />
              <Title>{t`Description`}:</Title>
              <InputTextArea
                small
                rows={5}
                group="survey"
                name="description"
                placeholder={t`Add a description for this survey...`}
                onChange={handleChange}
                value={fields.description}
                error={errors.description}
              />
              <Title>{t`Anonymous answering`}:</Title>
              <Label>{t`You cannot change the setting after the survey is published`}</Label>
              <Toggle
                type="checkbox"
                name="anonymous"
                onToggle={handleToggle}
                checked={fields.anonymous}
                disabled={editMode}
              />
            </FormSection>
            <FormSection open={editSchedule}>
              <Title>{t`Change survey schedule`}</Title>
              <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <DateTimeInput
                  name="active_from"
                  title={t`Start date`}
                  onChange={handleChange}
                  value={fields.active_from}
                  error={errors.active_from}
                />
                <DateTimeInput
                  name="active_until"
                  title={t`End date`}
                  onChange={handleChange}
                  value={fields.active_until}
                  error={errors.active_until}
                />
              </div>
              <Submit
                style={{ marginLeft: 'auto' }}
                onClick={() => this.setState({ editSchedule: !editSchedule })}
                secondary
                type="button"
              >
                {t`Cancel`}
              </Submit>
            </FormSection>
            <FormSection
              open={
                (editMode && addQuestion) ||
                (!!object && step === 1) ||
                (addQuestion && !!object && step > 1)
              }
            >
              <Title>{t`Add new question`}</Title>
              <NewQuestion
                company={this.getCompanyId()}
                parent={object}
                onCancel={() => this.setState({ addQuestion: false })}
                {...this.props}
              />
            </FormSection>
            <FormTitleSection open={editMode || (!!object && step > 0)}>
              <Title style={{ flex: 1 }}>{t`Questions`}</Title>
              {((editMode && !addQuestion && !editSchedule) ||
                (!editMode && !addQuestion && !!object && step > 1)) && [
                <div>
                  <Submit
                    secondary
                    onClick={() =>
                      this.setState({ editSchedule: !editSchedule })
                    }
                    style={{ marginRight: '1rem' }}
                  >{t`Change schedule`}</Submit>
                </div>,
                <div>
                  <Submit
                    onClick={() => this.setState({ addQuestion: true })}
                  >{t`Add new question`}</Submit>
                </div>
              ]}
            </FormTitleSection>
            <FormSection open={editMode || (!!object && step > 0)}>
              <Questions
                {...this.props}
                company={this.getCompanyId()}
                parent={object}
                data={questions}
              />
            </FormSection>
            <FormSection open={editMode || (!!object && step > 1)}>
              <UserSelector
                all={allUsers}
                groups={allGroups}
                name="respondent_ids"
                selected={fields.respondent_ids}
                onChange={handleSetFieldValue}
                allUsersTitle={<Title>{t`All users & groups`}</Title>}
                groupsUsersTitle={<Title>{t`Users in this survey`}</Title>}
              />
            </FormSection>
            <FormSection open>
              <div
                style={{
                  marginTop: '2rem',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                {editMode ? (
                  <DeleteConfirmation
                    reducer={REDUCER_QUESTIONNAIRES}
                    query={`/api/companies/${this.getCompanyId()}/questionnaires/${object}/`}
                    label={t`Do you really want to delete this survey? This will also delete all question entries if such exist.`}
                    onSuccess={this.onDelete}
                    onFailure={() => {}}
                    ActionButton={
                      <Submit
                        type="button"
                        style={{ float: 'left' }}
                        destructive
                      >{t`Delete`}</Submit>
                    }
                  />
                ) : (
                  <div />
                )}
                <div style={{ display: 'flex' }}>
                  <Submit
                    style={{ marginRight: '15px' }}
                    type="button"
                    onClick={
                      editMode
                        ? () => history.push(`/surveys/${object}/`)
                        : () => history.push(`/quetionnaires`)
                    }
                    secondary
                  >{t`Cancel`}</Submit>
                  <Submit
                    type="submit"
                    className="question-form-save"
                    secondary={!editMode && step < 2}
                  >
                    {!editMode && step < 2 ? t`Next` : t`Save`}
                  </Submit>
                </div>
              </div>
            </FormSection>
          </FormContainer>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions, endTour }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GenericFormHOC(
    QuestionnaireForm,
    DEFAULT_FIELDS,
    REDUCER_QUESTIONNAIRES,
    null
  )
)
