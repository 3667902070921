// @flow

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { login } from 'store/actions/auth'
import { resetErrorMessage } from 'store/actions/error'
import { REDUCER_SUBSCRIPTION } from 'store/reducers'

import { isMobile } from 'react-device-detect'

import MobileBlocked from 'components/MobileBlocked'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    auth: {
      tokenExpired,
      subscriptionChecked,
      registrationRedirect,
      isReseller
    },
    [REDUCER_SUBSCRIPTION]: { data },
    location: { pathname }
  } = rest

  let isAuthenticated = false
  let redirect = false

  const token = sessionStorage.getItem('API_TOKEN')
  if (token !== null) {
    isAuthenticated = true
  }

  if (tokenExpired) {
    sessionStorage.removeItem('API_TOKEN')
    redirect = true
  }

  const companyHasActiveSubscription = data.length > 0
  const currentLocationIsPlans = pathname === '/plans'

  const redirectToPlans =
    !companyHasActiveSubscription && !isReseller
      ? !currentLocationIsPlans && subscriptionChecked
      : false

  const redirectToLogin =
    (!isAuthenticated || redirect) && !registrationRedirect

  if (redirectToLogin) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )}
      />
    )
  } else if (redirectToPlans) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: '/plans', state: { from: props.location } }}
          />
        )}
      />
    )
  } else if (isMobile) {
    return <Route {...rest} render={(props) => <MobileBlocked {...props} />} />
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

const mapStateToProps = (...args) => args[0]

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      resetErrorMessage
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
