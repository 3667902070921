import React from 'react'
import { t } from 'ttag'
import styled, { keyframes } from 'styled-components/macro'

import StripeLogo from 'assets/stripe-logo.png'
import CardSection from './CardSection'
import Submit from './FormElements/Inputs/Submit/Submit'

const Form = styled.form`
  background: #4c4a4f;
  margin: 0 auto;
  margin-top: 30px;
  padding: 5px;
  border-radius: 8px;
  display: ${props => (props.isSelected ? 'block' : 'none')};
`

const Logo = styled.img`
  max-height: 50px;
`

const FieldsContainer = styled.div`
  background-color: #39383c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 5px;
`

const FieldContainer = styled.div`
  padding: 10px;
  margin-bottom: 2px;
  color: white;
`

const CheckoutSubmit = styled(Submit)`
  box-shadow: none;
  width: 100%;
`

const Agreements = styled.div`
  padding-bottom: 1rem;
  background: #4c4a4f;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 6px;

  input {
    width: 22px;
    height: 15px;
  }
  label {
    font-size: 12px;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate} 2s linear infinite;
  display: inline-block;
`

class CheckoutForm extends React.Component {
  state = {
    consent: false
  }

  toggleConsent = () => {
    const { consent } = this.state
    this.setState({ consent: !consent })
  }

  render() {
    const {
      isSelected,
      clientSecret,
      isPending,
      onSubmit,
      isStripePending
    } = this.props
    const { consent } = this.state
    const isCheckoutPending = isStripePending || isPending

    return (
      <Form isSelected={isSelected} onSubmit={onSubmit}>
        <Logo src={StripeLogo} alt="Stripe" />
        <FieldsContainer>
          <FieldContainer>
            <CardSection />
          </FieldContainer>
          <Agreements>
            <input
              type="checkbox"
              name="agreements"
              onChange={this.toggleConsent}
            />
            <label htmlFor="agreements">
              {t`I authorise Priocta (Easteam Oy) to send instructions to the financial institution that issued my card to take payments from my card account.`}
            </label>
          </Agreements>
          <CheckoutSubmit
            disabled={!consent}
            data-secret={clientSecret}
            onClick={this.onPlanSelect}
          >
            {isCheckoutPending ? (
              <Spinner>
                <i className="fas fa-spinner" />
              </Spinner>
            ) : (
              t`Confirm order`
            )}
          </CheckoutSubmit>
        </FieldsContainer>
      </Form>
    )
  }
}

export default CheckoutForm
