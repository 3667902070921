import styled from 'styled-components/macro'

const SelectWithLabelMultiple = styled.select`
  border: ${props => (props.error ? '1px solid red' : 'none')};
  border-radius: 4px;
  background-color: #535252;
  color: #ffffff;
  min-height: 36px;
  padding-left: 0.25rem;
  width: 100%;
`

export default SelectWithLabelMultiple
