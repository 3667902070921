import react from 'react'
import styled from 'styled-components/macro'
import { lighten } from 'polished'

const Submit = styled.button.attrs(props => ({
  color: props.destructive ? '#f46d65' : props.secondary ? '#CEA766' : '#009f86'
}))`
  padding: 0 3rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  line-height: 36px;
  display: block;
  text-decoration: none;
  color: #fff;
  text-align: center;
  background: ${props => props.color};
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  outline: 0;
  box-shadow: 0px 5px 5px 0px rgba(39, 39, 39, 0.75);
  transition: background 200ms;
  
  &:hover {
    background: ${props => lighten(0.08, props.color)};
    /*background: ${props => (props.secondary ? '#e1ba68' : '#00b498')};*/
  }

  &:disabled {
    background: gray;
    cursor: default;
  }

  
`

export default Submit
