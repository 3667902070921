/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Bar, Line } from 'react-chartjs-2'
import {
  REDUCER_CHARTS,
  REDUCER_QUESTIONNAIRES,
  REDUCER_SUBSCRIPTION
} from 'store/reducers'
import { bindActionCreators } from 'redux'
import connect from 'react-redux/es/connect/connect'
import * as actions from 'store/actions/CRUD'
import { t } from 'ttag'
import Submit from 'components/FormElements/Inputs/Submit/Submit'
import DateTimeInput from 'components/FormElements/Inputs/DateTimeInput/DateTimeInput'
import moment from 'moment'
import GenericFormHOC from 'components/FormElements/GenericFormHOC'
import { lighten } from 'polished'
import { toast } from 'react-toastify'
import Button from 'components/Button/Button'
import Toggle from 'components/Toggle/Toggle'

const Select = styled.select`
  border: ${(props) => (props.error ? '1px solid red' : '#979797')};
  border-radius: 4px;
  background-color: #535252;
  color: #ffffff;
  padding-left: 0.25rem;
  height: 36px;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
`
const QuestionContainer = styled.div`
  background: linear-gradient(180deg, #39383c 0%, #28272b 100%);
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
`

const TextBox = styled.div`
  background-color: #009f86;
  border-radius: 4px;
  padding: 60px 20px;
  font-weight: bold;
  width: 23%;
  margin: 1%;
  position: relative;
`

const ToggleSpan = styled.span`
  color: rgb(0, 159, 134);
  padding-bottom: 10px;
  display: inline-block;
  margin-top: 18px;

  &:hover {
    cursor: pointer;
  }
`

const PaginatioButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`

const ModalContainer = styled.div`
  display: ${(props) => (props.open ? 'initial' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalContent = styled.div`
  background-color: #39383c;
  margin: 15% auto;
  border: 1px solid #888;
  width: 50%;
  max-width: 50rem;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
  padding: 1rem 1rem 2rem 1rem;
`

const Label = styled.p`
  width: 100%;
`

const CommentLink = styled.span`
  color: rgb(0, 159, 134);
  padding-bottom: 10px;
  display: inline-block;
  padding-left: 10px;
  font-size: 16px;
  font-weight: normal;

  &:hover {
    cursor: pointer;
  }
`

const ToggleRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ToggleContainer = styled.div`
  display: flex;
  margin-right: 20px;
`

const ToggleLabel = styled.p`
  padding: 0 10px;
  margin-top: 18px;
`

const DataTypeContainer = styled.div``

const ToggleTitle = styled.p`
  margin-bottom: 0;
  width: 180px;
  text-align: center;
`

const ShareContainer = styled.div`
  margin-left: auto;
  padding-top: 1em;
`

const Share = styled.span`
  &:hover {
    cursor: pointer;
  }
`

const SearchButton = styled(Submit)`
  display: inline-block;
`

const data = {
  labels: ['Red', 'Green', 'Yellow'],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
    }
  ]
}

const startDefault = moment().subtract(1, 'M')
const endDefault = moment()

const DEFAULT_FIELDS = {
  start_date: startDefault.toISOString(),
  end_date: endDefault.toISOString(),
  user: null,
  group: null
}

class Charts extends Component {
  state = {
    filterOpen: false,
    relative: false,
    textPagination: [],
    commentModal: {}
  }

  componentDidMount() {
    this.getResults()
    this.colorIndex = 0
  }

  toggleFilters() {
    this.setState({
      filterOpen: !this.state.filterOpen
    })
  }

  copyToClipboard(url) {
    const el = document.createElement('textarea')
    el.value = url
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  openCommentModal(questionId) {
    const { commentModal } = this.state

    const modal = commentModal
    modal[questionId] = true

    this.setState({
      commentModal: modal
    })
  }

  closeCommentModal(questionId) {
    const { commentModal } = this.state

    const modal = commentModal
    modal[questionId] = false

    this.setState({
      commentModal: modal
    })
  }

  getCompanyId() {
    try {
      const {
        session: { company }
      } = this.props
      return company.id
    } catch (e) {
      console.error('Company is not defined.')
    }
  }

  getCSV() {
    const { questionnaireId, companyId, fields, respondents } = this.props

    const values = []
    if (fields.start_date) {
      values.start_date = fields.start_date
    }

    if (fields.end_date) {
      values.end_date = fields.end_date
    }

    if (fields.group) {
      if (!fields.user) {
        let { group } = fields
        if (fields.group === t`All groups`) {
          group = ''
        }
        values.group = group
      } else {
        values.user = fields.user
      }
    } else if (fields.user) {
      values.user = fields.user
    }

    const params = new URLSearchParams()
    for (const [key, val] of Object.entries(values)) {
      params.append(key, val)
    }
    const queryString = params.toString()

    const anchor = document.createElement('a')
    document.body.appendChild(anchor)
    const file = `/api/companies/${companyId}/questionnaires/${questionnaireId}/export/?${queryString}`

    const headers = new Headers()
    headers.append(
      'Authorization',
      `Token ${sessionStorage.getItem('API_TOKEN')}`
    )

    fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = 'stats.csv'
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
      })
  }

  getResults() {
    const { list, questionnaireId, companyId, fields, respondents } = this.props

    const values = []
    if (fields.start_date) {
      values.start_date = fields.start_date
    }

    if (fields.end_date) {
      values.end_date = fields.end_date
    }

    if (fields.group) {
      if (!fields.user) {
        let { group } = fields
        if (fields.group === t`All groups`) {
          group = ''
        }
        values.group = group
      } else {
        values.user = fields.user
      }
    } else if (fields.user) {
      values.user = fields.user
    }

    const params = new URLSearchParams()
    for (const [key, val] of Object.entries(values)) {
      params.append(key, val)
    }
    const queryString = params.toString()

    let url = null
    if (this.getCompanyId()) {
      url = `/api/companies/${companyId}/${REDUCER_QUESTIONNAIRES.toLowerCase()}/${questionnaireId}/${REDUCER_CHARTS.toLowerCase()}/?${queryString}`
    } else {
      url = `/api/${REDUCER_QUESTIONNAIRES.toLowerCase()}/share/${questionnaireId}/${REDUCER_CHARTS.toLowerCase()}/?${queryString}`
    }

    list(REDUCER_CHARTS, null, url)
  }

  resetColorIndex() {
    this.colorIndex = 0
  }

  getColor() {
    const colors = [
      '#009f86',
      '#cea766',
      '#ff9b78',
      '#fdc957',
      '#f48762',
      '#c7728f',
      '#8a84c4',
      '#4db6ce',
      '#92bb6f',
      '#a39a8b',
      '#a97c4c'
    ]

    const color = colors[this.colorIndex]

    this.colorIndex += 1
    if (this.colorIndex > colors.length - 1) {
      this.colorIndex = 0
    }

    return color
  }

  setQuestionPage(question, page) {
    const { textPagination } = this.state
    textPagination[question] = page
    this.setState({ textPagination })
  }

  handleGroupChange(e) {
    const { handleChange, fields } = this.props
    handleChange(e)
  }

  createShare(questionnaireId) {
    const uuidv4 = require('uuid/v4')
    const uuid = uuidv4()
    this.props.edit(
      REDUCER_QUESTIONNAIRES,
      {
        share_enabled: true,
        share_hash: uuid
      },
      this.getCompanyId(),
      questionnaireId,
      ''
    )

    const share_url = `${window.location.protocol}//${window.location.host}/share/${uuid}`
    this.copyToClipboard(share_url)
    toast.info(t`Share url has been copied to clipboard`)
  }

  removeShare(questionnaireId) {
    this.props.edit(
      REDUCER_QUESTIONNAIRES,
      {
        share_enabled: false,
        share_hash: ''
      },
      this.getCompanyId(),
      questionnaireId,
      ''
    )
  }

  render() {
    const chartData = this.props[REDUCER_CHARTS]
    let results = []
    const {
      handleChange,
      questionnaire,
      questionnaireId,
      fields,
      isShare,
      respondents,
      groups,
      isAnonymous,
      activeFrom,
      edit
    } = this.props
    const { relative } = this.state

    const currentSubscription =
      this.props[REDUCER_SUBSCRIPTION].data[0] || false

    let exportButton = null

    const chart = chartData.data
    if (
      chart &&
      chart.length > 0 &&
      chart[0].results &&
      chart[0].results.length > 0
    ) {
      this.resetColorIndex()
      for (const [key, question] of Object.entries(chart[0].results)) {
        const allComments = []

        for (const [key, period] of Object.entries(question.periods[0])) {
          const skippedComments = period.skipped_comments.map((comment) => {
            return (
              <div style={{ marginBottom: 10 }}>
                {comment.skip_option_value} - {comment.comment} -{' '}
                {!isAnonymous ? comment.name : null}
              </div>
            )
          })
          const comments = period.comments.map((comment) => {
            return (
              <div style={{ marginBottom: 10 }}>
                {comment.comment} - {!isAnonymous ? comment.name : null}
              </div>
            )
          })

          if (skippedComments.length > 0 || comments.length > 0) {
            allComments.push(<h2>{period.start_date}</h2>)
            if (skippedComments.length > 0) {
              allComments.push(<h3>{t`Skipped comments`}</h3>)
              allComments.push(<div>{skippedComments}</div>)
            }
            if (comments.length > 0) {
              allComments.push(<h3>{t`Comments`}</h3>)
              allComments.push(<div>{comments} </div>)
            }
          }
        }

        let allCommentModal = null
        if (allComments.length > 0) {
          const { commentModal } = this.state
          let open = false
          if (key in commentModal && commentModal[key] === true) {
            open = true
          }
          allCommentModal = (
            <ModalContainer open={open}>
              <ModalContent style={{ flexDirection: 'column' }}>
                {allComments}
                <Submit
                  style={{ marginTop: 15 }}
                  onClick={() => this.closeCommentModal(key)}
                >{t`Close`}</Submit>
              </ModalContent>
            </ModalContainer>
          )
        }

        // Only number and slider fields.
        if (question.field_type === 4 || question.field_type === 8) {
          let chart = []
          const labels = []
          const datasets = []
          let c = 0
          const linear = new Array(question.periods[0].length).fill('')
          const totals = []
          for (const [key, period] of Object.entries(question.periods[0])) {
            labels.push(`${period.start_date} (${period.answer_percentage}%)`)
            linear[c] = period.linear
            if (relative) {
              linear[c] = period.relative_linear
            }
            totals[`${period.start_date} (${period.answer_percentage}%)`] =
              period.total

            if (relative) {
              totals[`${period.start_date} (${period.answer_percentage}%)`] =
                period.relative_total
            }

            if (relative) {
              datasets[c] = period.relative_total
            } else {
              for (const [key, field] of Object.entries(period.fields)) {
                if (!datasets[field.name]) {
                  const arr = new Array(question.periods[0].length).fill('')
                  datasets[field.name] = arr
                }

                const dataset = datasets[field.name]
                dataset[c] = field.value
                datasets[field.name] = dataset
              }
            }
            c += 1
          }

          const dataset_objects = []
          if (relative) {
            const color = this.getColor()
            dataset_objects.push({
              label: t('total'),
              data: datasets,
              backgroundColor: color
            })
          } else {
            for (const [key, field] of Object.entries(datasets)) {
              const color = this.getColor()
              dataset_objects.push({
                label: key,
                data: field,
                backgroundColor: color
              })
            }
          }
          dataset_objects.unshift({
            data: linear,
            type: 'line',
            borderDash: [5, 5],
            fill: false,
            borderColor: '#FFFFFF'
          })

          const data = {
            labels,
            datasets: dataset_objects
          }

          chart = (
            <Bar
              data={data}
              options={{
                responsive: true,
                tooltips: {
                  callbacks: {
                    afterLabel(tooltipItem, data) {
                      return `${t`Total`}: ${totals[tooltipItem.label]}`
                    }
                  }
                },
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                      ticks: {
                        fontColor: '#EAEAEE'
                      },
                      gridLines: {
                        display: false
                      }
                    }
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      ticks: {
                        fontColor: '#EAEAEE'
                      },
                      gridLines: {
                        color: 'rgba(99,99,99,1)',
                        zeroLineColor: 'rgba(99,99,99,1)'
                      }
                    }
                  ]
                },
                legend: {
                  display: false,
                  labels: {
                    fontColor: '#EAEAEE'
                  },
                  onClick: null
                }
              }}
            />
          )

          results.push(
            <QuestionContainer key={question.label}>
              <h2
                style={{
                  paddingBottom: 20,
                  margin: 0
                }}
              >
                {question.label}
                {allComments.length > 0 ? (
                  <CommentLink onClick={() => this.openCommentModal(key)}>
                    {t`Comments`}
                  </CommentLink>
                ) : null}
              </h2>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '50%'
                }}
              >
                {chart}
                {allCommentModal}
              </div>
            </QuestionContainer>
          )
        } else if (question.field_type === 2) {
          const answers = []
          const allAnswers = []
          for (const [key, period] of Object.entries(question.periods[0])) {
            for (const [key, field] of Object.entries(period.fields)) {
              allAnswers.push(field)
            }
          }

          let page = 1
          if (question.id in this.state.textPagination) {
            page = this.state.textPagination[question.id]
          }

          const perPage = 20
          const start = page * perPage - perPage
          const end = page * perPage - 1
          const maxPages = Math.ceil(allAnswers.length / perPage)

          const pagedAnswers = allAnswers.slice(start, end + 1)

          let previous = null
          if (page > 1) {
            previous = (
              <PaginatioButton
                style={{
                  paddingRight: 20
                }}
                onClick={() => this.setQuestionPage(question.id, page - 1)}
              >
                {'<<'}
              </PaginatioButton>
            )
          }

          let next = null
          if (maxPages > 1 && page < maxPages) {
            next = (
              <PaginatioButton
                onClick={() => this.setQuestionPage(question.id, page + 1)}
              >
                {'>>'}
              </PaginatioButton>
            )
          }

          for (const [key, field] of Object.entries(pagedAnswers)) {
            answers.push(
              <TextBox key={field.value}>
                <i
                  className="far fa-comment"
                  style={{
                    position: 'absolute',
                    top: 0,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                    backgroundColor: '#39383C',
                    fontSize: 25
                  }}
                />
                <div>{field.value}</div>
                <div
                  style={{
                    marginTop: 10
                  }}
                >
                  {field.person_name}
                </div>
              </TextBox>
            )
          }

          results.push(
            <QuestionContainer key={question.label}>
              <h2
                style={{
                  paddingBottom: 20,
                  margin: 0
                }}
              >
                {question.label}
                {allComments.length > 0 ? (
                  <CommentLink onClick={() => this.openCommentModal(key)}>
                    {t`Comments`}
                  </CommentLink>
                ) : null}
              </h2>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {answers}
                {allCommentModal}
              </div>
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20
                }}
              >
                {previous}
                {next}
              </div>
            </QuestionContainer>
          )
        } else if (question.field_type === 3 || question.field_type === 5) {
          let chart = []
          const labels = []
          const datasets = []
          const linear_datasets = []
          let c = 0
          const totals = []
          for (const [key, period] of Object.entries(question.periods[0])) {
            labels.push(`${period.start_date} (${period.answer_percentage}%)`)
            totals[`${period.start_date} (${period.answer_percentage}%)`] =
              period.total

            for (const [key, field] of Object.entries(period.fields)) {
              if (!datasets[field.name]) {
                const arr = new Array(question.periods[0].length).fill('')
                datasets[field.name] = arr
              }

              const dataset = datasets[field.name]
              dataset[c] = field.percentage
              datasets[field.name] = dataset

              if (!linear_datasets[field.name]) {
                const arr = new Array(question.periods[0].length).fill('')
                linear_datasets[field.name] = arr
              }

              const linear_dataset = linear_datasets[field.name]
              linear_dataset[c] = field.linear
              linear_datasets[field.name] = linear_dataset
            }
            c += 1
          }

          const dataset_objects = []
          const colors = []
          for (const [key, field] of Object.entries(datasets)) {
            colors[key] = this.getColor()
            dataset_objects.push({
              label: key,
              data: field,
              backgroundColor: colors[key]
            })
          }

          const data = {
            labels,
            datasets: dataset_objects
          }

          const linear_dataset_objects = []
          for (const [key, field] of Object.entries(linear_datasets)) {
            linear_dataset_objects.push({
              label: key,
              data: field,
              borderColor: colors[key],
              borderDash: [5, 5],
              fill: false
            })
          }

          const linearData = {
            labels,
            datasets: linear_dataset_objects
          }

          chart = (
            <div
              style={{
                display: 'flex',
                width: '100%'
              }}
            >
              <div style={{ width: '50%' }}>
                <Bar
                  data={data}
                  options={{
                    responsive: true,
                    tooltips: {
                      callbacks: {
                        afterLabel(tooltipItem, data) {
                          return `${t`Total`}: ${totals[tooltipItem.label]}`
                        }
                      }
                    },
                    scales: {
                      xAxes: [
                        {
                          stacked: true,
                          ticks: {
                            fontColor: '#EAEAEE'
                          },
                          gridLines: {
                            display: false
                          }
                        }
                      ],
                      yAxes: [
                        {
                          stacked: true,
                          ticks: {
                            fontColor: '#EAEAEE',
                            callback(tick) {
                              return `${tick.toString()}%`
                            }
                          },
                          gridLines: {
                            color: 'rgba(99,99,99,1)',
                            zeroLineColor: 'rgba(99,99,99,1)'
                          }
                        }
                      ]
                    },
                    legend: {
                      labels: {
                        fontColor: '#EAEAEE'
                      },
                      onClick: null
                    }
                  }}
                  redraw
                />
              </div>
              <div style={{ width: '50%' }}>
                <Line
                  data={linearData}
                  options={{
                    legend: {
                      display: false
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontColor: '#EAEAEE'
                          },
                          gridLines: {
                            display: false,
                            zeroLineColor: 'rgba(99,99,99,1)'
                          }
                        }
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontColor: '#EAEAEE'
                          },
                          gridLines: {
                            color: 'rgba(99,99,99,1)',
                            zeroLineColor: 'rgba(99,99,99,1)'
                          }
                        }
                      ]
                    },
                    elements: {
                      line: {
                        tension: 0
                      }
                    }
                  }}
                />
              </div>
            </div>
          )

          results.push(
            <QuestionContainer key={question.label}>
              <h2
                style={{
                  paddingBottom: 20,
                  margin: 0
                }}
              >
                {question.label}
                {allComments.length > 0 ? (
                  <CommentLink onClick={() => this.openCommentModal(key)}>
                    {t`Comments`}
                  </CommentLink>
                ) : null}
              </h2>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {chart}
                {allCommentModal}
              </div>
            </QuestionContainer>
          )
        }
      }

      if (!isShare) {
        exportButton = (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Submit type="button" disabled>
              {t`Export as CSV`}
            </Submit>
            <div
              style={{
                marginLeft: 10
              }}
            >{t`Please upgrade to Professional plan to export data.`}</div>
          </div>
        )
      }

      if (!isShare && currentSubscription.can_export_chart_csv) {
        exportButton = (
          <Submit type="button" onClick={() => this.getCSV()}>
            {t`Export as CSV`}
          </Submit>
        )
      }
    } else {
      results = <div>{t`No results found`}</div>
    }

    let selectedGroup = fields.group
    if (fields.group === null || fields.group === t`All groups`) {
      selectedGroup = t`All groups`
    }

    let users = null
    let questionStartDate = activeFrom
    if (!isShare && !isAnonymous && respondents) {
      const userOptions = []
      for (const [key, value] of Object.entries(respondents[selectedGroup])) {
        userOptions.push(
          <option key={value.id} value={value.id}>
            {value.name}
          </option>
        )
      }

      users = (
        <div>
          <label
            style={{
              fontSize: 12,
              fontWeight: 500,
              paddingTop: 8,
              display: 'block'
            }}
          >{t`Select user`}</label>
          <Select
            name="user"
            style={{ marginTop: 5 }}
            onChange={handleChange}
            key={fields.user}
            value={fields.user}
          >
            <option value="">{t`All users`}</option>
            {userOptions}
          </Select>
        </div>
      )
    }

    let groupSelect = null
    if (groups || (chart && chart.length > 0 && chart[0].groups)) {
      const groupOptions = []
      groupOptions.push(
        <option key={t`All groups`} value={t`All groups`}>
          {t`All groups`}
        </option>
      )

      if (isShare) {
        questionStartDate = chart[0].groups.active_from
        for (const [key, value] of Object.entries(chart[0].groups.groups)) {
          groupOptions.push(
            <option key={key} value={key}>
              {chart[0].groups.groups[key]}
            </option>
          )
        }
      } else {
        for (const [key, value] of Object.entries(groups)) {
          groupOptions.push(
            <option key={key} value={key}>
              {groups[key]}
            </option>
          )
        }
      }

      groupSelect = (
        <div style={{ marginRight: 30 }}>
          <label
            style={{
              fontSize: 12,
              fontWeight: 500,
              paddingTop: 8,
              display: 'block'
            }}
          >{t`Select group`}</label>
          <Select
            name="group"
            style={{ marginTop: 5 }}
            onChange={(e) => this.handleGroupChange(e)}
            key={fields.group}
            value={fields.group}
          >
            {groupOptions}
          </Select>
        </div>
      )
    }

    let title = 'Filter by '

    let filterByGroup = false
    let filterByEmployee = false

    if (groupSelect) {
      filterByGroup = true
    }

    if (users && !isAnonymous) {
      filterByEmployee = true
    }

    if (filterByGroup && filterByEmployee) {
      title = t`Filter by group or employee`
    } else if (filterByGroup) {
      title = t`Filter by group`
    } else if (filterByEmployee) {
      title = t`Filter by employee`
    }

    let filters = null
    let filterToggle = (
      <ToggleSpan
        onClick={() => this.toggleFilters()}
      >{t`Show filters`}</ToggleSpan>
    )

    const valid = function (current) {
      return current.isAfter(questionStartDate)
    }

    let share = null
    if (questionnaire && questionnaire.share_enabled) {
      const share_url = `${window.location.protocol}//${window.location.host}/share/${questionnaire.share_hash}`
      const current_share_url = (
        <span>
          <span>{t`Copy share url`}</span>
          <Share
            title={t`Copy to clipboard`}
            onClick={() => this.copyToClipboard(share_url)}
          >
            <i
              style={{ marginLeft: 10, marginRight: 10 }}
              className="far fa-clipboard"
            />
          </Share>
        </span>
      )

      share = (
        <ShareContainer>
          {current_share_url}
          <SearchButton onClick={() => this.removeShare(questionnaireId)}>
            {t`Remove share`}
          </SearchButton>
        </ShareContainer>
      )
    } else {
      share = (
        <ShareContainer>
          <SearchButton onClick={() => this.createShare(questionnaireId)}>
            {t`Share`}
          </SearchButton>
        </ShareContainer>
      )
    }

    if (this.state.filterOpen) {
      filters = (
        <>
          <div
            style={{
              display: 'flex',
              marginBottom: 20
            }}
          >
            <div>
              {groupSelect || users ? <h3>{title}</h3> : null}
              <div style={{ display: 'flex' }}>
                {groupSelect}
                {users}
              </div>
            </div>
            <div>
              <h3 style={{ paddingLeft: 10 }}>{t`Select report period`}</h3>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    paddingRight: 10
                  }}
                >
                  <DateTimeInput
                    name="start_date"
                    title={t`Start date`}
                    onChange={handleChange}
                    value={fields.start_date}
                    defaultValue={startDefault.format('L')}
                    isValidDate={valid}
                  />
                </div>
                <div
                  style={{
                    paddingLeft: 10
                  }}
                >
                  <DateTimeInput
                    name="end_date"
                    title={t`End date`}
                    onChange={handleChange}
                    value={fields.end_date}
                    defaultValue={endDefault.format('L')}
                    isValidDate={valid}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignItems: 'flex-end',
                display: 'flex',
                paddingLeft: 20,
                paddingBottom: 5
              }}
            >
              <Submit type="button" onClick={() => this.getResults()}>
                {t`Filter`}
              </Submit>
            </div>

            {isShare ? null : share}
          </div>
          <DataTypeContainer>
            <ToggleTitle>{t`Representation style`}</ToggleTitle>
            <ToggleContainer>
              <ToggleLabel>{t`Precise`}</ToggleLabel>
              <Toggle
                onToggle={() => this.setState({ relative: !relative })}
                name="relative"
                checked={relative}
              />
              <ToggleLabel>{t`Relative`}</ToggleLabel>
            </ToggleContainer>
          </DataTypeContainer>
        </>
      )

      filterToggle = (
        <ToggleSpan
          onClick={() => this.toggleFilters()}
        >{t`Hide filters`}</ToggleSpan>
      )
    }

    return (
      <Container>
        <ToggleRow>{filterToggle}</ToggleRow>
        {filters}
        {results}
        {exportButton}
      </Container>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GenericFormHOC(Charts, DEFAULT_FIELDS, REDUCER_QUESTIONNAIRES, REDUCER_CHARTS)
)
