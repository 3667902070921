import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NavBar from 'components/Navbar/Navbar'
import Header from 'components/Header/Header'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from 'store/actions/CRUD'
import { changeSelectedCompany } from 'store/actions/session'

import {
  REDUCER_COMPANIES,
  REDUCER_GROUPS,
  REDUCER_QUESTIONNAIRES,
  REDUCER_USERS,
  REDUCER_PLANS,
  REDUCER_SUBSCRIPTION
} from 'store/reducers'

import device from 'device'

const Container = styled.div`
  height: 100%;
  min-height: 100%;
`

const StyledHeader = styled(Header)`
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
`

const Content = styled.div`
  display: flex;
`

const LeftSide = styled.div`
  background-color: #39383c;
  width: ${props => (props.isVisible ? '15rem' : '0')};
  transition: width 500ms;
  position: fixed;
  height: 100%;
`

const RightSide = styled.div`
  background-color: #353438;
  flex-grow: 1;
  padding: 1rem 2rem;
  width: ${props => (props.fullWidth ? '100%' : '88vw')};

  @media ${device.laptop} {
    margin-left: 15rem;
  }

  height: 100%;

  & > div {
    max-width: ${props => (props.fullWidth ? '100vw' : '80vw')};
  }
`

const STALE_REDUCERS = [REDUCER_GROUPS, REDUCER_USERS, REDUCER_QUESTIONNAIRES]

class BaseContainer extends Component {
  componentDidMount() {
    const {
      session: { company }
    } = this.props

    if (company) {
      this.props.changeSelectedCompany(company)
      this.props.isStale(REDUCER_COMPANIES)
      this.props.isStale(REDUCER_GROUPS)
      this.props.isStale(REDUCER_USERS)
      this.props.isStale(REDUCER_QUESTIONNAIRES)
      this.props.isStale(REDUCER_PLANS)
      this.props.isStale(REDUCER_SUBSCRIPTION)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      session,
      session: { notification },
      list
    } = this.props
    const company = session.company || false

    // Loop through specified reducers and call given action if marked as stale
    STALE_REDUCERS.forEach(reducer => {
      if (company && this.props[reducer].isStale) list(reducer, company.id)
    })

    // Companies reducer is a special case and is handled separately
    if (this.props[REDUCER_COMPANIES].isStale) {
      list(REDUCER_COMPANIES, null, '/api/companies/')
    }

    if (this.props[REDUCER_PLANS].isStale) {
      list(REDUCER_PLANS, null, '/api/stripe/products/')
    }

    if (this.props[REDUCER_PLANS].isStale) {
      list(
        REDUCER_SUBSCRIPTION,
        null,
        `/api/stripe/companies/${company.id}/subscriptions/`
      )
    }

    // Handle global errors messages which actions might trigger
    if (
      notification !== null &&
      prevProps.session.notification !== notification
    ) {
      toast[notification.type](notification.message)
    }
  }

  render() {
    const {
      children,
      auth: { loggedIn, isReseller },
      [REDUCER_SUBSCRIPTION]: { data },
      startTour
    } = this.props

    const hasSubscription = data.length > 0

    return (
      <Container>
        <ToastContainer position="top-center" />
        <StyledHeader />
        <Content>
          {loggedIn && (
            <LeftSide isVisible={isReseller || hasSubscription}>
              <NavBar />
            </LeftSide>
          )}
          <RightSide fullWidth={!isReseller || !hasSubscription}>
            {children}
          </RightSide>
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeSelectedCompany, ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseContainer)
