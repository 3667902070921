// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import { logout } from 'store/actions/auth'
import { t } from 'ttag'
import { Elements } from 'react-stripe-elements'
import SinglePlan from 'components//SinglePlan'
import LoadingSpinner from '../components/LoadingSpinner'
import { REDUCER_SUBSCRIPTION, REDUCER_PLANS } from '../store/reducers'
import Submit from 'components//FormElements/Inputs/Submit/Submit'
import { withRouter } from 'react-router'
import { startTour } from 'store/actions/tour'

const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const PlanSelector = styled.div`
  padding: 1rem 0;
`
const Plan = styled.div`
  margin-right: 1rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: pointer;
  padding-bottom: 0.25rem;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  border-bottom: ${(props) => (props.selected ? '3px solid #009f86' : 'none')};
  display: inline-block;
`
const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const BillingDescription = styled.p`
  font-size: 14px;
`

class Plans extends Component {
  state = {
    plan: 0,
    selectedPlan: null,
    isNewSubscription: false
  }
  onSubscriptionUpdate = () => {
    this.setState({
      isNewSubscription: true
    })
  }

  onPlanSelect = (plan) => {
    this.setState({ selectedPlan: plan })
  }

  render() {
    const { startTour: startTourAction, history, session } = this.props
    const subscriptions = this.props[REDUCER_PLANS].data
    const currentSubscription = this.props[REDUCER_SUBSCRIPTION].data
    const currentSubscriptionId = currentSubscription[0]
      ? currentSubscription[0].subscription_id
      : null

    const { plan, clientSecret, selectedPlan } = this.state
    const { isNewSubscription } = this.state
    let showOldPlans = false
    if (session) {
      if (session.company) {
        if (session.company.old_pricing_model === true) {
          showOldPlans = true
        }
      }
    }
    const products = subscriptions.map((product) => {
      if (!product.plans[plan]) return
      return (
        <Elements key={product.plans[plan].plan_id}>
          <SinglePlan
            {...this.props}
            {...product}
            showOldPlans={showOldPlans}
            key={product.plans[plan].plan_id}
            onPlanSelect={this.onPlanSelect}
            plan={product.plans[plan]}
            selectedPlan={selectedPlan}
            currentSubscription={currentSubscription}
            currentSubscriptionId={currentSubscriptionId}
            clientSecret={clientSecret}
            onSubscriptionUpdate={this.onSubscriptionUpdate}
          />
        </Elements>
      )
    })

    const isPending =
      this.props[REDUCER_SUBSCRIPTION].isPending ||
      this.props[REDUCER_PLANS].isPending

    return (
      <div>
        <h1>{t`Available plans`}</h1>
        <PlanSelector>
          {showOldPlans ? (
            <Plan
              selected={plan === 1}
              onClick={() => this.setState({ plan: 1 })}
            >
              {t`12 month subscription`}
            </Plan>
          ) : null}
          <Plan
            selected={plan === 0}
            onClick={() => this.setState({ plan: 0 })}
          >
            {t`1 month subscription`}
          </Plan>
          {showOldPlans ? null : (
            <BillingDescription>
              {t`Billing is based on the maximum number of personnel at the present billing cycle.`}
            </BillingDescription>
          )}
        </PlanSelector>
        {isPending && <LoadingSpinner />}
        {isNewSubscription ? (
          <SuccessContainer>
            <div>
              <h1>{t`Success!`}</h1>
              <p>{t`Thank you for the purchase.`}</p>
              <Submit
                style={{ margin: '0 auto 25px' }}
                onClick={() => {
                  startTourAction()
                  history.push('/surveys')
                }}
              >{t`SEE HOW PRIOCTA WORKS`}</Submit>
            </div>
          </SuccessContainer>
        ) : null}
        <ProductContainer>{products}</ProductContainer>
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions, startTour, logout }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plans))
