import React, { Component } from 'react'
import styled from 'styled-components/macro'

const Container = styled.label`
  cursor: pointer;
  role: input;
  position: relative;
  top: 0.25rem;
`

const Input = styled.input`
  visibility: hidden;
`

const Background = styled.span`
  width: 40px;
  height: 12px;
  border-radius: 20px;
  display: block;
  background-color: #535252;
`

const Balloon = styled.div`
  position: relative;
  background-color: ${props =>
    props.checked && !props.disabled ? '#009f86' : 'gray'};
  height: 20px;
  width: 20px;
  bottom: 16px;
  border-radius: 16px;
  left: ${props => (props.checked ? '20px' : '0')};
  transition: left 300ms ease, background-color 300ms ease;
`

const Toggle = ({ checked, onToggle, name, disabled }) => {
  return (
    <Container>
      <Input
        type="checkbox"
        name={name}
        onClick={disabled ? null : onToggle}
        checked={checked}
      />
      <Background />
      <Balloon checked={checked} />
    </Container>
  )
}
export default Toggle
