// @flow

import React from 'react'
import styled from 'styled-components/macro'
import { t } from 'ttag'
import AppStoreBadge from 'assets/apple-store.png'
import PlayStoreBadge from 'assets/play-store.png'

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #353438;
  z-index: 999999;
  text-align: center;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: #cea766;
  }

  a {
    display: inline-block;
    color: #009f86;
    padding: 0.5rem;
    &:hover {
      color: #00574a;
    }
  }

  img {
    max-width: 120px;
  }
`

const Icon = styled.i.attrs({
  src: props => props.src
})`
  padding: 1rem 0.5rem 1rem 1rem;
  color: #009f86;
`

const MobileBlocked = props => {
  return (
    <Overlay>
      <Icon className={`fad fa-desktop fa-7x`} />
      <h1>Continue to desktop</h1>
      <p>
        {t`As an admin user, the desktop is a better way for you to use Priocta.
        All survey and personnel management options can be found there. You can
        log in to Priocta with your computer on this website: `}
        <a href="https://app.priocta.com/login">
          https://app.priocta.com/login
        </a>
      </p>
      <p>
        {t`You will also need the Priocta mobile app to be able to answer the
        surveys. Download the Priocta app from App Store or Google Play. Use the
        same email address and password in both the desktop version and the
        mobile application.`}
      </p>
      <div>
        <a href="https://play.google.com/store/apps/details?id=fi.priole.priocta">
          <img src={PlayStoreBadge} />
        </a>
        <a href="https://apps.apple.com/us/app/priocta/id1464662574">
          <img src={AppStoreBadge} />
        </a>
      </div>
    </Overlay>
  )
}

export default MobileBlocked
