import React from 'react'
import styled from 'styled-components/macro'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const Tbody = styled.tbody``

export const ListContainer = props => (
  <Table>
    <Tbody>{props.children}</Tbody>
  </Table>
)

export const ListCompanyTitle = styled.th`
  text-align: left;
  padding: 1rem 0;
  font-size: 1.3rem;
`

export const ListRow = styled.tr``

export const ListHeadingCell = styled.th`
  border-top: 1px solid #212121;
  border-bottom: 1px solid #212121;
  padding: 0.75rem;
  text-align: left;
`

export const ListBodyCell = styled.td`
  border-top: 1px solid #212121;
  border-bottom: 1px solid #212121;
  padding: 0.75rem;
  &:first-letter {
    text-transform: capitalize;
  }
  &:last-child {
    padding-right: 0;
    text-align: right;
  }
`
