import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { t } from 'ttag'
import InputWithLabel from '../InputWithLabel/InputWithLabel'

const Container = styled.form`
  width: 100%;
  display: flex;
`

export default class MinMaxValueList extends Component {
  state = {
    min: 1,
    max: 10,
    step: 1
  }

  componentDidMount() {
    const { values } = this.props

    if (!values) {
      this.setState({
        min: values[0],
        max: values[1]
      })
    }
  }

  onChange = () => {
    const { min, max, step } = this.state
    const { onChange } = this.props
    onChange([min, max, step])
  }

  handleChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => this.onChange()
    )
  }

  render() {
    const { min, max } = this.state

    return (
      <Container>
        <div>
          <InputWithLabel
            name="min"
            label={t`Min`}
            type="number"
            onChange={this.handleChange}
            value={min}
          />
        </div>
        <div>
          <InputWithLabel
            name="max"
            label={t`Max`}
            type="number"
            onChange={this.handleChange}
            value={max}
          />
        </div>
      </Container>
    )
  }
}
