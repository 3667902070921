import React from 'react'
import styled from 'styled-components/macro'
import {
  SingleSelector,
  SingleUser
} from 'components/FormElements/Inputs/UserSelector/UserSelector'

const Container = styled.div`
  width: 100%;
  display: flex;
`
const Userlist = ({ selected, groupsUsersTitle, late }) => {
  // .sort((a, b) => a.firstname.localeCompare(b.firstname))
  const usersInGroup = selected
    .map(user => ({
      ...user,
      isLate: late.findIndex(lateUser => user.id === lateUser.id) > -1
    }))
    .sort((x, y) => Number(y.isLate) - Number(x.isLate))
    .map(user => {
      return (
        <SingleUser
          data={{
            ...user,
            isLate: late.findIndex(lateUser => user.id === lateUser.id) > -1
          }}
          readOnly
        />
      )
    })

  /*   const usersInGroup = selected.map(user => {
    return (
      <SingleUser
        data={{
          ...user,
          isLate: late.findIndex(lateUser => user.id === lateUser.id) > -1
        }}
        readOnly
      />
    )
  }) */

  return (
    <Container>
      <SingleSelector label={groupsUsersTitle} data={usersInGroup} />
    </Container>
  )
}
export default Userlist
