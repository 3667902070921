import { LIST, CREATE, EDIT, PUT, IS_STALE } from 'store/actions/CRUD'

import produce from 'immer'
import typeToReducer from 'type-to-reducer'

const initialState = {
  data: [],
  isPending: false,
  isStale: true,
  error: {
    messages: []
  }
}

/**
 * Generic CRUD Reducer
 *
 * @param name Name of the reducer
 * @returns {*}
 */
export default function CRUDReducer(name = '') {
  return typeToReducer(
    {
      [`${IS_STALE}_${name}`]: state =>
        produce(state, draft => {
          draft.data = []
          draft.isStale = true
        }),
      [`${LIST}_${name}`]: {
        PENDING: state =>
          produce(state, draft => {
            draft.isPending = true
            draft.isStale = false
          }),

        REJECTED: (state, { payload: { response } }) =>
          produce(state, draft => {
            draft.data = []
            draft.isPending = false
            draft.error = response
          }),
        FULFILLED: (state, { payload }) =>
          produce(state, draft => {
            draft.data = payload
            draft.isPending = false
          })
      },
      [`${CREATE}_${name}`]: {
        PENDING: state =>
          produce(state, draft => {
            draft.isPending = true
          }),
        REJECTED: (state, { payload: { messages } }) =>
          produce(state, draft => {
            draft.error = messages
          }),
        FULFILLED: (state, { payload }) =>
          produce(state, draft => {
            draft.isPending = false
            draft.isStale = true
          })
      },
      [`${EDIT}_${name}`]: {
        PENDING: state =>
          produce(state, draft => {
            draft.isPending = true
          }),
        REJECTED: (state, { payload: { messages } }) =>
          produce(state, draft => {
            draft.error = messages
          }),
        FULFILLED: (state, { payload }) =>
          produce(state, draft => {
            draft.isPending = false
            draft.isStale = true
          })
      },
      [`${PUT}_${name}`]: {
        PENDING: state =>
          produce(state, draft => {
            draft.isPending = true
          }),
        REJECTED: (state, { payload: { messages } }) =>
          produce(state, draft => {
            draft.error = messages
          }),
        FULFILLED: (state, { payload }) =>
          produce(state, draft => {
            draft.isPending = false
            draft.isStale = true
          })
      }
    },
    initialState
  )
}
