import React from 'react'
import styled from 'styled-components/macro'

const Input = styled.input`
  border: ${props => (props.error ? '1px solid red' : 'none')};
  color: #ffffff;
  background: transparent;
  font-weight: ${props => (props.small ? 'normal' : 'bold')};
  font-size: ${props => (props.small ? 'initial' : '1.5rem')};
  outline: none;
  padding: 0.25rem;
  height: ${props => (props.small ? '32px' : '64px')};
  width: 100%;
  border-bottom: 1px solid #212121;
  transition: background-color 200ms;
  margin: 10px 0;
  border-radius: 4px;

  &::placeholder {
    transition: color 200ms;
    color: rgba(255, 255, 255, 1);
  }

  &:hover,
  &:focus {
    background-color: #535252;
  }

  &:focus::placeholder {
    color: transparent;
  }
`

const ErrorField = styled.span`
  padding: 5px;
`
const textInput = props => {
  const {
    group,
    name,
    onChange,
    value,
    error,
    type,
    placeholder,
    small
  } = props

  const id = group ? `${group}-${name}` : name

  const inputType = type || 'text'
  return (
    <>
      <div>
        <Input
          autoComplete="off"
          small={small}
          id={id}
          name={name}
          type={inputType}
          onChange={onChange}
          value={value}
          error={error}
          placeholder={placeholder}
        />
        {error && <ErrorField>{error}</ErrorField>}
      </div>
    </>
  )
}

textInput.defaultProps = {
  type: 'text',
  placeholder: null,
  group: null
}

export default textInput
