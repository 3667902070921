// @flow
import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'store/actions/CRUD'
import Charts from 'components/Charts'
import { REDUCER_QUESTIONNAIRES } from '../store/reducers'
import Button from 'components/Button/Button'

// require('moment/locale/fi')

const Title = styled.div`
  font-size: ${props => (props.secondary ? '1rem' : '1.3rem')};
  padding: 1rem 0rem 1rem 0rem;
  font-weight: bold;
  text-transform: ${props => (props.secondary ? 'uppercase' : 'none')};
  color: ${props => (props.secondary ? '#009f86' : 'inherit')};
  cursor: ${props => (props.secondary ? 'pointer' : 'normal')};
`

const Share = styled.button`
  color: #009f86;
`

class DashboardStats extends Component {
  render() {
    const {
      match: { params }
    } = this.props

    const questionnaireId = params.uuid

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Title>Statistics</Title>
        </div>
        <Charts questionnaireId={questionnaireId} isShare={true} />
      </div>
    )
  }
}

const mapStateToProps = (...args) => args[0]
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...actions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardStats)
